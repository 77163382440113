import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6399ab66"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id", "aria-labelledby"]
const _hoisted_2 = { class: "modal-dialog" }
const _hoisted_3 = { class: "modal-content modal-component" }
const _hoisted_4 = { class: "modal-header header" }
const _hoisted_5 = { class: "modal-body d-flex flex-column gap-3" }
const _hoisted_6 = { class: "modal-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "modal modal-sm modal-lg modal-md modal-xs fade",
    id: _ctx.id,
    tabindex: "-1",
    "aria-labelledby": _ctx.id,
    "aria-hidden": "true"
  }, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _renderSlot(_ctx.$slots, "modal-header", {}, undefined, true)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _renderSlot(_ctx.$slots, "modal-body", {}, undefined, true)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _renderSlot(_ctx.$slots, "modal-footer", {}, undefined, true)
        ])
      ])
    ])
  ], 8, _hoisted_1))
}