import { EmissionApiAbstractHandler } from './EmissionApiAbstractHandler';
import { ChainTarget } from '../../../domain/ChainOfResponsibility/Handler';

import useRequestInProgressModal from '../../../composables/useRequestInProgressModal';
import { useModalControl } from '@/SignaturePlugin/A1/store/pinia/useModalControl.store';

export class ReturnSisArInvalidSolicitationStatus extends EmissionApiAbstractHandler {
  public async handle(target: ChainTarget): Promise<any> {
    EmissionApiAbstractHandler.firstChainLink = this;
    const solicitationStatus = Number(target?.response?.data?.solicitation?.status ?? -1);

    if (EmissionApiAbstractHandler.validSisArSolicitationStatus.includes(solicitationStatus)) {
      return super.handle(target);
    }

    await ReturnSisArInvalidSolicitationStatus.showRequestInProgressModal();

    return undefined;
  }

  public static async showRequestInProgressModal(): Promise<void> {
    const requestInProgressModal = useRequestInProgressModal();
    const modalControlStore = useModalControl();

    await modalControlStore.show({
      modalId: 'requestInProgressModal',
      show: requestInProgressModal.showRequestInProgressModal,
      hide: requestInProgressModal.hideRequestInProgressModal,
    });
  }
}

export default ReturnSisArInvalidSolicitationStatus;
