
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'soluti-icon',
  data: () => ({
    value: '',
    url: '',
  }),
  props: {
    name: {
      type: String,
      default: 'question',
    },
    fixWidth: Boolean,
    blink: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
    },
    colorVariation: {
      type: String,
      default: '500',
    },
    type: {
      type: String,
      default: 'regular',
    },
  },
  computed: {
    cor() {
      return this.color ? `var(--color-${this.color}-${this.colorVariation}) ` : '';
    },
  },
});
