// eslint-disable
import { createApp } from 'vue';
import Maska from 'maska';
import vSelect from 'vue-select';
import { createPinia } from 'pinia';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { IconDefinition, library } from '@fortawesome/fontawesome-svg-core';
import App from './App.vue';
import { router } from './router';
import 'vue-select/dist/vue-select.css';
import icons from './helpers/icons';
import components from './components';

icons.forEach((icon) => {
  library.add(icon as IconDefinition);
});

const app = createApp(App);

Object.entries(components).forEach(([key, value]: any) => {
  app.component(key, value);
});

app.use(createPinia());
app.use(router);
app.use(Maska);
app.component('v-select', vSelect);
app.component('font-awesome-icon', FontAwesomeIcon);
app.mount('#app');
