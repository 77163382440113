export default function formatCSR(originalCsr: string) {
  const beginMarker = '-----BEGIN CERTIFICATE REQUEST-----';
  const endMarker = '-----END CERTIFICATE REQUEST-----';

  const csr = originalCsr.replace(beginMarker, '').replace(endMarker, '');
  let csrFormatted = csr.replace(/(\r\n|\n|\r)/gm, '');

  const lines = [];
  for (let i = 0; i < csrFormatted.length; i += 64) {
    lines.push(csrFormatted.slice(i, i + 64));
  }

  csrFormatted = lines.join('\n');

  csrFormatted = csrFormatted.trim();

  return `${beginMarker}\n${csrFormatted}\n${endMarker}`;
}
