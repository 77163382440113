/* eslint-disable camelcase */
import { server } from '@/infra/server';
import { domainHttpValidationDataType } from '@/store/Pinia/useDomainValidation.store';

type ResponseType = {
  status: string;
  message: string;
};

type ResponseChoseMethodType = {
  choosedValidationData: domainHttpValidationDataType[];
} & ResponseType;

type RequestChoseMethodType = {
  codigo: string;
  senha: string;
  domain: string;
};

type RequestValidateType = {
  codigo: string;
  senha: string;
  domain: string;
};

type ResponseValidateDomain = ResponseType & {
  domainValidated: {
    verified_at: null | string;
    domain: string;
  }[];
};

const httpValidationController = {
  choseMethod: async (params: RequestChoseMethodType) => {
    const response = await server.post<ResponseChoseMethodType>(
      '/domain-validation/http/choose-method',
      { ...params },
    );
    return response.data;
  },
  validate: async (params: RequestValidateType) => {
    const response = await server.post<ResponseValidateDomain>('/domain-validation/http/validate', {
      ...params,
    });
    return response.data;
  },
};

export default httpValidationController;
