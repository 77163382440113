import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "input-wrapper" }
const _hoisted_2 = { class: "input-label p-small" }
const _hoisted_3 = { class: "form-control-icon" }
const _hoisted_4 = {
  key: 0,
  class: "invalid-feedback"
}
const _hoisted_5 = { class: "text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_v_select = _resolveComponent("v-select")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.label) + " " + _toDisplayString(_ctx.requiredSymbol), 1),
    _createElementVNode("div", _hoisted_3, [
      (!!_ctx.icon)
        ? (_openBlock(), _createBlock(_component_Icon, {
            key: 0,
            class: "icon",
            name: _ctx.icon,
            style: _normalizeStyle("width: 1.25rem; height: 1.5rem; margin-top: 18px !important;" + `color: var(--color-${_ctx.getColorIcon}); `)
          }, null, 8, ["name", "style"]))
        : _createCommentVNode("", true),
      _createVNode(_component_v_select, {
        class: _normalizeClass(["pt-3 pb-2", ['form-select', _ctx.errorLog ? 'invalid' : '' ]]),
        "onOption:selected": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event.nome))),
        options: _ctx.options,
        id: "select-input",
        placeholder: _ctx.placeholder,
        disabled: _ctx.isDisabled,
        label: _ctx.labelSelectable,
        value: _ctx.modelValue,
        style: {"marign":"0"}
      }, {
        "no-options": _withCtx(() => [
          _createTextVNode("Nenhuma opção encontrada")
        ]),
        _: 1
      }, 8, ["options", "class", "placeholder", "disabled", "label", "value"])
    ]),
    (!!_ctx.errorLog)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.errorLog), 1)
        ]))
      : _createCommentVNode("", true)
  ]))
}