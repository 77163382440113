
import { defineComponent } from 'vue';
import { mapActions, mapState } from 'pinia';
import { useToastGlobal } from '@/store/Pinia/useToastGlobal.store';
import Icon from '@/components/Atoms/Icon.vue';
import { Toast } from 'bootstrap';

export default defineComponent({
  name: 'ToastGlobalComponent',
  data: () => ({}),
  components: {
    Icon,
  },
  watch: {
    'toast.isShow': function w(curr) {
      if (curr) {
        this.showToast();
        setTimeout(() => {
          this.toggleToastGlobal({ isShow: false });
        }, 5000);
      }
    },
  },
  computed: {
    ...mapState(useToastGlobal, ['toast']),
    typeAlert() {
      return `alert-${this.toast.type || 'danger'}`;
    },
  },
  methods: {
    ...mapActions(useToastGlobal, ['toggleToastGlobal']),
    showToast() {
      const toast = new Toast('#toast-new-message');
      toast.show();
    },
  },
});
