
import { defineComponent } from 'vue';
import Icon from '@/components/Atoms/Icon.vue';
import Wizzard from '@/components/Molecules/Wizzard.vue';
import Contact from '@/components/Molecules/Contact.vue';
import Selectable from '@/components/Atoms/Selectable.vue';
import { AxiosError } from 'axios';
import { useModalLoading } from '@/store/Pinia/useModalLoading.store';
import Loading from '@/components/Atoms/Loading.vue';
import { mapActions, mapState } from 'pinia';
import { useDynamicForms } from '@/store/Pinia/useDynamicForms.store';
import { useSolicitation } from '@/store/Pinia/useSolicitation.store';
import { useToastGlobal } from '@/store/Pinia/useToastGlobal.store';
import { useDomainValidation } from '@/store/Pinia/useDomainValidation.store';
import browserDeviceCheck from '@/utils/browserDeviceCheck';
import { domainValidationController } from '@/infra/DomainValidation/domainValidation.controller';
import emailValidationController from '@/infra/DomainValidation/email.controller';
import httpValidationController from '@/infra/DomainValidation/http.controller';
import dnsValidationController from '@/infra/DomainValidation/dns.controller';
import ModalOnlyUseOnDesktop from '@/components/templates/ModalOnlyUseOnDesktop.vue';
import ModalEmailValidation from './email/ModalEmailValidation.vue';

interface SelectableOption {
  id: number;
  label: string;
  value: string;
  nome: string;
}

export default defineComponent({
  components: {
    Icon,
    Wizzard,
    Contact,
    Loading,
    Selectable,
    ModalEmailValidation,
    ModalOnlyUseOnDesktop,
  },
  data() {
    return {
      isLoading: false,
      isMobile: false,
      validationMethodOptions: [
        {
          id: 1,
          label: 'E-mail',
          value: 'email',
          nome: 'email',
        },
        {
          id: 2,
          label: 'Requisição HTTP',
          value: 'Requisição HTTP',
          nome: 'http',
        },
        {
          id: 3,
          label: 'Registro CNAME no DNS',
          value: 'Registro CNAME no DNS',
          nome: 'dns',
        },
      ],
      validationMethod: '',
      domainOptions: [] as SelectableOption[],
      domain: '',
      errorCode: '',
    };
  },
  watch: {
    validationMethod() {
      this.getDomains();
    },
  },
  computed: {
    ...mapState(useDynamicForms, ['getPassword']),
    ...mapState(useSolicitation, ['getSolicitation']),
    validationMethodIsSelected() {
      if (this.validationMethod) {
        return true;
      }
      return false;
    },
    domainsAreLoaded() {
      if (this.domainOptions.length > 0) {
        return true;
      }
      return false;
    },
    domainIsSelected() {
      if (this.domain) {
        return true;
      }
      return false;
    },
  },
  methods: {
    ...mapActions(useModalLoading, ['setIsLoading']),
    ...mapActions(useToastGlobal, ['toggleToastGlobal']),
    ...mapActions(useDomainValidation, ['setDomainHttpValidationData', 'setDomainDnsValidationData']),
    async getDomains() {
      try {
        this.setIsLoading({
          isLoading: true,
          title: 'Aguarde enquanto listamos seus domínios.',
          message: '',
          image: 'clock',
        });

        const response = await domainValidationController.getDomains(
          this.getSolicitation.cdSolicitacao,
          this.getPassword,
        );

        this.domainOptions = this.mapDomainsToOptions(response.domains);
      } catch (error) {
        this.toggleToastGlobal({
          isShow: true,
          type: 'danger',
          message: 'Houve um erro com a requisição, por favor tente novamente mais tarde.',
        });
      } finally {
        setTimeout(() => {
          this.setIsLoading({ isLoading: false });
        }, 1000);
      }
    },
    mapDomainsToOptions(domains: string[]) {
      return domains.map((domain, index) => ({
        id: index,
        label: domain,
        nome: domain,
        value: domain,
      }));
    },
    async handleNextStep() {
      if (this.validationMethod === 'email') {
        this.handleEmailValidationSelected();
      } else if (this.validationMethod === 'http') {
        this.handleHttpValidationSelected();
      } else if (this.validationMethod === 'dns') {
        this.handleDnsValidationSelected();
      }
    },
    async handleEmailValidationSelected() {
      try {
        this.setIsLoading(
          {
            isLoading: true,
            title: 'Aguarde enquanto buscamos seus e-mails.',
            message: '',
            image: 'clock',
          },
        );

        // eslint-disable-next-line max-len
        await (this.$refs.modalEmailValidation as InstanceType<typeof ModalEmailValidation>).fetchEmails();

        if (this.validationMethod === 'email') {
          (document.getElementById('modal-email-validation-button') as HTMLElement).click();
        }
      } catch (error) {
        this.toggleToastGlobal({
          isShow: true,
          type: 'danger',
          message: 'Houve um erro com a requisição, por favor tente novamente mais tarde.',
        });
      } finally {
        setTimeout(() => { this.setIsLoading({ isLoading: false }); }, 1000);
      }
    },
    async handleHttpValidationSelected() {
      if (this.isMobile) {
        return (document.getElementById('modal-only-desktop-issuer-button') as HTMLButtonElement).click();
      }

      try {
        this.setIsLoading(
          {
            isLoading: true,
            title: 'Aguarde enquanto selecionamos o método de validação.',
            message: '',
            image: 'clock',
          },
        );

        const response = await httpValidationController.choseMethod({
          codigo: this.getSolicitation.cdSolicitacao,
          senha: this.getPassword,
          domain: this.domain,
        });

        this.setDomainHttpValidationData(response.choosedValidationData[0]);
        return this.$router.push({ path: '/domain-validation/http-instructions' });
      } catch (error) {
        this.toggleToastGlobal({
          isShow: true,
          type: 'info',
          message: 'O domínio selecionado já foi validado.',
        });
        return null;
      } finally {
        setTimeout(() => { this.setIsLoading({ isLoading: false }); }, 1000);
      }
    },
    async handleDnsValidationSelected() {
      try {
        this.setIsLoading(
          {
            isLoading: true,
            title: 'Aguarde enquanto selecionamos o método de validação.',
            message: '',
            image: 'clock',
          },
        );

        const response = await dnsValidationController.choseMethod({
          codigo: this.getSolicitation.cdSolicitacao,
          senha: this.getPassword,
          domain: this.domain,
        });

        this.setDomainDnsValidationData(response.choosedValidationData[0]);
        return this.$router.push({ path: '/domain-validation/dns-instructions' });
      } catch (error) {
        this.toggleToastGlobal({
          isShow: true,
          type: 'info',
          message: 'O domínio selecionado já foi validado.',
        });
        return null;
      } finally {
        setTimeout(() => { this.setIsLoading({ isLoading: false }); }, 1000);
      }
    },
    async handleValidateEmail(code: string) {
      try {
        this.isLoading = true;

        await emailValidationController.validateCode({
          codigo: this.getSolicitation.cdSolicitacao,
          senha: this.getPassword,
          domain: this.domain,
          code,
        });

        const modal = this.$refs.modalEmailValidation as typeof ModalEmailValidation;

        modal.modalClose();

        if (!this.getSolicitation.isQsa) {
          this.$router.push({ path: '/domain-validation/validation-success/feedback-http-email-dns-ontime-wo-qsa' });
          modal.modalClose();
          return;
        }

        modal.modalClose();
        this.$router.push({ path: '/ssl-certificate/installation-guide-ssl' });
        return;
      } catch (error) {
        const err = error as AxiosError<{ message: string }>;
        if (err.response?.data.message === 'Código de Validação inválido.') {
          this.errorCode = 'Oops! Código inválido.';
        } else {
          this.toggleToastGlobal({
            isShow: true,
            type: 'danger',
            message: 'Houve um erro com a requisição, por favor tente novamente mais tarde.',
          });
        }
      } finally {
        this.isLoading = false;
      }
    },
  },
  mounted() {
    this.isMobile = browserDeviceCheck();
  },
  beforeRouteEnter(to, from, next) {
    const solicitationStore = useSolicitation();
    if (solicitationStore.getSolicitation.cdSolicitacao === '') {
      next('/');
    }
    next();
  },
});
