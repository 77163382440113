import { RemovableRef, useStorage } from '@vueuse/core';
import { defineStore } from 'pinia';

export type pluginDataType = {
  csrDownloadLink: string;
  certificateIssuedAt: string;
  certificateIssuedAtWasChecked: boolean;
};

type State = {
  pluginData: RemovableRef<pluginDataType>;
}

export const usePlugin = defineStore({
  id: 'pluginStore',
  state: () => ({
    pluginData: useStorage('pluginData', {
      csrDownloadLink: '',
      certificateIssuedAt: '',
      certificateIssuedAtWasChecked: false,
    }),
  } as State),
  actions: {
    setCsrDownloadLink(link: string) {
      this.pluginData.csrDownloadLink = link;
    },
    setCertificateIssuedAt(date: string) {
      this.pluginData.certificateIssuedAt = date;
    },
    setCertificateIssuedAtWasChecked(check: boolean) {
      this.pluginData.certificateIssuedAtWasChecked = check;
    },
    clear() {
      this.pluginData.csrDownloadLink = '';
      this.pluginData.certificateIssuedAt = '';
      this.pluginData.certificateIssuedAtWasChecked = false;
    },
  },
  getters: {
    csrDownloadLink(): string {
      return this.pluginData.csrDownloadLink;
    },
    issuedAt(): string {
      return this.pluginData.certificateIssuedAt;
    },
    certificateIssuedAtWasChecked(): boolean {
      return this.pluginData.certificateIssuedAtWasChecked;
    },
  },
});
