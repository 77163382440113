
import { defineComponent } from 'vue';
import Icon from '@/components/Atoms/Icon.vue';
import { mapActions } from 'pinia';
import { useToastGlobal } from '@/store/Pinia/useToastGlobal.store';

export default defineComponent({
  name: 'ModalTokenComponent',
  components: {
    Icon,
  },
  data: () => ({
    teste: '',
  }),
  beforeCreate() {
    this.teste = window.location.href;
  },
  methods: {
    ...mapActions(useToastGlobal, ['toggleToastGlobal']),
    async sendLink() {
      try {
        const shareData = {
          url: this.teste,
        };
        await navigator.share(shareData);
      } catch (error) {
        this.toggleToastGlobal({
          isShow: true,
          type: 'info',
          message: 'Link não enviado',
        });
      }
    },
  },
});
