
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'modalVue',
  props: {
    id: {
      type: String,
      require: true,
    },
    idName: {
      type: String,
    },
  },
});
