import { StartChain } from './StartChain';
import { ReturnCode5 } from './ReturnCode5';
import { ReturnCode10 } from './ReturnCode10';
import { ReturnCode20 } from './ReturnCode20';
import { ReturnCode30 } from './ReturnCode30';
import { ReturnCode9001 } from './ReturnCode9001';
import { ReturnError } from './ReturnError';

export default [
  new StartChain(),
  new ReturnCode9001(),
  new ReturnCode5(),
  new ReturnCode10(),
  new ReturnCode20(),
  new ReturnCode30(),
  new ReturnError(),
];
