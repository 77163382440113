import ModalDownloadCertificateVue from '../modals/ModalDownloadCertificate.vue';

import { ref } from 'vue';

const downloadCertificateModal = ref<InstanceType<typeof ModalDownloadCertificateVue> | null>(null);

export default function useDownloadCertificateModal() {
  function showDownloadCertificateModal() {
    downloadCertificateModal.value?.show();
  }

  function hideDownloadCertificateModal() {
    downloadCertificateModal.value?.hide();
  }

  return {
    downloadCertificateModal,
    showDownloadCertificateModal,
    hideDownloadCertificateModal,
  };
}
