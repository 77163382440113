import ModalPluginDownload from '../modals/ModalPluginDownload.vue';

import { ref } from 'vue';
import useEmissionStepsModal from './useEmissionStepsModal';
import { usePlugin } from '@/store/Pinia/usePlugin.store';

const pluginDownloadModal = ref<InstanceType<typeof ModalPluginDownload> | null>(null);

export default function usePluginDownloadModal() {
  function showPluginDownloadModal() {
    const pluginStore = usePlugin();
    const { setEmissionStep } = useEmissionStepsModal();
    // Garantir que não mostrar os steps antes da execução do plugin
    setEmissionStep(0);

    // Limpar dados do plugin para garantir que não mostre os
    if (pluginStore.issuedAt.length === 0) {
      pluginStore.clear();
    }

    pluginDownloadModal.value?.show();
  }

  function hidePluginDownloadModal() {
    pluginDownloadModal.value?.hide();
  }

  return {
    pluginDownloadModal,
    showPluginDownloadModal,
    hidePluginDownloadModal,
  };
}
