import { AbstractHandler } from './AbstractHandler';
import { ChainTarget } from '../../domain/ChainOfResponsibility/Handler';

export class ReturnCode5 extends AbstractHandler {
  public async handle(target: ChainTarget): Promise<any> {
    let response = target?.response;
    const code = response?.data?.code;

    if (code !== 5) {
      return super.handle(target);
    }

    response = await AbstractHandler.makePluginRequest(target?.makeRequest);
    return super.handle({ ...target, response });
  }
}

export default ReturnCode5;
