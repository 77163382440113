/* eslint-disable camelcase */
/* eslint-disable max-len */
import { server } from '@/infra/server';

export type validationMethodType =
  | 'validateEmail'
  | 'validateCPF'
  | 'validateCNPJ'
  | 'validatePhone'
  | 'domainValidation'
  | 'validateName'
  | null;

export type FieldType = {
  id: string;
  name: string;
  label: string;
  value: string;
  required: boolean;
  disable: boolean;
  min_length: string | number;
  max_length: string | number;
  placeholder: string;
  type: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  values: any;
  icon: string;
  isNumeric: boolean;
  mask: string[] | null;
  order: number;
  validationMethod: validationMethodType;
};

export type RequestGetFields = {
  productId: string;
  sku: string;
  arsubdomain: string;
};

export type ResponseSolicitation = {
  status: string;
  fields: FieldType[];
  produto: string;
};

export const dynamicFormsController = {
  getFields: async ({ productId, sku, arsubdomain }: RequestGetFields) => {
    const response = await server.post<ResponseSolicitation>('get-product-fields', {
      product_id: productId,
      sku,
      arsubdomain,
    });
    return response.data;
  },
};
