
import { useAR } from '@/store/Pinia/useAR.store';
import { useSolicitation } from '@/store/Pinia/useSolicitation.store';
import { mapState } from 'pinia';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'HomeView',
  data: () => ({
    form: {
      arsubdomain: '',
      codigoSolicitacao: '',
      user: '',
    },
  }),
  computed: {
    ...mapState(useAR, ['getSubdomain']),
    ...mapState(useSolicitation, ['getSolicitation']),
    link() {
      if (process.env.NODE_ENV === 'development') {
        return `http://emissao-online-hom.soluti.com.br/${this.form.arsubdomain}/guia-de-instalacao-de-certificado-digital`;
      }
      return `https://emissao-online.soluti.com.br/${this.form.arsubdomain}/guia-de-instalacao-de-certificado-digital`;
    },
  },
  mounted() {
    this.form.arsubdomain = this.getSubdomain;
    this.form.codigoSolicitacao = this.getSolicitation.cdSolicitacao;
    this.form.user = this.getSolicitation.userName;
  },
});
