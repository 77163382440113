import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "loaded-files-wrapper"
}
const _hoisted_2 = { class: "loaded-file__icon" }
const _hoisted_3 = { class: "loaded-file__content" }
const _hoisted_4 = { class: "w-80 loaded-file__name text-wrap" }
const _hoisted_5 = { class: "loaded-file__error" }
const _hoisted_6 = ["onClick", "onKeydown"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_ctx.filesLoaded.length > 0)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filesLoaded, (file) => {
          return (_openBlock(), _createElementBlock("div", {
            key: file.name,
            "aria-label": "Área de Arquivos já enviados.",
            class: _normalizeClass(['loaded-file mt-2', !!_ctx.isInvalid(file) ? 'loaded-file--error' : ''])
          }, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_Icon, { name: "file-lines" })
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, _toDisplayString(file.name), 1),
              _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.isInvalid(file)), 1)
            ]),
            _createElementVNode("div", {
              onClick: ($event: any) => (_ctx.deleteFile(file)),
              onKeydown: ($event: any) => (_ctx.deleteFile(file)),
              class: "loaded-file__action",
              style: {"cursor":"pointer"}
            }, [
              _createVNode(_component_Icon, { name: "x" })
            ], 40, _hoisted_6)
          ], 2))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}