
import { defineComponent } from 'vue';
import Icon from '@/components/Atoms/Icon.vue';
import Input from '@/components/Atoms/Input.vue';
import Breadcrumb from '@/components/Molecules/Breadcrumbs.vue';
import Wizzard from '@/components/Molecules/Wizzard.vue';
import { mapActions } from 'pinia';
import { useDynamicForms } from '@/store/Pinia/useDynamicForms.store';
import { useRouteBirdId } from '@/store/Pinia/useRouteBirdId.store';
import { useSolicitation } from '@/store/Pinia/useSolicitation.store';

interface DataPassword {
  validation: {
      uppercase: boolean,
      lowercase: boolean,
      hasNumber: boolean,
      minLength: boolean,
    },
    password: string | null,
    confirmPassword: string | null,
    error: string,
}

export default defineComponent({
  name: 'HomeView',
  components: {
    Icon,
    Input,
    Breadcrumb,
    Wizzard,
  },
  data: () => ({
    validation: {
      uppercase: false,
      lowercase: false,
      hasNumber: false,
      minLength: false,
    },
    password: null,
    confirmPassword: null,
    error: '',
  }) as DataPassword,
  methods: {
    ...mapActions(useDynamicForms, ['setPassword']),
    ...mapActions(useRouteBirdId, ['setRouterDone']),
    checkSamePassword() {
      if (this.password !== this.confirmPassword) {
        this.error = 'As senhas não correspondem.';
      } else {
        this.error = '';
      }
    },
    savePassword() {
      this.checkSamePassword();
      if (this.error === '' && this.isValid && this.password) {
        this.setPassword(this.password);
        this.setRouterDone('pass');
        this.$router.push('/birdId/check');
      }
    },
  },
  computed: {
    isValid() {
      const {
        uppercase, lowercase, hasNumber, minLength,
      } = this.validation;
      return uppercase
        && lowercase
        && hasNumber
        && minLength
        && this.confirmPassword
        && !this.error;
    },
  },
  watch: {
    password(current: string) {
      const lastLetter = current.slice(-1);

      if ((/[\\"/'/`/´]/g).test(lastLetter)) {
        this.error = 'caracter inválido';
      } else {
        this.error = '';
      }

      const lowerCaseLetters = /[a-z]/g;
      if (current.match(lowerCaseLetters)) {
        this.validation.lowercase = true;
      } else {
        this.validation.lowercase = false;
      }
      const upperCaseLetters = /[A-Z]/g;
      if (current.match(upperCaseLetters)) {
        this.validation.uppercase = true;
      } else {
        this.validation.uppercase = false;
      }
      const numbers = /[0-9]/g;
      if (current.match(numbers)) {
        this.validation.hasNumber = true;
      } else {
        this.validation.hasNumber = false;
      }
      if (current.length >= 8) {
        this.validation.minLength = true;
      } else {
        this.validation.minLength = false;
      }

      if (current.length > 0 && this.confirmPassword) {
        this.checkSamePassword();
      }
    },
    confirmPassword(current: string) {
      if (current.length > 0) {
        this.checkSamePassword();
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    const useRouteBirdIdStore = useRouteBirdId();
    const dynamicFormsStore = useDynamicForms();
    const solicitationStore = useSolicitation();
    const routesDone = useRouteBirdIdStore.getRoutesDone;

    if (!solicitationStore.getSolicitation.voucher) {
      return next('/');
    }
    if (!routesDone.form) {
      return next('/form');
    }
    if (dynamicFormsStore.getPassword) {
      return next('/birdId/check');
    }
    return next();
  },
});
