import { useDynamicForms } from '@/store/Pinia/useDynamicForms.store';
import { ObjMunicipio } from '@/store/Pinia/useMain.store';

export default function getMunicipioByNameAndUf(municipioName: string, ufName: string) {
  const store = useDynamicForms();
  if (municipioName && ufName) {
    return store.getMunicipios.find(
      (x: ObjMunicipio) => x.nome === municipioName && x.uf === ufName,
    );
  }

  return false;
}

export function getMunicipioByIBGECode(ibgeCode: string) {
  const store = useDynamicForms();
  const municipio = store.getMunicipios.find((x) => x.idmunicipio === ibgeCode);
  return municipio;
}
