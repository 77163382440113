
import { defineComponent } from 'vue';
import Contact from '@/components/Molecules/Contact.vue';
import Icon from '@/components/Atoms/Icon.vue';
import { Tooltip } from 'bootstrap';
import { mapState } from 'pinia';
import { useSolicitation } from '@/store/Pinia/useSolicitation.store';
import { useAR } from '@/store/Pinia/useAR.store';

export default defineComponent({
  components: {
    Contact,
    Icon,
  },
  data: () => ({
    solicitationCode: '',
    copyToClipboardTooltip: null as Tooltip | null,
    messages: [
      {
        type: 'feedback-http-email-dns-ontime-wo-qsa',
        messages: [
          'Agora um de nossos Agentes fará a <strong>análise dos documentos.</strong>',
          'Assim que estiver tudo certo, você <strong>receberá as instruções para os próximos passos no seu e-mail.</strong>',
        ],
      },
      {
        type: 'feedback-http-dns-later-w-qsa',
        messages: [
          'Assim que você concluir a <strong>validação do seu domínio</strong> e estiver tudo certo, você <strong>receberá as instruções para os próximos passos no seu e-mail.</strong>',
        ],
      },
      {
        type: 'feedback-http-dns-later-wo-qsa',
        messages: [
          'Agora um de nossos Agentes fará a <strong>análise dos documentos.</strong>',
          'Enquanto isso,  estaremos aguardando a propagação do DNS com a <strong>validação do seu domínio.</strong> Você poderá consultar o andamento da validação <strong>através do e-mail que enviamos pra você.</strong>',
          'Assim estiver tudo certo, você <strong>receberá as instruções para os próximos passos no seu e-mail.</strong>',
        ],
      },
      {
        type: 'feedback-dns-later-wo-qsa',
        messages: [
          'Agora um de nossos Agentes fará a <strong>análise dos documentos.</strong>',
          'Você também precisará concluir a <strong>validação do seu domínio.</strong>',
          'Assim estiver tudo certo, você <strong>receberá as instruções para os próximos passos no seu e-mail.</strong>',
        ],
      },
    ],
  }),
  computed: {
    ...mapState(useSolicitation, ['getSolicitation']),
    ...mapState(useAR, ['arData']),
    selectedMessages(): string[] {
      // eslint-disable-next-line max-len
      return (
        this.messages.find((message) => message.type === this.$route.params.type)?.messages || []
      );
    },
  },
  methods: {
    copyToClipboard(content: string) {
      navigator.clipboard.writeText(content);
      this.copyToClipboardTooltip?.setContent({ '.tooltip-inner': 'Código copiado!' });
    },
    resetContent() {
      this.copyToClipboardTooltip?.setContent({ '.tooltip-inner': 'Clique para copiar' });
    },
    openNewTab() {
      window.open(
        `https://${this.arData.subdomain || ''}.acsoluti.com.br/site/verificar-situacao-de-emissao`,
        '_blank',
      );
    },
  },
  mounted() {
    this.copyToClipboardTooltip = new Tooltip('#copy-solicitation-code-icon', {
      title: 'Clique para copiar',
      placement: 'right',
    });

    this.solicitationCode = this.getSolicitation.cdSolicitacao;
  },
  beforeRouteEnter(to, from, next) {
    const solicitationStore = useSolicitation();
    if (solicitationStore.getSolicitation.cdSolicitacao === '') {
      next('/');
    }
    next();
  },
});
