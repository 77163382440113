import { RemovableRef, useStorage } from '@vueuse/core';
import { defineStore } from 'pinia';

export type RoutesType = {
  voucher: boolean;
  form: boolean;
  pass: boolean;
};

type State = {
  routes: RemovableRef<RoutesType>;
}

export const STATE_DEFAULT = {
  voucher: false,
  form: false,
  pass: false,
};

export const useRouteBirdId = defineStore({
  id: 'routeBirdId',
  state: () => ({
    routes: useStorage('routes', {
      voucher: false,
      form: false,
      pass: false,
    }),
  } as State),
  getters: {
    getRoutesDone(): RoutesType {
      return this.routes;
    },
  },
  actions: {
    setRouterDone(payload: keyof RoutesType) {
      this.routes[payload] = true;
    },
    clearRoutes() {
      this.routes = STATE_DEFAULT;
    },
  },
});
