
import { ComponentOptions, defineComponent } from 'vue';

import Input from '@/components/Atoms/Input.vue';
import Contact from '@/components/Molecules/Contact.vue';
import Icon from '@/components/Atoms/Icon.vue';
import Breadcrumb from '@/components/Molecules/Breadcrumbs.vue';
import Wizzard from '@/components/Molecules/Wizzard.vue';
import Selectable from '@/components/Atoms/Selectable.vue';
import DynamicForms from '@/components/Molecules/DynamicForms.vue';

import { mapActions, mapState } from 'pinia';
import { useSolicitation } from '@/store/Pinia/useSolicitation.store';
import { FieldType, dynamicFormsController } from '@/infra/sisAr/dynamicForms.controller';
import { useRouteBirdId } from '@/store/Pinia/useRouteBirdId.store';
import { FormDataType, useDynamicForms } from '@/store/Pinia/useDynamicForms.store';
import { useAR } from '@/store/Pinia/useAR.store';

export default defineComponent({
  name: 'formaData',
  components: {
    Breadcrumb,
    Wizzard,
    Input,
    Contact,
    Icon,
    Selectable,
    DynamicForms,
  },
  data: () => ({
    form: {
      codVoucher: '',
      fields: [{}] as FieldType[],
      isPj: false,
      isSSL: false,
      cei: '',
      pis: '',
    },
    model: {
      cei: false,
      pis: false,
    },
    isValid: false,
    dynamicCount: 0,
  }),
  methods: {
    ...mapActions(useDynamicForms, ['setFormData', 'setFormInfo']),
    ...mapActions(useRouteBirdId, ['setRouterDone']),
    async saveAndNavigate() {
      if (this.isValid) {
        const modelDynamic = this.$refs.dinamycComponent as ComponentOptions;
        this.setFormData(modelDynamic.model as FormDataType);
        this.$router.push('/birdId/pass');
        this.setRouterDone('form');
      }
    },
    handleIsValid(value: boolean) {
      this.isValid = value;
    },
  },
  computed: {
    ...mapState(useDynamicForms, ['formData']),
    ...mapState(useSolicitation, ['getProduto', 'getSolicitation']),
  },
  mounted() {
    this.form.isPj = this.getSolicitation.isPj;
    this.form.isSSL = this.getSolicitation.isSSL;
    this.form.cei = this.getProduto.cei;
    this.form.pis = this.getProduto.pis;
  },
  async beforeCreate() {
    const solicitation = useSolicitation();
    const produto = solicitation.getProduto;
    const ar = useAR();
    const model = {
      productId: produto.id,
      sku: produto.sku,
      arsubdomain: ar.getSubdomain,
    };

    const { fields } = await dynamicFormsController.getFields(model);
    let formattedFields = [...fields];

    formattedFields = formattedFields.map((x: FieldType) => {
      if (this.formData.cnpj && x.id === 'cnpj') {
        return {
          ...x,
          disable: true,
        };
      }
      return { ...x };
    });

    this.form.fields = formattedFields as FieldType[];

    const fieldsInfo = fields.map((field: FieldType) => ({
      id: field.id,
      icon: field.icon,
      label: field.label,
    }));

    this.setFormInfo(fieldsInfo);
    this.dynamicCount += 1;
  },
  beforeRouteEnter(to, from, next) {
    const routes = useRouteBirdId();
    const solicitation = useSolicitation();
    const routesDone = routes.routes;
    if (!solicitation.getSolicitation.voucher || !routesDone.voucher) {
      return next({ path: '/birdId/voucher' });
    }
    return next();
  },
});
