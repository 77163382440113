/* eslint-disable implicit-arrow-linebreak */
import { defineStore } from 'pinia';

import { RemovableRef, useStorage } from '@vueuse/core';

export type ObjMunicipio = {
  idmunicipio: string;
  uf: string;
  nome: string;
};

type State = {
  municipios: RemovableRef<ObjMunicipio[]>;
  idunidade: RemovableRef<string>;
  unidade: RemovableRef<string>;
  cookies: RemovableRef<boolean>;
  enviroment?: RemovableRef<'DEV' | 'HOM' | 'PROD'>;
};

export const STATE_DEFAULT = {
  idunidade: '',
  unidade: '',
  municipios: [],
  ufs: [],
  pass: '',
  cookies: true,
};

export const useMainStore = defineStore({
  id: 'main',
  state: () =>
    ({
      idunidade: useStorage('idunidade', ''),
      unidade: useStorage('unidade', ''),
      cookies: useStorage('cookies', false),
      enviroment: undefined,
    } as State),
  //  region [GETTERS]
  getters: {},
  // end_region [GETTERS]
  actions: {
    fetchEnviroment() {
      const { hostname } = window.location;
      if (hostname.includes('locahost')) this.enviroment = 'DEV';
      if (hostname.includes('dev')) this.enviroment = 'DEV';
      if (hostname.includes('dev')) this.enviroment = 'HOM';

      this.enviroment = 'PROD';
    },
    setUnidade(unidade: string) {
      this.unidade = unidade;
    },
    setUnidadeId(unidadeId: string) {
      this.unidade = unidadeId;
    },
    clearData() {
      this.$state = {
        ...STATE_DEFAULT,
        cookies: this.cookies,
      };
    },
  },
});
