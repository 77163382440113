import FAQLayout from '@/layouts/FAQLayout.vue';
import { RouteRecordRaw } from 'vue-router';
import GenerateCSRRequestView from '@/views/FAQ/GenerateCSRRequest.vue';
import SignDigitalTermView from '@/views/FAQ/SignDigitalTerm.vue';
import InstallOpenSSLWindowsView from '@/views/FAQ/InstallOpenSSLWindows.vue';
import AWSKMSView from '@/views/FAQ/AWSKMS.vue';
import DigitalCertificatePluginPage from '@/views/FAQ/DigitalCertificatePluginPage.vue';

const routesFAQ: Array<RouteRecordRaw> = [
  {
    path: '/faq',
    component: FAQLayout,
    redirect: {
      name: 'generateCSRResquest',
    },
    children: [
      {
        path: 'generate-csr-request',
        name: 'generateCSRResquest',
        component: GenerateCSRRequestView,
        meta: { pageName: 'Gerar requisição (CRS)' },
      },
      {
        path: 'sign-digital-term',
        name: 'signDigitalTerm',
        component: SignDigitalTermView,
        meta: { pageName: 'Assinar termo digital' },
      },
      {
        path: 'install-open-ssl-windows',
        name: 'installOpenSSLWindows',
        component: InstallOpenSSLWindowsView,
        meta: { pageName: 'Instalar Open SSL - Windows' },
      },
      {
        path: 'digital-certificate-plugin',
        name: 'digitalCertificatePluginPage',
        component: DigitalCertificatePluginPage,
        meta: { pageName: 'Plugin de instalação de Certificados Digitais A1' },
      },
      {
        path: 'aws-kms',
        name: 'AWSKMS',
        component: AWSKMSView,
        meta: { pageName: 'AWS KMS' },
      },

    ],
  },
];

export default routesFAQ;
