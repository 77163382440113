
import { defineComponent } from 'vue';
import { useModalLoading } from '@/store/Pinia/useModalLoading.store';
import { mapActions, mapState } from 'pinia';
import Icon from '@/components/Atoms/Icon.vue';
import Wizzard from '@/components/Molecules/Wizzard.vue';
import Contact from '@/components/Molecules/Contact.vue';
import { useDomainValidation } from '@/store/Pinia/useDomainValidation.store';
import httpValidationController from '@/infra/DomainValidation/http.controller';
import { useDynamicForms } from '@/store/Pinia/useDynamicForms.store';
import { useSolicitation } from '@/store/Pinia/useSolicitation.store';
import { useToastGlobal } from '@/store/Pinia/useToastGlobal.store';
import ModalAwaitVue from './ModalAwait.vue';

export default defineComponent({
  components: {
    Icon,
    Wizzard,
    Contact,
    ModalAwaitVue,
  },
  data() {
    return {
      domain: '',
      firstFolder: '',
      secondFolder: '',
      links: Array<string>(),
      validationFileContent: '',
      validationFileName: '',
    };
  },
  computed: {
    ...mapState(useDomainValidation, ['domainHttpValidationData']),
    ...mapState(useDynamicForms, ['getPassword']),
    ...mapState(useSolicitation, ['getSolicitation']),
  },
  methods: {
    ...mapActions(useModalLoading, ['setIsLoading']),
    ...mapActions(useToastGlobal, ['toggleToastGlobal']),
    ...mapActions(useDomainValidation, ['clearDomainHttpValidationData']),
    openModalAwait() {
      (document.getElementById('modal-await-button') as HTMLButtonElement).click();
    },
    downloadValidationFile() {
      const blob = new Blob([this.validationFileContent], { type: 'text/plain;charset=utf-8' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = this.validationFileName;
      link.click();
    },
    async validateDomain() {
      try {
        this.setIsLoading({
          isLoading: true,
          title: 'Aguarde enquanto validamos seu domínio.',
          message: '',
          image: 'clock',
        });

        await httpValidationController.validate({
          codigo: this.getSolicitation.cdSolicitacao,
          senha: this.getPassword,
          domain: this.domain,
        });

        this.clearDomainHttpValidationData();

        if (!this.getSolicitation.isQsa) {
          return this.$router.push({ path: '/domain-validation/validation-success/feedback-http-dns-later-wo-qsa' });
        }

        return this.$router.push({ path: '/domain-validation/validation-success/feedback-http-dns-later-w-qsa' });
      } catch (error) {
        return this.toggleToastGlobal({
          isShow: true,
          type: 'danger',
          message: 'Erro ao validar o domínio',
        });
      } finally {
        setTimeout(() => {
          this.setIsLoading({ isLoading: false });
        }, 1000);
      }
    },
  },
  mounted() {
    this.domain = this.domainHttpValidationData.domain;
    // eslint-disable-next-line prefer-destructuring
    this.firstFolder = this.domainHttpValidationData.check_endpoint.split('/')[1];
    // eslint-disable-next-line prefer-destructuring
    this.secondFolder = this.domainHttpValidationData.check_endpoint.split('/')[2];
    this.links = this.domainHttpValidationData.links;
    this.validationFileContent = this.domainHttpValidationData.file_content;
    this.validationFileName = this.domainHttpValidationData.file_name;
  },
  beforeRouteEnter(to, from, next) {
    const solicitationStore = useSolicitation();
    if (solicitationStore.getSolicitation.cdSolicitacao === '') {
      next('/');
    }
    next();
  },
});
