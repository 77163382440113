<template>
  <div class="contact-wrapper">
    <div class="contact">
      <slot></slot>
    </div>
  </div>
</template>

<style scoped>
.contact-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  width: 100%;
  cursor: pointer;
}

.contact:hover {
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.16);
}

.contact {
  display: flex;
  padding: 16px;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  gap: 0.5rem;
  border: 1px solid #e6e7ec;
  border-radius: 10px;
}
</style>
