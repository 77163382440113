
import { defineComponent } from 'vue';
import Icon from '@/components/Atoms/Icon.vue';
import Contact from '@/components/Molecules/Contact.vue';
import Wizzard from '@/components/Molecules/Wizzard.vue';
import Selectable from '@/components/Atoms/Selectable.vue';
import Upload from '@/components/Molecules/Upload.vue';
import Camera from '@/components/Molecules/Camera.vue';
import { typesCompany, documentsList } from '@/store/JSON/documentsJson';
import mobileAndTabletCheck from '@/utils/browserDeviceCheck';
import { mapActions, mapState } from 'pinia';
import { useToastGlobal } from '@/store/Pinia/useToastGlobal.store';
import sentFilesController from '@/infra/sisAr/sentFiles.controller';
import { useSolicitation } from '@/store/Pinia/useSolicitation.store';
import { useModalLoading } from '@/store/Pinia/useModalLoading.store';
import FilesLoader from '@/components/Molecules/FilesLoader.vue';

type DocumentsSegment = 'Sociedade' | 'Empresário Individual' | 'Das demais Entidades de Direito Privado' | 'Das Entidades de Direito Público';

interface IObjectDocument {
  nome: string;
  type: DocumentsSegment,
  list: string[],
  notas: string[],
}

export default defineComponent({
  name: 'DocumentsUpload',
  components: {
    Icon,
    Wizzard,
    Selectable,
    Contact,
    Camera,
    Upload,
    FilesLoader,
  },
  data: () => ({
    form: {
      types: typesCompany,
      documents: [] as Array<IObjectDocument>,
    },
    companySegmentSeleted: '',
    companyTypeSelected: '',
    companyType: {} as IObjectDocument,
    isMobile: false,
    filesLoaded: [] as Array<File>,
    extentionsAllows: ['png', 'jpg', 'jpeg', 'doc', 'docs', 'docx', 'pdf'],
  }),
  watch: {
    companySegmentSeleted(curr: string) {
      this.form.documents = documentsList.filter((x: IObjectDocument) => x.type === curr);
    },
    companyTypeSelected(curr) {
      const company = this.form.documents.find((x: IObjectDocument) => x.nome === curr);
      if (company) {
        this.companyType = company;
      }
    },
  },
  computed: {
    ...mapState(useSolicitation, ['getSolicitation']),
    isInvalid() {
      return this.filesLoaded.length === 0 || this.findDocumentError();
    },
    hasNotes() {
      return this.companyType.notas && this.companyType.notas.length > 0;
    },
  },
  methods: {
    ...mapActions(useToastGlobal, ['toggleToastGlobal']),
    ...mapActions(useModalLoading, ['setIsLoading']),
    handleAddFile(newFiles: File[]) {
      if (this.filesLoaded.length >= 10) {
        return;
      }
      if (this.filesLoaded.length > 0) {
        this.filesLoaded = [...this.filesLoaded, ...newFiles];
      } else {
        this.filesLoaded = [...newFiles];
      }
    },
    handleDeleteFile(indexFile: number) {
      const filtered = this.filesLoaded.filter((x: File, i: number) => i !== indexFile);
      this.filesLoaded = filtered;
    },
    async sendFiles() {
      try {
        this.setIsLoading(
          {
            isLoading: true,
            title: 'Aguarde enquanto enviamos seus documentos',
            message: '',
            image: 'fileUpload',
          },
        );

        await sentFilesController.sendFiles(this.filesLoaded);

        if (this.getSolicitation.isSSL) {
          this.$router.push('/domain-validation');
        } else {
          this.$router.push('/success-upload-files');
        }
      } catch (error) {
        this.toggleToastGlobal({
          isShow: true,
          message: 'Houve um erro ao enviar seus documentos, tente novamente mais tarde',
        });
      } finally {
        this.setIsLoading({ isLoading: false });
      }
    },
    findDocumentError() {
      return !!this.filesLoaded.find((x: File) => !this.extentionsAllows.includes(x.type.split('/')[1]));
    },
  },
  mounted() {
    this.isMobile = mobileAndTabletCheck();
  },
  beforeRouteEnter(to, from, next) {
    const solicitationStore = useSolicitation();

    if (!solicitationStore.getSolicitation.cdSolicitacao) {
      return next('/');
    }
    return next();
  },
});
