type DocumentsSegment = 'Sociedade' | 'Empresário Individual' | 'Das demais Entidades de Direito Privado' | 'Das Entidades de Direito Público';

interface IObjectDocument {
  nome: string;
  type: DocumentsSegment,
  list: string[],
  notas: string[],
}

const typesCompany = [
  { nome: 'Sociedade' },
  { nome: 'Empresário Individual' },
  { nome: 'Das demais Entidades de Direito Privado' },
  { nome: 'Das Entidades de Direito Público' },
];

const documentsList = [
  {
    nome: 'Sociedade Unipessoal de Advocacia',
    list: [
      'Cartão CNPJ;',
      'Contrato social devidamente registrado;',
      'Documentos do responsável e representante legal da Pessoa Jurídica;',
    ],
    type: 'Sociedade',
    notas: [`Caso exista alterações no contrato social, estas deverão ser apresentadas ou
    a alteração que conste a consolidação. E, caso exista alterações seguintes a última
    consolidada, também deverão ser apresentadas, devidamente registradas.`],
  },
  {
    nome: 'Sociedade Limitada (LTDA)',
    list: [
      'Cartão CNPJ;',
      'Contrato social devidamente registrado;',
      'Documentos do responsável e representante legal da Pessoa Jurídica;',
    ],
    type: 'Sociedade',
    notas: [`Caso exista alterações no contrato social, estas deverão ser apresentadas ou
    a alteração que conste a consolidação. E, caso exista alterações seguintes a última
    consolidada, também deverão ser apresentadas, devidamente registradas.`],
  },
  {
    nome: 'Sociedade Simples',
    list: [
      'Cartão CNPJ;',
      'Contrato social devidamente registrado;',
      'Documentos do responsável e representante legal da Pessoa Jurídica;',
    ],
    type: 'Sociedade',
    notas: [`Caso exista alterações no contrato social, estas deverão ser apresentadas ou
    a alteração que conste a consolidação. E, caso exista alterações seguintes a última
    consolidada, também deverão ser apresentadas, devidamente registradas.`],
  },
  {
    nome: 'Sociedade em Nome Coletivo',
    list: [
      'Cartão CNPJ;',
      'Contrato social devidamente registrado;',
      'Documentos do responsável e representante legal da Pessoa Jurídica;',
    ],
    type: 'Sociedade',
    notas: [`Caso exista alterações no contrato social, estas deverão ser apresentadas ou
    a alteração que conste a consolidação. E, caso exista alterações seguintes a última
    consolidada, também deverão ser apresentadas, devidamente registradas.`],
  },
  {
    nome: 'Sociedade em Comandita Simples',
    list: [
      'Cartão CNPJ;',
      'Contrato social devidamente registrado;',
      'Documentos do responsável e representante legal da Pessoa Jurídica;',
    ],
    type: 'Sociedade',
    notas: [`Caso exista alterações no contrato social, estas deverão ser apresentadas ou
    a alteração que conste a consolidação. E, caso exista alterações seguintes a última
    consolidada, também deverão ser apresentadas, devidamente registradas.`],
  },
  {
    nome: 'Sociedade em Comandita por Ações',
    list: [
      'Cartão CNPJ;',
      'Estatuto devidamente registrado;',
      'Ata de eleição e posse da Diretoria devidamente registrada;',
      'Documentos do responsável e representante legal da Pessoa Jurídica;',
    ],
    type: 'Sociedade',
    notas: ['O mandato do Diretor que responde pela sociedade deve estar vigente.'],
  },
  {
    nome: 'Sociedade de Economia Mista',
    list: [
      'Cartão CNPJ;',
      'Estatuto devidamente registrado;',
      'Ata de eleição e posse da Diretoria devidamente registrada;',
      'Documentos do responsável e representante legal da Pessoa Jurídica;',
    ],
    type: 'Sociedade',
    notas: ['O mandato do Diretor que responde pela sociedade deve estar vigente.'],
  },
  {
    nome: 'Sociedade em Conta de Participação',
    list: [
      'Cartão CNPJ;',
      'Documento que comprove a existência da Sociedade em Conta de Participação entre os sócios ostensivo e participante, sem necessidade de registro em qualquer órgão;',
      'Documentos do responsável e representante legal da Pessoa Jurídica;',
    ],
    type: 'Sociedade',
    notas: ['O mandato do Diretor que responde pela sociedade deve estar vigente.'],
  },
  {
    nome: 'Sociedade Anônima',
    list: [
      'Cartão CNPJ;',
      'Estatuto Social, devidamente registrado;',
      'Ata de eleição ou posse da Diretoria e/ou Conselho da S/A devidamente registrada.',
      'Documentos do responsável e representante legal da Pessoa Jurídica;',
    ],
    type: 'Sociedade',
    notas: [
      `Caso exista alterações no contrato social, estas deverão ser apresentadas ou
      a alteração que conste a consolidação. E, caso exista alterações seguintes a última consolidada, também deverão ser apresentadas, devidamente registradas.`,
      'O mandato do(s) administrador(es) deve(m) estar vigente(s).',
      `Se tratando de S/A, onde a diretoria informar que permanece no mandato mesmo
      após o encerramento do prazo de mandato, pode ser solicitado uma Certidão Simplificada
      recente, no qual deverá constar que de fato os Diretores permanecem em seus cargos.`,
    ],
  },
  {
    nome: 'Estabelecimento, no Brasil, de Sociedade Estrangeira',
    type: 'Sociedade',
    list: [
      'Cartão CNPJ;',
      'Ato de instalação do primeiro estabelecimento da sociedade estrangeira no Brasil, acompanhado do ato de nomeação do seu representante no País, ambos registrados na Junta Comercial ou no cartório RCPJ;',
      'Documentos do responsável e representante legal da Pessoa Jurídica;',
    ],
    notas: [],
  },
  {
    nome: 'Cooperativa',
    type: 'Sociedade',
    list: [
      'Cartão CNPJ;',
      'Estatuto Social da Cooperativa devidamente registrado;',
      'Ata de eleição e posse da Diretoria devidamente registrada;',
      'Documentos do responsável e representante legal da Pessoa Jurídica;',
    ],
    notas: [],
  },
  {
    nome: 'Eireli',
    list: [
      'Cartão CNPJ;',
      'Contrato social devidamente registrado;',
      'Documentos do responsável e representante legal da Pessoa Jurídica;',
    ],
    type: 'Empresário Individual',
    notas: [`Caso exista alterações no contrato social, estas deverão ser apresentadas ou
    a alteração que conste a consolidação. E, caso exista alterações seguintes a última
    consolidada, também deverão ser apresentadas, devidamente registradas.`],
  },
  {

    nome: 'Empresário Individual',
    list: [
      'Cartão CNPJ;',
      'Requerimento de empresário ou Instrumento de Inscrição de Empresário Individual, devidamente registrado - Inscrição e alterações (quando houver) ou alteração consolidado*;',
      'Documentos do responsável e representante legal da Pessoa Jurídica;',
    ],
    type: 'Empresário Individual',
    notas: ['Será considerado consolidado, no caso do Requerimento de Empresário, aquele ato que possui todos os dados do formulário devidamente preenchidos, exceto o campo “Início das atividades” e “E-mail”.'],
  },
  {
    nome: 'Microempreendedor Individual - MEI',
    list: [
      'Cartão CNPJ;',
      'Ficha de cadastro do MEI – Certificado de Condição de Microempreendedor;',
      'Documentos do responsável e representante legal da Pessoa Jurídica;',
    ],
    type: 'Empresário Individual',
    notas: ['Quando houver o desenquadramento de MEI, deverá ser apresentado um documento emitido pela Junta Comercial onde conste que a empresa foi desenquadrada ou a consulta do Simples Nacional que contenha a informação do desenquadramento.'],
  },
  {
    nome: 'Empresa Individual Imobiliária',
    type: 'Empresário Individual',
    list: [
      'Cartão CNPJ;',
      'Certidão emitida pelo Cartório de Registro de Imóveis, comprovando o registro do empreendimento, caso tenha sido registrado; ou',
      'Documento que comprove a existência de qualquer ajuste preliminar que caracterize a alienação de unidade imobiliária ou lote de terreno, ainda que sem registro em cartório;',
      'Documentos do responsável e representante legal da Pessoa Jurídica;',
    ],
    notas: [],
  },

  {
    nome: 'Produtor Rural',
    list: [
      'Cartão CNPJ;',
      'Certidão de inteiro teor do Imóvel atualizada (o prazo máximo para que seja considerada válida é de 30 dias);',
      'Se arrendatário: juntar o contrato de arrendamento, onde o arrendante tem que ser aquele que consta da certidão de inteiro teor;',
      'Para o contribuinte individual registrado no Estado de São Paulo: é necessária somente a consulta cadastral do CADESP, desde que esteja com todas as informações completas. A consulta CADESP tem validade de 7 dias;',
      'Documentos do responsável e representante legal da Pessoa Jurídica;',
    ],
    type: 'Empresário Individual',
    notas: ['Quando houver o desenquadramento de MEI, deverá ser apresentado um documento emitido pela Junta Comercial onde conste que a empresa foi desenquadrada ou a consulta do Simples Nacional que contenha a informação do desenquadramento.'],
  },
  {
    nome: 'Consórcio Simples',
    type: 'Das demais Entidades de Direito Privado',
    list: [
      'Cartão CNPJ;',
      'Contrato de consórcio devidamente registrado;',
      'Documentos do responsável e representante legal da Pessoa Jurídica;',
    ],
  },
  {
    nome: 'Consórcio de Sociedades',
    type: 'Das demais Entidades de Direito Privado',
    list: [
      'Cartão CNPJ;',
      'Contrato de consórcio devidamente registrado;',
      'Documentos do responsável e representante legal da Pessoa Jurídica;',
    ],
  },
  {
    nome: 'Consórcio de Empregadores',
    type: 'Das demais Entidades de Direito Privado',
    list: [
      'Cartão CNPJ;',
      'Documento de constituição do consórcio simplificado de produtores rurais, em que conste a quem cabe a administração do consórcio, devidamente registrado.',
      'Documentos do responsável e representante legal da Pessoa Jurídica;',
    ],
  },
  {
    nome: 'Consórcio Privado',
    type: 'Das demais Entidades de Direito Privado',
    list: [
      'Cartão CNPJ;',
      'Estatuto Social, devidamente registrado;',
      'Ata de eleição e posse da Diretoria devidamente registrada. O mandato do Diretor que responde pela entidade deve estar vigente;',
      'Documentos do responsável e representante legal da Pessoa Jurídica;',
    ],

    notas: ['algumas instituições de Serviço Social Autônomo utilizam lei de criação como ato constitutivo.'],
  },
  {
    nome: 'Associação',
    type: 'Das demais Entidades de Direito Privado',
    list: [
      'Cartão CNPJ;',
      'Estatuto Social, devidamente registrado;',
      'Ata de eleição e posse da Diretoria devidamente registrada. O mandato do Diretor que responde pela entidade deve estar vigente;',
      'Documentos do responsável e representante legal da Pessoa Jurídica;',
    ],

    notas: ['algumas instituições de Serviço Social Autônomo utilizam lei de criação como ato constitutivo.'],
  },
  {
    nome: 'Sindicato',
    type: 'Das demais Entidades de Direito Privado',
    list: [
      'Cartão CNPJ;',
      'Estatuto Social, devidamente registrado;',
      'Ata de eleição e posse da Diretoria devidamente registrada. O mandato do Diretor que responde pela entidade deve estar vigente;',
      'Documentos do responsável e representante legal da Pessoa Jurídica;',
    ],

    notas: ['algumas instituições de Serviço Social Autônomo utilizam lei de criação como ato constitutivo.'],
  },
  {
    nome: 'Federação',
    type: 'Das demais Entidades de Direito Privado',
    list: [
      'Cartão CNPJ;',
      'Estatuto Social, devidamente registrado;',
      'Ata de eleição e posse da Diretoria devidamente registrada. O mandato do Diretor que responde pela entidade deve estar vigente;',
      'Documentos do responsável e representante legal da Pessoa Jurídica;',
    ],

    notas: ['algumas instituições de Serviço Social Autônomo utilizam lei de criação como ato constitutivo.'],
  },
  {
    nome: 'Confederação',
    type: 'Das demais Entidades de Direito Privado',
    list: [
      'Cartão CNPJ;',
      'Estatuto Social, devidamente registrado;',
      'Ata de eleição e posse da Diretoria devidamente registrada. O mandato do Diretor que responde pela entidade deve estar vigente;',
      'Documentos do responsável e representante legal da Pessoa Jurídica;',
    ],

    notas: ['algumas instituições de Serviço Social Autônomo utilizam lei de criação como ato constitutivo.'],
  },
  {
    nome: 'Fundação Privada',
    type: 'Das demais Entidades de Direito Privado',
    list: [
      'Cartão CNPJ;',
      'Estatuto Social, devidamente registrado;',
      'Ata de eleição e posse da Diretoria devidamente registrada. O mandato do Diretor que responde pela entidade deve estar vigente;',
      'Documentos do responsável e representante legal da Pessoa Jurídica;',
    ],

    notas: ['algumas instituições de Serviço Social Autônomo utilizam lei de criação como ato constitutivo.'],
  },
  {
    nome: 'Instituto',
    type: 'Das demais Entidades de Direito Privado',
    list: [
      'Cartão CNPJ;',
      'Estatuto Social, devidamente registrado;',
      'Ata de eleição e posse da Diretoria devidamente registrada. O mandato do Diretor que responde pela entidade deve estar vigente;',
      'Documentos do responsável e representante legal da Pessoa Jurídica;',
    ],

    notas: ['algumas instituições de Serviço Social Autônomo utilizam lei de criação como ato constitutivo.'],
  },
  {
    nome: 'Serviço Social Autônomo',
    type: 'Das demais Entidades de Direito Privado',
    list: [
      'Cartão CNPJ;',
      'Estatuto Social, devidamente registrado;',
      'Ata de eleição e posse da Diretoria devidamente registrada. O mandato do Diretor que responde pela entidade deve estar vigente;',
      'Documentos do responsável e representante legal da Pessoa Jurídica;',
    ],

    notas: ['algumas instituições de Serviço Social Autônomo utilizam lei de criação como ato constitutivo.'],
  },
  {
    nome: 'Fundo de Investimento',
    type: 'Das demais Entidades de Direito Privado',
    list: [
      'Cartão CNPJ;',
      'Instrumento de Constituição do Fundo de Investimento, devidamente registrado;',
      'Documentos do responsável e representante legal da Pessoa Jurídica;',
    ],
  },
  {
    nome: 'Comunidade Indígena',
    type: 'Das demais Entidades de Direito Privado',
    list: [
      'Cartão CNPJ;',
      'Certidão emitida pela Funai contendo o nome da comunidade, seu endereço e representante;',
      'Ata da assembleia de eleição do representante legal publicada no Diário Oficial da União.',
      'Documentos do responsável e representante legal da Pessoa Jurídica;',
    ],
  },
  {
    nome: 'Empresa Domiciliada no Exterior',
    type: 'Das demais Entidades de Direito Privado',
    list: [
      'Cartão CNPJ;',
      'Ato de constituição da entidade estrangeira, com tradução juramentada;',
      'Ato que demonstre os poderes de administração do representante legal no país de origem da entidade estrangeira, caso tal informação não conste do ato de constituição;',
      'Ato de nomeação do representante da entidade no Brasil;',
      'Documentos do responsável e representante legal da Pessoa Jurídica;',
    ],

    notas: ['Tradução juramentada: é um importante documento que atribui autenticidade a documentos estrangeiros, uma vez que a nossa legislação não reconhece ou aceita documentos escritos em outros idiomas. Geralmente é expedida por órgãos, como Juntas Comerciais e Consulados'],
  },
  {
    nome: 'Organização Religiosa (Exceto Igreja Católica)',
    type: 'Das demais Entidades de Direito Privado',
    list: [
      'Cartão CNPJ;',
      'Estatuto Social, devidamente registrado;',
      'Ata de eleição e posse da Diretoria devidamente registrada. O mandato do Diretor que responde pela entidade deve estar vigente;',
      'Documentos do responsável e representante legal da Pessoa Jurídica;',
    ],
  },
  {
    nome: 'Organização Religiosa – Igreja Católica',
    type: 'Das demais Entidades de Direito Privado',
    list: [
      'Cartão CNPJ;',
      'Carta Papal (traduzida por tradutor juramentado) ou documento equivalente emitido por autoridade responsável, de acordo com a hierarquia da Igreja Católica, devidamente registrado;',
      'Documento de nomeação do responsável pela Igreja;',
      'Documentos do responsável e representante legal da Pessoa Jurídica;',
    ],
  },
  {
    nome: 'Partido Político',
    type: 'Das demais Entidades de Direito Privado',
    list: [
      'Cartão CNPJ;',
      'Estatuto Social devidamente registrado;',
      'Ata de eleição e posse da Diretoria devidamente registrada ou certidão emitida pela Justiça Eleitoral citando o nome do presidente, tempo de exercício e o Partido atual. O mandato do Presidente que responde pelo partido deve estar vigente;',
      'Documentos do responsável e representante legal da Pessoa Jurídica;',
    ],
  },
  {
    nome: 'Órgão de Direção Local (ou Regional) de Partido Político',
    type: 'Das demais Entidades de Direito Privado',
    list: [
      'Cartão CNPJ;',
      'Documento de constituição do órgão partidário, registrado na Justiça Eleitoral;',
      'Ato de designação de seus dirigentes, registrado na Justiça Eleitoral ou certidão emitida pela Justiça Eleitoral citando o nome do responsável, tempo de exercício e o Partido atual.',
      'Documentos do responsável e representante legal da Pessoa Jurídica;',
    ],
  },
  {
    nome: 'Candidato a Cargo Eletivo',
    type: 'Das demais Entidades de Direito Privado',
    list: [
      'Cartão CNPJ;',
      'Os documentos necessários para a emissão do certificado para Candidato a Cargo Eletivo devem ser retirados dos links abaixo e anexados com os demais documentos de PF do titular; http://www.receita.fazenda.gov.br/PessoaJuridica/CNPJ/Eleicoes/consulta.asp | http://www.tse.jus.br/eleicoes/eleicoes-2016/divulgacao-de-candidaturas-e-contas-el eitorais',
      'Documentos do responsável e representante legal da Pessoa Jurídica;',
    ],
    notas: [],
  },
  {
    nome: 'Cartório',
    type: 'Das demais Entidades de Direito Privado',
    list: [
      'Cartão CNPJ;',
      'Consulta do cartório realizada no site do CNJ, através do link http://www.cnj.jus.br/corregedoria/justica_aberta/?#',
      'Documento de nomeação do Tabelião (Ata, Apostila ou publicação no Diário Oficial);',
      'Documentos do responsável e representante legal da Pessoa Jurídica;',
    ],
    notas: [],
  },
  {
    nome: 'Condomínio',
    type: 'Das demais Entidades de Direito Privado',
    list: [
      'Cartão CNPJ;',
      'Ato constitutivo do Condomínio com registro em Cartório;',
      'Ata de eleição e posse do Síndico. O mandato do Síndico que responde pelo condomínio deve estar vigente. Quando não informado o tempo de mandato do síndico, será de dois anos, conforme prevê o código civil;',
      'Documentos do responsável e representante legal da Pessoa Jurídica;',
    ],
    notas: [
      'Entende-se como ato constitutivo o testamento, a escritura pública ou particular de instituição, a convenção, a minuta da futura convenção, ou, caso o condomínio não possua os documentos constitutivos citados anteriormente, poderá ser apresentado uma certidão de individualização emitida pelo cartório.',
      'Para os condomínios não constituídos nos termos da Legislação, ou seja, os que possuem seu ato constitutivo registrado em cartório antes do ano de 2003 não é obrigatório que seu registro tenha ocorrido em Cartório de Imóveis.',
      'Caso a ata de eleição não esteja registrada, a mesma deverá estar acompanhada da lista dos participantes da eleição, onde conste no mínimo dois condôminos participantes, não podendo estes serem membros da mesa diretora da assembleia.',
      'Como identificar uma Certidão de Individualização: Para ser considerada uma Certidão de Individualização, deverá conter três informações mínimas no documento apresentado: endereço, especificação das unidades do condomínio (informações sobre os condomínios), número da matrícula e livro onde foi registrado o condomínio. Para Condomínios com data de registro anterior a 1974, não será cobrado o número da matrícula, mas sim o do registro.',
      'Matrícula mãe, Certidão de ônus reais, Certidão de inteiro teor e Memorial de incorporação.',
    ],
  },
  {
    nome: 'Estado ou Distrito Federal',
    type: 'Das Entidades de Direito Público',
    list: [
      'Cartão CNPJ;',
      'Constituição Estadual;',
      'Ata de posse do Governador. O mandato do Governador deve estar vigente;',
      'Documentos do responsável e representante legal da Pessoa Jurídica;',
    ],
    notas: [],
  },
  {
    nome: 'Município',
    type: 'Das Entidades de Direito Público',
    list: [
      'Cartão CNPJ;',
      'Lei orgânica ou Lei de criação;',
      'Diploma do Prefeito emitido pela Justiça Eleitoral;',
      'Ata de posse do Prefeito. O mandato do prefeito deve estar vigente;',
      'Documentos do responsável e representante legal da Pessoa Jurídica;',
    ],
    notas: [],
  },
  {
    nome: 'Assembleia Legislativa ou Distrital',
    type: 'Das Entidades de Direito Público',
    list: [
      'Cartão CNPJ;',
      'Constituição Estadual;',
      'Regimento interno devidamente aprovado;',
      'Ata de eleição e posse da Presidência da Assembleia Legislativa devidamente aprovada. O mandato do Presidente que responde pela Assembleia Legislativa deve estar vigente;',
      'Documentos do responsável e representante legal da Pessoa Jurídica;',
    ],
    notas: [],
  },
  {
    nome: 'Câmara de Vereadores',
    type: 'Das Entidades de Direito Público',
    list: [
      'Cartão CNPJ;',
      'Lei orgânica;',
      'Regimento interno;',
      'Ata de eleição e posse da Presidência da Câmara de Vereadores devidamente aprovada. O mandato do Presidente que responde pela Câmara de Vereadores deve estar vigente;',
      'Documentos do responsável e representante legal da Pessoa Jurídica;',
    ],
    notas: [],
  },
  {
    nome: 'Órgão Público',
    type: 'Das Entidades de Direito Público',
    list: [
      'Cartão CNPJ;',
      'Ato legal de criação do órgão público, publicado na forma da lei;',
      'Ato de nomeação ou eleição/posse do seu gestor, publicado na forma da lei ou registrado em órgão competente, conforme o caso;',
      'Documentos do responsável e representante legal da Pessoa Jurídica;',
    ],
    notas: ['verificar a possibilidade de existência de Regimento Interno e solicitá-lo, caso a organização ou administração da instituição não esteja clara.'],
  },
  {
    nome: 'Fundação Pública',
    type: 'Das Entidades de Direito Público',
    list: [
      'Cartão CNPJ;',
      'Ato legal de criação do órgão público, publicado na forma da lei;',
      'Ato de nomeação ou eleição/posse do seu gestor, publicado na forma da lei ou registrado em órgão competente, conforme o caso;',
      'Documentos do responsável e representante legal da Pessoa Jurídica;',
    ],
    notas: ['verificar a possibilidade de existência de Regimento Interno e solicitá-lo, caso a organização ou administração da instituição não esteja clara.'],
  },
  {
    nome: 'Fundo',
    type: 'Das Entidades de Direito Público',
    list: [
      'Cartão CNPJ;',
      'Ato legal de criação do órgão público, publicado na forma da lei;',
      'Ato de nomeação ou eleição/posse do seu gestor, publicado na forma da lei ou registrado em órgão competente, conforme o caso;',
      'Documentos do responsável e representante legal da Pessoa Jurídica;',
    ],
    notas: ['verificar a possibilidade de existência de Regimento Interno e solicitá-lo, caso a organização ou administração da instituição não esteja clara.'],
  },
  {
    nome: 'Autarquia Pública',
    type: 'Das Entidades de Direito Público',
    list: [
      'Cartão CNPJ;',
      'Ato legal de criação do órgão público, publicado na forma da lei;',
      'Ato de nomeação ou eleição/posse do seu gestor, publicado na forma da lei ou registrado em órgão competente, conforme o caso;',
      'Documentos do responsável e representante legal da Pessoa Jurídica;',
    ],
    notas: ['verificar a possibilidade de existência de Regimento Interno e solicitá-lo, caso a organização ou administração da instituição não esteja clara.'],
  },
  {
    nome: 'Conselho ou Autarquia',
    type: 'Das Entidades de Direito Público',
    list: [
      'Cartão CNPJ;',
      'Lei de criação;',
      'Regimento Interno;',
      'Ata de eleição e posse da Diretoria devidamente registrada. O mandato do Diretor e/ou Presidente que responde pela entidade deve estar vigente;',
      'Documentos do responsável e representante legal da Pessoa Jurídica;',
    ],
    notas: [],
  },
  {
    nome: 'Consórcio Público',
    type: 'Das Entidades de Direito Público',
    list: [
      'Cartão CNPJ;',
      'Protocolo de Intenções firmado pelos entes federativos consorciados, publicados na forma da lei;',
      'Ato de nomeação ou eleição/posse do seu responsável, publicado na forma da lei ou registrado em órgão competente, conforme o caso;',
      'Documentos do responsável e representante legal da Pessoa Jurídica;',
    ],
    notas: [],
  },
  {
    nome: 'Empresa Pública',
    type: 'Das Entidades de Direito Público',
    list: [
      'Cartão CNPJ;',
      'Contrato social ou Estatuto devidamente registrado ou publicado na forma da lei;',
      'Ata de assembleia de constituição devidamente registrada ou publicado na forma da lei.',
      'Documentos do responsável e representante legal da Pessoa Jurídica;',
    ],
    notas: [],
  },
  {
    nome: 'Representação Diplomática do Governo Brasileiro no Exterior (Embaixadas, Consulados, Etc.)',
    type: 'Das Entidades de Direito Público',
    list: [
      'Cartão CNPJ;',
      'Certidão ou Declaração do Ministério das Relações Exteriores que informe e qualifique os representantes legais a praticarem atos jurídicos em nome da entidade;',
      'Documentos do responsável e representante legal da Pessoa Jurídica;',
    ],
    notas: [],
  },
  {
    nome: 'Representação Diplomática Estrangeira',
    type: 'Das Entidades de Direito Público',
    list: [
      'Cartão CNPJ;',
      'Declaração emitida pelo MRE contendo o nome do representante diplomático no Brasil e, se conhecida, a data de criação da representação;',
      'Documentos do responsável e representante legal da Pessoa Jurídica;',
    ],
    notas: [],
  },
  {
    nome: 'Organização Internacional',
    type: 'Das Entidades de Direito Público',
    list: [
      'Cartão CNPJ;',
      'Declaração emitida pelo Ministério das Relações Exteriores, contendo o nome do representante da organização internacional no Brasil e, se conhecida, a data de criação da representação;',
      'Documentos do responsável e representante legal da Pessoa Jurídica;',
    ],
    notas: [],
  },
] as IObjectDocument[];

export { typesCompany, documentsList };
