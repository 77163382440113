import { serverPanel } from '@/infra/server';

export type ValidatePanelType = {
  password: string;
  solicitation: string;
  arsubdomain: string;
}

export type EmitPanelType = {
  solicitacao: string;
  senha: string;
  csr: string;
  digitaltermSignature: string;
  arsubdomain: string;
}

export type RecoverPanelType = {
  senha: string;
  solicitacao: string;
}

export type validatePanelResponse = {
  mensagem: {
    certificado: {
      categoria: string;
      nome: string;
      idpolitica: string;
      politica: string;
      perfiliti: string;
      midia: string;
      CN: string;
      validade: string;
      cnpj: string,
      email: string,
      senhausuario: string;
    },
    emissao?: {
      status: string;
      obs: string;
      'digitalterm_hash'?: string;
    },
    kaspersky: {
        direito: boolean
    },
    datetime: string;
    erro?: {
      mensagem: string;
      codigo: number;
    }
  },
  hmac: string;
};

export type EmitPanelResponse = {
  mensagem: {
    status: string;
    mensagem: string;
    data: string;
    datetime: string
    erro?: {
      status: string;
      mensagem: string;
    }
  },
  hmac: string;
};

export type RecoverPanelResponse = {
  mensagem: {
    status: string;
    mensagem: string;
    data: string;
    datetime: string
  },
  hmac: string;
};

export const panelController = {
  validate: async (params: ValidatePanelType) => {
    const response = await serverPanel.post<validatePanelResponse>('validar', {
      senha: params.password,
      solicitacao: params.solicitation,
      arsubdomain: params.arsubdomain,
    });
    return response.data;
  },

  emit: async (params: EmitPanelType) => {
    const response = await serverPanel.post<EmitPanelResponse>('emitir', {
      ...params,
      digitalterm_signature: params.digitaltermSignature,
    });

    return response.data;
  },

  recover: async (params: RecoverPanelType) => {
    const response = await serverPanel.post<RecoverPanelResponse>('recuperar', {
      ...params,
    });

    return response.data;
  },
};
