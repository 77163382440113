
import { Tooltip } from 'bootstrap';
import { defineComponent } from 'vue';
import Icon from '@/components/Atoms/Icon.vue';

type TooltipType = {
  id: number;
  tooltip: Tooltip;
};

export default defineComponent({
  name: 'GenerateCSRRequestView',
  components: {
    Icon,
  },
  data() {
    return {
      tooltips: [] as TooltipType[],
    };
  },
  mounted() {
    this.tooltips.push(
      {
        id: 1,
        tooltip: new Tooltip('#copy-generate-key-and-request-icon', {
          title: 'Clique para copiar',
          placement: 'right',
        }),
      },
    );

    this.tooltips.push(
      {
        id: 2,
        tooltip: new Tooltip('#copy-check-data-icon', {
          title: 'Clique para copiar',
          placement: 'right',
        }),
      },
    );
  },
  methods: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async doCopy(ref: any, id: number) {
      await navigator.clipboard.writeText(ref.textContent.replace('$ ', ''));
      this.tooltips.find((tooltip) => tooltip.id === id)?.tooltip.setContent({ '.tooltip-inner': 'Código copiado!' });
    },
    resetContent(id: number) {
      this.tooltips.find((tooltip) => tooltip.id === id)?.tooltip.setContent({ '.tooltip-inner': 'Clique para copiar' });
    },
  },
});
