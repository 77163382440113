import { router } from '@/router/index';

import { EmissionApiAbstractHandler } from './EmissionApiAbstractHandler';
import { ChainTarget } from '../../../domain/ChainOfResponsibility/Handler';
import { useModalControl } from '@/SignaturePlugin/A1/store/pinia/useModalControl.store';

export class ReturnSisArValidSolicitationStatusA3 extends EmissionApiAbstractHandler {
  public async handle(target: ChainTarget): Promise<any> {
    const certificateType = target?.response?.data?.product?.iti_profile;

    EmissionApiAbstractHandler.firstChainLink = this;

    if (certificateType === 'A3') {
      return ReturnSisArValidSolicitationStatusA3.redirectUserToInstallationGuidePage();
    }

    return super.handle(target);
  }

  public static redirectUserToInstallationGuidePage(): void {
    const modalControlStore = useModalControl();
    modalControlStore.close();

    router.push('/installation-guide-token');
  }
}

export default ReturnSisArValidSolicitationStatusA3;
