
import { defineComponent } from 'vue';
import Icon from '@/components/Atoms/Icon.vue';
import Modal from '@/components/Molecules/Modal.vue';
import InputComponent from '@/components/Atoms/Input.vue';
import loginController, { VoucherParam } from '@/infra/BirdId/controllers/login.controller';
import allValidates from '@/utils/validates';
import { AxiosError } from 'axios';
import { mapActions, mapState } from 'pinia';
import { useMainStore } from '@/store/Pinia/useMain.store';
import { useSolicitation } from '@/store/Pinia/useSolicitation.store';
import { useAR } from '@/store/Pinia/useAR.store';
import { useToastGlobal } from '@/store/Pinia/useToastGlobal.store';
import saveDataVoucher from '@/utils/saveDataVoucher';
import gvsController from '@/infra/sisAr/gvs.controller';
import { GVSStatusEnum } from '@/helpers/EGVS.enum';
import { useDynamicForms } from '@/store/Pinia/useDynamicForms.store';
import { useRouteBirdId } from '@/store/Pinia/useRouteBirdId.store';
import config from '../../../config';

export default defineComponent({
  components: {
    Icon,
    Modal,
    InputComponent,
  },
  data: () => ({
    isMobile: false,
    showCookiesAlert: true,
    model: {
      cpf: '',
      otp: '',
      termos: false,
    },
    errors: {
      cpf: '',
      otp: '',
      ar: '',
    },
  }),
  watch: {
    'model.cpf': function watch(curr: string) {
      if (curr.length === 14 && !allValidates.validateCPF(curr)) {
        this.errors.cpf = 'CPF inválido!';
      } else {
        this.errors.cpf = '';
      }
    },
    'model.otp': function watch(curr: string) {
      if (curr.length > 0) {
        this.errors.otp = '';
      }
    },
  },
  computed: {
    ...mapState(useDynamicForms, ['getFormData']),
    ...mapState(useMainStore, ['cookies', 'unidade']),
    ...mapState(useSolicitation, ['getProduto']),
    ...mapState(useAR, ['arData']),
    isValid() {
      return (
        this.model.cpf
        && this.model.otp.length === 7
        && this.model.termos
        && this.errors.cpf === ''
        && this.errors.otp === ''
      );
    },
  },
  methods: {
    ...mapActions(useRouteBirdId, ['setRouterDone']),
    ...mapActions(useDynamicForms, ['setCPF', 'setFormData']),
    ...mapActions(useMainStore, ['setUnidade']),
    ...mapActions(useSolicitation, ['setCessToken']),
    ...mapActions(useToastGlobal, ['toggleToastGlobal']),
    async authUser() {
      this.$emit('loading-start');

      if (!this.errors.cpf && !this.errors.otp) {
        const requestLogin = {
          username: this.model.cpf,
          otp: this.model.otp,
        };
        try {
          const { data } = await loginController.login(requestLogin);
          const formatted = {
            token: {
              sessionData: data.sessionData,
              sessionId: data.sessionId,
            },
            nome: data.nome,
          };
          this.saveData(formatted);

          if (this.getProduto.token !== '') {
            if (!config.app.negociations.includes(this.getProduto.token)) {
              this.toggleToastGlobal({
                isShow: true,
                message: GVSStatusEnum.NEGOTIATION_NOT_FOUND.mensagem,
              });
              // eslint-disable-next-line no-throw-literal
              throw 'Não existe a negociação para a AR';
            }

            const response = await this.getVoucherBirdId();

            if (response.status !== GVSStatusEnum.VOUCHER_SUCCESS.status) {
              // eslint-disable-next-line max-len, @typescript-eslint/no-unused-vars
              const ArrayEnum = Object.entries(GVSStatusEnum).map(([_, value]) => value);
              const findedEnum = ArrayEnum.find((x) => x.status === response.status);
              this.toggleToastGlobal({
                type: 'danger',
                isShow: true,
                message: findedEnum?.mensagem || 'Erro ao buscar sua negociação',
              });
              throw response.voucher;
            }

            const request: VoucherParam = {
              token: formatted.token,
              arsubdomain: this.arData.subdomain || '',
              voucher: response.voucher || '',
            };

            if (this.unidade) {
              request.unidade = this.unidade;
            }

            const { data: loginResponse } = await loginController.validateVoucher(request);

            const payload = {
              ...loginResponse,
              voucher: response.voucher || '',
            };

            saveDataVoucher(payload);
            this.setRouterDone('voucher');
            this.$router.push('/birdId/form');
            this.closeModal();
            return;
          }
          this.closeModal();
          this.$router.push('/birdId/voucher');
          return;
        } catch (error) {
          const err = error as AxiosError;
          if (err) {
            this.errors.otp = 'Credenciais inválidas!';
          }
        } finally {
          this.$emit('loading-end');
        }
      }
    },
    saveData(data: {
      token: {
        sessionId: string;
        sessionData: string;
      };
      nome: string;
    }) {
      this.setCessToken(data.token);
      this.setCPF(this.model.cpf);
      this.setFormData({
        ...this.getFormData,
        cpf: this.model.cpf,
        nome: data.nome,
      });
    },
    async getVoucherBirdId() {
      const voucherBirdId = await gvsController.generateVoucher(
        this.getFormData.nome,
        this.model.cpf,
        this.getProduto.token,
      );

      return voucherBirdId;
    },
    closeModal() {
      const overlay = document.getElementsByClassName('modal-backdrop');
      const modal = document.getElementById('modalValidationBird');
      [...overlay].forEach((element) => {
        element.remove();
      });
      if (modal) {
        modal.classList.remove('show');
      }
    },
  },
});
