
import {
  defineComponent,
} from 'vue';
import Icon from '@/components/Atoms/Icon.vue';
import Contact from '@/components/Molecules/Contact.vue';
import ModalTokenA3 from '@/components/templates/ModalTokenA3.vue';
import ModalSmartCard from '@/components/templates/ModalSmartCard.vue';
import ModalOnlyUseOnDesktop from '@/components/templates/ModalOnlyUseOnDesktop.vue';
import {
  mapState,
} from 'pinia';
import {
  useSolicitation,
} from '@/store/Pinia/useSolicitation.store';

export default defineComponent({
  name: 'InstallationGuideBirdIdView',
  components: {
    Icon,
    Contact,
    ModalTokenA3,
    ModalOnlyUseOnDesktop,
    ModalSmartCard,
  },
  data: () => ({
    // eslint-disable-next-line global-require
    smartcard: require('@/assets/images/Smartcard.svg'),
    // eslint-disable-next-line global-require
    smartcardAnimation: require('@/assets/images/smartcard.gif'),
    hoverSmartCard: false,
    // eslint-disable-next-line global-require
    tokenA3: require('@/assets/images/tokenA3.svg'),
    // eslint-disable-next-line global-require
    tokenA3Animation: require('@/assets/images/tokena3.gif'),
    hoverTokenA3: false,
    // eslint-disable-next-line global-require
    desktop: require('@/assets/images/desktop.svg'),
    // eslint-disable-next-line global-require
    desktopAnimation: require('@/assets/images/desktop.gif'),
    hoverDesktop: false,
  }),
  computed: {
    ...mapState(useSolicitation, ['getSolicitation']),
    smartcardAnima() {
      if (this.hoverSmartCard === true) {
        return this.smartcardAnimation;
      }
      return this.smartcard;
    },
    tokenA3Anima() {
      if (this.hoverTokenA3 === true) {
        return this.tokenA3Animation;
      }
      return this.tokenA3;
    },
    desktopAnima() {
      if (this.hoverDesktop === true) {
        return this.desktopAnimation;
      }
      return this.desktop;
    },
  },
});
