// eslint-disable-next-line no-shadow
enum UserOperateSystem {
  LINUX = 'Linux',
  MACOS = 'MacOS',
  WINDOWS = 'Windows',
  ANDROID = 'Android',
  IOS = 'iOS',
  UNKNOWN = 'unknown',
}

function getOs(): { os: UserOperateSystem, arch: 32 | 64 | undefined } {
  const userAgent = navigator.userAgent.toUpperCase();

  function getArch(ua?: string): 32 | 64 {
    if (ua?.indexOf('WOW64')) {
      return 64;
    }

    if (ua?.indexOf('WIN64') !== -1) {
      return 64;
    }

    if (ua?.indexOf('x86_64') !== -1) {
      return 64;
    }

    return 32;
  }

  if (userAgent.indexOf('ANDROID') !== -1) {
    return {
      os: UserOperateSystem.ANDROID,
      arch: undefined,
    };
  }

  if (userAgent.indexOf('LIKE MAC') !== -1) {
    return {
      os: UserOperateSystem.IOS,
      arch: undefined,
    };
  }

  if (userAgent.indexOf('MAC') !== -1) {
    return {
      os: UserOperateSystem.MACOS,
      arch: getArch(userAgent),
    };
  }

  if (userAgent.indexOf('WINDOWS') !== -1 || userAgent.indexOf('WIN') !== -1) {
    return {
      os: UserOperateSystem.WINDOWS,
      arch: getArch(userAgent),
    };
  }

  if (userAgent.indexOf('LINUX') !== -1) {
    return {
      os: UserOperateSystem.LINUX,
      arch: getArch(userAgent),
    };
  }

  return {
    os: UserOperateSystem.UNKNOWN,
    arch: undefined,
  };
}

export { getOs, UserOperateSystem };
