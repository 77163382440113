
import { defineComponent } from 'vue';
import Icon from '@/components/Atoms/Icon.vue';
import Input from '@/components/Atoms/Input.vue';
import { mapState } from 'pinia';
import { useAR } from '@/store/Pinia/useAR.store';
import mobileAndTabletCheck from '@/utils/browserDeviceCheck';
import config from '../../../config';
import FAQSidebarBody from './FAQSidebarBody.vue';

const DEFAULT_MENU = {
  firstMenu: true,
  certificadeMenu: false,
  revokeMenu: false,
  helpMenu: false,
  installMenu: false,
};

export default defineComponent({
  name: 'HeaderComponent',
  components: {
    Icon,
    Input,
    FAQSidebarBody,
  },
  data: () => ({
    menuShow: {
      firstMenu: true,
      certificadeMenu: false,
      revokeMenu: false,
      helpMenu: false,
      installMenu: false,
    },
    form: {
      subdomain: '',
      foneAR: '',
      siteAR: '',
      logo: '',
      nomeAr: '',
    },
    partners: [''],
  }),
  computed: {
    ...mapState(useAR, ['arData', 'getSiteArFormatted']),
    routeHome() {
      return this.subdomain ? this.subdomain : '';
    },
    subdomain() {
      return this.arData.subdomain === 'acsoluti' ? 'arsoluti' : this.arData.subdomain;
    },
    isPartner() {
      return this.partners.includes(this.arData.subdomain || '');
    },
  },
  methods: {
    openCertificadeMenu() {
      this.menuShow.firstMenu = false;
      this.menuShow.certificadeMenu = true;
      this.menuShow.revokeMenu = false;
      this.menuShow.helpMenu = false;
    },
    openRevokeMenu() {
      this.menuShow.firstMenu = false;
      this.menuShow.certificadeMenu = false;
      this.menuShow.revokeMenu = true;
      this.menuShow.helpMenu = false;
    },
    openHelpMenu() {
      this.menuShow.firstMenu = false;
      this.menuShow.certificadeMenu = false;
      this.menuShow.revokeMenu = false;
      this.menuShow.helpMenu = true;
    },
    openInstallMenu() {
      this.menuShow.firstMenu = false;
      this.menuShow.certificadeMenu = false;
      this.menuShow.revokeMenu = false;
      this.menuShow.helpMenu = false;
      this.menuShow.installMenu = true;
    },
    navigateTo(path: string) {
      this.$router.push({
        path,
      });
      const btn = document.getElementById('btnClose');
      if (btn) {
        btn.click();
      }
    },
    closeMenu() {
      this.menuShow = {
        ...DEFAULT_MENU,
      };
    },
    navigateToHome() {
      if (!mobileAndTabletCheck()) {
        this.$router.push({ name: 'home' });
      }
    },
  },
  beforeMount() {
    setTimeout(() => {
      this.form = {
        ...this.arData,
        subdomain: this.arData.subdomain || '',
      };
    }, 1000);
  },
  mounted() {
    this.partners = JSON.parse(JSON.stringify(config.app.partnerNames));
  },
  watch: {
    arData: {
      handler() {
        this.form = {
          ...this.arData,
          subdomain: this.arData.subdomain || '',
        };
      },
      deep: true,
    },
  },
});
