/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable comma-dangle */
import { server } from '@/infra/server';

type ParamsSent = {
  senha: string;
  senhaConfirm: string;
  municipio: string;
  telefone: string;
  email: string;
  cnpj: string;
  razaoSocial: string;
};

export type ResponseSolicitation = {
  username: string;
  name: string;
  tipoProduto: string;
  codigoSolicitacao: string;
  codigioSolicitacao: string;
  computador: string;
  urlBase: string;
  urlEmission: string;
  ip: string;
  isQsa: boolean;
  message?: string;
};

export type ParamsUpdateSolicitation = {
  sessionData: string;
  sessionId: string;
  formData: ParamsSent;
};

export type ParamsCreateSolicitation = {
  token: {
    sessionData: string;
    sessionId: string;
  };
  formData: ParamsSent;
};

export type ParamsGetSolicitationData = {
  solicitacao: string;
  arsubdomain: string;
};

export type ResponseSolicitationData = {
  isQsa: boolean;
  titular: {
    nome: string;
    email: string;
    cpf: string;
  };
  situacao: {
    id: string | number;
    descricao: string;
  };
  produto: {
    descricao: string;
    ext_produto: string;
    ext_referencia: string;
    ext_sequencia: string;
    tipo: string;
    isSSL: boolean;
  };
};

const emissionController = {
  send: async (params: any) => {
    const { data: response } = await server.post<ResponseSolicitation>('emit', params);
    return response;
  },

  createSolicitationSpro: async (params: ParamsCreateSolicitation) => {
    const { data: response } = await server.post<ResponseSolicitation>(
      'solicitation/create-solicitation',
      params,
    );
    return response;
  },

  updateSolicitation: async (params: ParamsUpdateSolicitation) => {
    const { data: response } = await server.post<ResponseSolicitation>(
      'solicitation/create',
      params,
    );
    return response;
  },
  getSolicitationData: async (params: ParamsGetSolicitationData) => {
    const { data: response } = await server.post<ResponseSolicitationData>(
      '/get-solicitation-data',
      params,
    );

    return response;
  },
};

export default emissionController;
