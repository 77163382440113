import { AbstractHandler } from './AbstractHandler';
import { ChainTarget } from '../../domain/ChainOfResponsibility/Handler';
import useTryAgainModal from '../../composables/useTryAgainModal';
import useError400TriesCount from '../../composables/useError400TriesCount';
import { useModalControl } from '../../store/pinia/useModalControl.store';
import { wait } from '@/utils/helpers';

export class ReturnError extends AbstractHandler {
  public async handle(target: ChainTarget): Promise<any> {
    const waitingTimeMinutesInMs = 60000;
    const response = target?.response;
    const error400TriesCount = useError400TriesCount();
    const { triesCount, incrementTriesCount, resetTriesCount } = error400TriesCount;

    let code = response?.data?.code;

    if (code === undefined) {
      code = response?.code;
    }

    const codesToHandle = [99, 0];

    if (!codesToHandle.includes(code)) {
      resetTriesCount();
      return super.handle(target);
    }

    await wait(waitingTimeMinutesInMs);

    incrementTriesCount();

    if (triesCount.value < 3) {
      const handle = AbstractHandler.firstChainLink?.handle({
        makeRequest: target?.makeRequest,
      });

      return handle;
    }

    await ReturnError.showTryAgainModal(() => {
      resetTriesCount();
      const handle = AbstractHandler.firstChainLink?.handle({
        makeRequest: target?.makeRequest,
      });

      return handle;
    });

    return undefined;
  }

  public static async showTryAgainModal(callback: () => any): Promise<void> {
    const tryAgainModal = useTryAgainModal();
    const modalControlStore = useModalControl();

    tryAgainModal.setTryAgainModalAction(callback);

    await modalControlStore.show({
      modalId: 'tryAgainModal',
      show: tryAgainModal.showTryAgainModal,
      hide: tryAgainModal.hideTryAgainModal,
    });
  }
}

export default ReturnError;
