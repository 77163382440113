import { RemovableRef, useStorage } from '@vueuse/core';
import { defineStore } from 'pinia';
import { nextTick } from 'vue';

export type PayloadType = {
  isLoading: boolean;
  title?: string,
  message?: string;
  image?: 'clock' | 'zoom' | 'fileUpload';
}

export type LoadingModalType = {
  modalLoading: RemovableRef<PayloadType>;
}

export const useModalLoading = defineStore({
  id: 'modalLoadingStore',
  state: () => ({
    modalLoading: useStorage('modaLoading', {
      isLoading: false,
      title: 'Aguarde',
      message: '',
      image: 'clock',
    }),
  } as LoadingModalType),
  actions: {
    async setIsLoading(payload: PayloadType) {
      this.setLoading(payload.isLoading ?? false);
      await nextTick();
      this.modalLoading = { ...this.modalLoading, ...payload };
    },
    setLoading(isLoading: boolean) {
      this.modalLoading.isLoading = isLoading;
    },
  },
});
