import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1e8b06e9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "text-area text-start" }
const _hoisted_2 = ["for"]
const _hoisted_3 = ["row", "placeholder", "name", "id", "value", "disabled"]
const _hoisted_4 = {
  key: 0,
  class: "invalid-feedback"
}
const _hoisted_5 = { class: "text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", {
      class: "text-area-label mb-2 p-small",
      for: _ctx.name
    }, _toDisplayString(_ctx.label) + " " + _toDisplayString(_ctx.requiredSymbol), 9, _hoisted_2),
    _createElementVNode("textarea", {
      row: _ctx.rows,
      placeholder: _ctx.placeholder,
      class: _normalizeClass(['form-control', _ctx.errorLog ? 'invalid' : '', _ctx.overflowHidden ? 'overflow-hidden' : '', _ctx.resizable ? '' : 'resize-none']),
      name: _ctx.name,
      id: _ctx.name,
      value: _ctx.modelValue,
      disabled: _ctx.isDisabled,
      style: _normalizeStyle(`height:${_ctx.size}px;`),
      onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.value)))
    }, null, 46, _hoisted_3),
    (!!_ctx.errorLog)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.errorLog), 1)
        ]))
      : _createCommentVNode("", true)
  ]))
}