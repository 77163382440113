/* eslint-disable camelcase */
import emissionController from '@/infra/BirdId/controllers/emission.controller';
import { useSSLCertificate } from '@/store/Pinia/useSSLCertificate.store';
import { useSolicitation } from '@/store/Pinia/useSolicitation.store';

export type FinishJourneyAndEmitType = {
  sessionData: string;
  sessionId: string;
  formData: {
    senha: string;
    senhaConfirm: string;
    municipio: string;
    telefone: string;
    email: string;
    business_category: string;
    uf: number | string;
    cnpj: string;
    razaoSocial: string;
    idunidade: string | number;
    cn: string;
    isRFB: boolean;
  };
};

export type ParamsRequestFinishJourney = {
  token: {
    sessionData: string;
    sessionId: string;
  };
  formData: {
    senha: string;
    senhaConfirm: string;
    municipio: string;
    telefone: string;
    email: string;
    uf: number | string;
    cnpj: string;
    razaoSocial: string;
    idunidade: string | number;
    cn: string;
  };
};

export async function finishJourneyAndEmit(params: FinishJourneyAndEmitType) {
  const solicitationStore = useSolicitation();
  const sslStore = useSSLCertificate();

  const response = await emissionController.updateSolicitation(params);

  const model = {
    ...solicitationStore.getSolicitation,
    isQsa: response.isQsa,
    cdSolicitacao: response.codigoSolicitacao || response.codigioSolicitacao,
    userName: response.username,
  };

  sslStore.setUser(response.username);
  solicitationStore.saveDataSolicitation(model);

  return response;
}

export async function finishJourneySpro(params: ParamsRequestFinishJourney) {
  const solicitationStore = useSolicitation();
  const sslStore = useSSLCertificate();

  const response = await emissionController.createSolicitationSpro(params);

  const model = {
    ...solicitationStore.getSolicitation,
    isQsa: response.isQsa,
    cdSolicitacao: response.codigoSolicitacao || response.codigioSolicitacao,
    userName: response.username,
  };

  sslStore.setUser(response.username);
  solicitationStore.saveDataSolicitation(model);

  return response;
}
