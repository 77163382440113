import axios from 'axios';
import config from '../../config';

const server = axios.create({
  baseURL: `${config.app.urlServer}/`,
});

const serverPanel = axios.create({
  baseURL: `${config.app.urlServerPanel}/`,
});

export { server, serverPanel };
