
import { defineComponent } from 'vue';
// import { AxiosError } from 'axios';
import Breadcrumb from '@/components/Molecules/Breadcrumbs.vue';
import Wizzard from '@/components/Molecules/Wizzard.vue';
import Icon from '@/components/Atoms/Icon.vue';
import { useMainStore } from '@/store/Pinia/useMain.store';
import { useSolicitation } from '@/store/Pinia/useSolicitation.store';
import { FormDataType, useDynamicForms } from '@/store/Pinia/useDynamicForms.store';
import { mapActions, mapState } from 'pinia';
import { useModalLoading } from '@/store/Pinia/useModalLoading.store';
import { finishJourneyAndEmit, FinishJourneyAndEmitType } from '@/utils/finishJourney';
import getMunicipioByNameAndUf from '@/utils/getMunicipioByNameAndUF';
import { useToastGlobal } from '@/store/Pinia/useToastGlobal.store';
import DynamicSummary from '@/components/Molecules/DynamicSummary.vue';
import emissionController from '@/infra/BirdId/controllers/emission.controller';
import { useAR } from '@/store/Pinia/useAR.store';
import { ESolicitationStatus } from '@/helpers/ESisar.enum';

export default defineComponent({
  name: 'SummaryInfo',
  components: {
    Wizzard,
    Breadcrumb,
    Icon,
    DynamicSummary,
  },
  data: () => ({
    error: '',
  }),
  computed: {
    ...mapState(useModalLoading, ['modalLoading']),
    ...mapState(useMainStore, ['idunidade']),
    ...mapState(useDynamicForms, ['formData', 'getPassword']),
    ...mapState(useSolicitation, ['getSolicitation', 'getSolicitationToken']),
    ...mapState(useDynamicForms, ['getFormData']),
    ...mapState(useAR, ['arData']),
    hasCei(): boolean {
      return this.getFormData.cei !== '';
    },
    hasPis(): boolean {
      return this.getFormData.pis !== '';
    },
  },
  methods: {
    ...mapActions(useModalLoading, ['setIsLoading']),
    ...mapActions(useToastGlobal, ['toggleToastGlobal']),
    async finishJorney() {
      this.setIsLoading({
        isLoading: true,
        title: 'Aguarde um pouquinho! Estamos enviando sua Solicitação',
        image: 'clock',
      });

      try {
        const municipio = getMunicipioByNameAndUf(this.formData.municipio, this.formData.uf);

        if (!municipio) {
          throw new Error('Não foi possível encontrar o município');
        }

        const telefoneFormatted = this.formData.telefone.replace('55', '').replace(/[^0-9]/g, '');

        const formattedFormData = { ...this.getFormData };

        Object.entries(this.getFormData).forEach(([key, value]) => {
          if (value === '') delete formattedFormData[key as keyof FormDataType];
        });

        const finishJourneyAndEmitParams = {
          sessionData: this.getSolicitationToken.sessionData,
          sessionId: this.getSolicitationToken.sessionId,
          formData: {
            ...formattedFormData,
            senha: this.getPassword,
            senhaConfirm: this.getPassword,
            municipio: municipio.idmunicipio,
            telefone: telefoneFormatted,
            email: this.formData.email.toLowerCase(),
            // ESSE CAMPO está no formattedFormData
            // business_category: this.formData.business_category,
            uf: this.formData.uf,
            cnpj: this.formData.cnpj?.replace(/\D/g, ''),
            razaoSocial: this.formData.razao_social,
            idunidade: this.idunidade,
            isRFB: this.getSolicitation.isRFB,
          },
        } as FinishJourneyAndEmitType;

        const response = await finishJourneyAndEmit(finishJourneyAndEmitParams);

        const solicitationData = await emissionController.getSolicitationData({
          solicitacao: response.codigioSolicitacao,
          arsubdomain: this.arData.subdomain || '',
        });

        if (Number(solicitationData.situacao.id) === ESolicitationStatus.APROVADA) {
          this.$router.push({ name: 'ReservedDocument' });
          return;
        }

        if (response.isQsa && this.getSolicitation.isSSL) {
          this.$router.push({ path: '/domain-validation' });
          return;
        }

        if (!this.getSolicitation.isPj && (this.hasCei || this.hasPis)) {
          this.$router.push({ path: '/upload-documents-pis-cei' });
          return;
        }

        if (!this.getSolicitation.isPj || response.isQsa) {
          const route = this.getSolicitation.isBirdId
            ? '/installation-guide-birdid'
            : '/installation-guide-token';
          this.$router.push({ path: route });
          return;
        }

        this.$router.push({ path: '/upload-documents' });

        return;
      } catch (error) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const err = error as any;
        this.error = err.message;
        let errorMessage = 'Houve um erro ao tentar atualizar os dados da solicitação';
        if (err.response) {
          if (err.response.data.message && err.response?.data.message !== '') {
            errorMessage = err.response?.data.message;
          }
        }

        this.toggleToastGlobal({
          type: 'danger',
          isShow: true,
          message: errorMessage,
        });
      } finally {
        setTimeout(() => {
          this.setIsLoading({ isLoading: false });
        }, 100);
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    const solicitationStore = useSolicitation();
    if (
      // eslint-disable-next-line operator-linebreak
      solicitationStore.getSolicitationToken.sessionData === '' ||
      // eslint-disable-next-line operator-linebreak
      solicitationStore.getSolicitationToken.sessionId === '' ||
      solicitationStore.$state.solicitation.voucher === ''
    ) {
      next('/');
    }
    next();
  },
});
