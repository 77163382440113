import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createBlock(_component_font_awesome_icon, {
    icon: `fa-${_ctx.type} fa-${_ctx.name}`,
    class: _normalizeClass(`icon far fa-${_ctx.name}`),
    style: _normalizeStyle({ color: _ctx.cor }),
    teste: ""
  }, null, 8, ["icon", "class", "style"]))
}