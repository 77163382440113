import ModalOnboardingVideo from '../modals/ModalOnboardingVideo.vue';

import { ref } from 'vue';

const onboardingVideoModal = ref<InstanceType<typeof ModalOnboardingVideo> | null>(null);

export default function useOnboardingVideoModal() {
  function showOnboardingVideoModal() {
    onboardingVideoModal.value?.show();
  }

  function hideOnboardingVideoModal() {
    onboardingVideoModal.value?.hide();
  }

  return {
    onboardingVideoModal,
    showOnboardingVideoModal,
    hideOnboardingVideoModal,
  };
}
