/* eslint-disable import/prefer-default-export */
const ESolicitationStatus = {
  EM_EDICAO: 0,
  ENVIADA: 1,
  RECUSADA: 2,
  // Verificada (2a. aprovação / EJBCA)
  APROVADA: 3,
  EMITIDA: 4,
  REVOGADA: 5,
  CANCELADA: 6,
  // EM ANÁLISE (P/ 1a. aprovação)
  EM_ANALISE_1A: 7,
  // Verificada (1a. aprovação / EJBCA)
  VERIFICADA2: 8,
  // AGUARDANDO (1a. aprovação, aguardando aprovar no EJBCA)
  AGUARDANDO_1A: 9,
  // EM ANÁLISE (P/ 2a. aprovação)
  EM_ANALISE_2A: 10,
  // AGUARDANDO (2a. aprovação, aguardando aprovar no EJBCA)
  AGUARDANDO_2A: 11,
  AGUARDANDO_RECUSA: 12,
  TODAS_SITUACOES: 13,
};

export { ESolicitationStatus };
