import ModalPluginConfirmation from '../modals/ModalPluginConfirmation.vue';

import { ref } from 'vue';

const pluginConfimartionModal = ref<InstanceType<typeof ModalPluginConfirmation> | null>(null);

export default function usePluginConfirmationModal() {
  function showPluginConfirmationModal() {
    pluginConfimartionModal.value?.show();
  }

  function hidePluginConfirmationModal() {
    pluginConfimartionModal.value?.hide();
  }

  return {
    pluginConfimartionModal,
    showPluginConfirmationModal,
    hidePluginConfirmationModal,
  };
}
