import { AbstractHandler } from './AbstractHandler';
import { ChainTarget } from '../../domain/ChainOfResponsibility/Handler';
import useEmissionStepsModal from '../../composables/useEmissionStepsModal';
import useDownloadCertificateModal from '../../composables/useDownloadCertificateModal';
import { useModalControl } from '../../store/pinia/useModalControl.store';
import { usePlugin } from '@/store/Pinia/usePlugin.store';
import { wait } from '@/utils/helpers';

export class ReturnCode30 extends AbstractHandler {
  private static clearUserData = () => null;

  public async handle(target: ChainTarget): Promise<any> {
    const pluginStore = usePlugin();

    ReturnCode30.clearUserData = target?.clearUserData ? target?.clearUserData : () => null;

    if (target?.response?.data?.code !== 30) {
      return super.handle(target);
    }

    pluginStore.setCertificateIssuedAtWasChecked(true);
    pluginStore.setCsrDownloadLink(target?.response?.data?.detail);

    await ReturnCode30.showStepModal();

    await wait(3500);

    await ReturnCode30.showSuccessModal();

    await wait(2500);

    return super.handle(target);
  }

  public static async showStepModal(): Promise<void> {
    const emissionStep = useEmissionStepsModal();

    const modalControlStore = useModalControl();

    emissionStep.setEmissionStep(3);

    await modalControlStore.show({
      modalId: 'emissionStepsModal',
      show: emissionStep.showEmissionStepsModal,
      hide: emissionStep.hideEmissionStepsModal,
    });
  }

  public static async showSuccessModal(): Promise<void> {
    const modalControlStore = useModalControl();
    const {
      showDownloadCertificateModal,
      hideDownloadCertificateModal,
    } = useDownloadCertificateModal();

    await modalControlStore.show({
      modalId: 'downloadCertificateModal',
      show: showDownloadCertificateModal,
      hide: hideDownloadCertificateModal,
    });

    ReturnCode30.clearUserData();
  }
}

export default ReturnCode30;
