// eslint-disable-next-line import/no-extraneous-dependencies
import {
  faBars,
  faTag,
  faXmark,
  faAngleRight,
  faAngleLeft,
  faGlobe,
  faUser,
  faAngleDown,
  faAngleUp,
  faIdCard,
  faArrowRight,
  faPhone,
  faShield,
  faHouse,
  faCheck,
  faBell,
  faCircleCheck,
  faCircleQuestion,
  faCalendar,
  faMapMarkedAlt,
  faEnvelope,
  faLocationDot,
  faCreditCardBlank,
  faCamera,
  faSunBright,
  faUsersSlash,
  faFaceSmile,
  faFaceViewfinder,
  faFaceMehBlank,
  faCircle,
  faBuilding,
  faFingerprint,
  faArrowUpRightFromSquare,
  faDatabase,
  faInputNumeric,
  faChevronRight,
  faChevronLeft,
  faKey,
  faChevronDown,
  faAddressCard,
  faBan,
  faX,
  faUpRightFromSquare,
  faDownload,
  faThumbsUp,
  faShareNodes,
  faLockKeyhole,
  faEye,
  faEyeSlash,
  faCircleExclamation,
  faPenToSquare,
  faLock,
  faFileLines,
  faRightFromBracket,
  faMagnifyingGlass,
  faCopy,
  faLightbulb,
  faCircleInfo,
  faDisplayArrowDown,
  faPrint,
  faDesktop,
  faQuestion,
  faLightbulbOn,
  faIdCardAlt,
} from '@fortawesome/pro-regular-svg-icons';

import { faYoutube } from '@fortawesome/free-brands-svg-icons';

export default [
  faBars,
  faTag,
  faXmark,
  faAngleRight,
  faAngleLeft,
  faGlobe,
  faUser,
  faAngleDown,
  faAngleUp,
  faIdCard,
  faArrowRight,
  faPhone,
  faShield,
  faHouse,
  faCheck,
  faBell,
  faCircleCheck,
  faCircleQuestion,
  faCalendar,
  faMapMarkedAlt,
  faEnvelope,
  faLocationDot,
  faCreditCardBlank,
  faCamera,
  faSunBright,
  faUsersSlash,
  faFaceSmile,
  faFaceViewfinder,
  faFaceMehBlank,
  faCircle,
  faBuilding,
  faFingerprint,
  faArrowUpRightFromSquare,
  faDatabase,
  faInputNumeric,
  faChevronRight,
  faChevronLeft,
  faChevronDown,
  faXmark,
  faKey,
  faAddressCard,
  faBan,
  faX,
  faUpRightFromSquare,
  faDownload,
  faThumbsUp,
  faShareNodes,
  faLockKeyhole,
  faEye,
  faEyeSlash,
  faCircleExclamation,
  faPenToSquare,
  faLock,
  faFileLines,
  faRightFromBracket,
  faMagnifyingGlass,
  faCopy,
  faLightbulb,
  faCircleInfo,
  faDisplayArrowDown,
  faPrint,
  faDesktop,
  faYoutube,
  faQuestion,
  faLightbulbOn,
  faIdCardAlt,
];
