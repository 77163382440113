/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable comma-dangle */
/* eslint-disable camelcase */
import { applyCnpjMask } from '@/utils/formatDocument';
import { RemovableRef, useStorage } from '@vueuse/core';
import CryptoJS from 'crypto-js';
import { defineStore } from 'pinia';

export type ObjMunicipio = {
  idmunicipio: string;
  uf: string;
  nome: string;
};

export type FormDataType = {
  // PF Fields
  nome: string;
  cpf: string;
  telefone: string;
  email: string;
  uf: string;
  municipio: string;
  cei?: string;
  pis?: string;
  // PJ Fields
  cnpj?: string;
  razao_social?: string;
  // SSL Fields
  business_category?: string;
  cn?: string;
  // Openbaking
  software_statement_id?: string;
  organisation_id?: string;
  // SBP
  sisbacen_environment?: string;
  sisbacen_code?: string;
  sequential_number?: string;
  ispb_code?: string;
  sisbacen_name?: string;
  // ECO
  eco_environment?: string;
  eco_type?: string;
  eco_code?: string;
  // COMPE
  compe_environment?: string;
  compe_code?: string;
  compe_control_code?: string;
  // Other fields
  senha: string;
  senhaConfirm: string;
  unidadeId?: string;
};

export type DecryptedData = {
  municipio: string;
  uf: string;
  telefone: string;
  email: string;
  razao_social: string;
  cnpj: string;
  name: string;
  cpf: string;
};

export type FieldInfoType = {
  id: string;
  icon: string;
  label: string;
};

export type State = {
  formData: RemovableRef<FormDataType>;
  formInfo: RemovableRef<FieldInfoType[]>;
  municipios: RemovableRef<ObjMunicipio[]>;
};

export const STATE_DEFAULT = {
  nome: '',
  cpf: '',
  telefone: '',
  email: '',
  uf: '',
  municipio: '',
  cnpj: '',
  razao_social: '',
  business_category: '',
  cn: '',
  software_statement_id: '',
  organisation_id: '',
  sisbacen_environment: '',
  sisbacen_code: '',
  sequential_number: '',
  compe_environment: '',
  compe_code: '',
  compe_control_code: '',
  ispb_code: '',
  sisbacen_name: '',
  eco_environment: '',
  eco_type: '',
  eco_code: '',
  unidadeId: '',
  senha: '',
  senhaConfirm: '',
  cei: '',
  pis: '',
};

export const useDynamicForms = defineStore({
  id: 'dynamicFormStore',
  state: () =>
    ({
      formData: useStorage('dynamicFormStore', {
        nome: '',
        cpf: '',
        telefone: '',
        email: '',
        uf: '',
        municipio: '',
        cnpj: '',
        razao_social: '',
        business_category: '',
        cn: '',
        software_statement_id: '',
        organisation_id: '',
        sisbacen_environment: '',
        sisbacen_code: '',
        sequential_number: '',
        compe_environment: '',
        compe_code: '',
        compe_control_code: '',
        ispb_code: '',
        sisbacen_name: '',
        eco_environment: '',
        eco_type: '',
        eco_code: '',
        unidadeId: '',
        senha: '',
        senhaConfirm: '',
        cei: '',
        pis: '',
      }),
      formInfo: useStorage('formInfo', [] as FieldInfoType[]),
      municipios: useStorage('municipios', []),
    } as State),
  //  region [GETTERS]
  getters: {
    getFormData(): FormDataType {
      return this.formData;
    },
    getMunicipiosByState(): ObjMunicipio[] {
      const mun = this.municipios.filter((x: ObjMunicipio) => x.uf === this.formData.uf);
      return mun;
    },
    getPassword(): string {
      return CryptoJS.AES.decrypt(
        this.formData.senha,
        'e3b0c44298fc1c149afbf4c8996fb92427ae41e4649b934ca495991b7852b855',
      ).toString(CryptoJS.enc.Utf8);
    },
    getFormDataDefault(): FormDataType {
      return STATE_DEFAULT;
    },
    getMunicipios(): ObjMunicipio[] {
      return this.municipios;
    },
  },
  // end_region [ACTIONS]
  actions: {
    setCPF(cpf: string) {
      this.formData.cpf = cpf;
    },
    setUf(payload: string) {
      this.formData.uf = payload;
    },
    setFormData(payload: FormDataType) {
      this.formData = {
        ...this.formData,
        ...payload,
      };
    },
    setFormInfo(payload: FieldInfoType[]) {
      this.formInfo = payload;
    },
    setPassword(pass: string) {
      const passEncrypt = CryptoJS.AES.encrypt(
        pass,
        'e3b0c44298fc1c149afbf4c8996fb92427ae41e4649b934ca495991b7852b855',
      ).toString();
      this.formData.senha = passEncrypt;
    },
    setMunicipios(payload: ObjMunicipio[]) {
      this.municipios = payload;
    },
    setMunicipioByCode(payload: string) {
      const municipio = this.municipios.find((x) => x.idmunicipio === payload);
      this.formData.municipio = municipio?.nome || '';
    },
    setCNPJ(payload: string) {
      this.formData.cnpj = applyCnpjMask(payload);
    },
    clearData() {
      this.formData = STATE_DEFAULT;
    },
  },
});
