

import { defineComponent } from 'vue';
import Icon from '../Atoms/Icon.vue';

export default defineComponent({
  name: 'CameraComponent',
  components: {
    Icon,
  },
  props: {
    labelButton: {
      type: String,
    },
    classButton: {
      type: String,
    },
  },
});
