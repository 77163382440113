import { EmissionApiAbstractHandler } from './EmissionApiAbstractHandler';
import { ChainTarget } from '../../../domain/ChainOfResponsibility/Handler';
import { usePlugin } from '@/store/Pinia/usePlugin.store';

export class SetDownloadModalIssuedAt extends EmissionApiAbstractHandler {
  public async handle(target: ChainTarget): Promise<any> {
    EmissionApiAbstractHandler.firstChainLink = this;
    const solicitationStatus = Number(target?.response?.data?.solicitation?.status ?? -1);
    let issuedAt = target?.response?.data?.solicitation?.issued_at ?? '';

    if (issuedAt !== '') {
      const issuedAtDate = new Date(issuedAt);

      issuedAtDate.setHours(issuedAtDate.getHours() - 3);
      issuedAt = issuedAtDate.toLocaleString('pt-BR');
    }

    if (!EmissionApiAbstractHandler.validSisArSolicitationStatus.includes(solicitationStatus)) {
      return super.handle(target);
    }

    SetDownloadModalIssuedAt.setIssuedAt(issuedAt);

    return super.handle(target);
  }

  public static setIssuedAt(dateTime: string): void {
    const pluginStore = usePlugin();

    pluginStore.setCertificateIssuedAtWasChecked(true);
    pluginStore.setCertificateIssuedAt(dateTime);
  }
}

export default SetDownloadModalIssuedAt;
