
import {
  defineComponent,
} from 'vue';
import Icon from '@/components/Atoms/Icon.vue';
import {
  mapState,
} from 'pinia';
import {
  useAR,
} from '@/store/Pinia/useAR.store';

export default defineComponent({
  name: 'contactContent',
  computed: {
    ...mapState(useAR, ['arData', 'getSiteArFormatted']),
  },
  components: {
    Icon,
  },
});
