
import { defineComponent } from 'vue';
import { Modal } from 'bootstrap';

export default defineComponent({
  data() {
    return {
      modal: null as null | Modal,
      deafultModal: true,
    };
  },
  mounted() {
    this.modal = new Modal(
      document.getElementById('modal-success-validation-DNS') as HTMLElement,
      // eslint-disable-next-line comma-dangle
      {},
    );
  },
  methods: {
    navidateToGuide() {
      this.hideModal();
      this.$router.push({ path: '/ssl-certificate/installation-guide-ssl' });
    },
    changeModal() {
      this.deafultModal = false;
    },
    showModal() {
      this.modal?.show();
    },
    hideModal() {
      this.modal?.hide();
    },
    setModalDefault() {
      this.deafultModal = true;
    },
  },
});
