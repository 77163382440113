import Container from '@/components/Container.vue';
import FormData from '@/views/BirdId/FormDataView/FormDataView.vue';
import PasswordView from '@/views/BirdId/PasswordView.vue';
import SummaryView from '@/views/BirdId/SummaryView.vue';
import VoucherView from '@/views/BirdId/VoucherView/VoucherView.vue';
import SuccessView from '@/views/BirdId/SuccessView.vue';

import { RouteRecordRaw } from 'vue-router';

const routesBird: Array<RouteRecordRaw> = [
  {
    path: '/birdId',
    component: Container,
    children: [
      {
        path: 'voucher',
        name: 'Voucher',
        component: VoucherView,
      },
      {
        path: 'form',
        name: 'Dados do Certificado Digital',
        component: FormData,
      },
      {
        path: 'pass',
        name: 'Senha do Certificado',
        component: PasswordView,
      },
      {
        path: 'check',
        name: 'checkinfo',
        component: SummaryView,
      },
    ],
  },
  {
    path: '/success',
    name: 'success',
    component: SuccessView,
  },
];

export default routesBird;
