import { ChainTarget } from '../../../domain/ChainOfResponsibility/Handler';
import chainList from './chainList';

export * from './EmissionApiAbstractHandler';

export class ValidateCertificateRequest {
  public readonly dispatch: () => any | Promise<any> = () => null;

  constructor(target: ChainTarget) {
    let index = 0;

    while (index < chainList.length - 1) {
      chainList[index].setNext(chainList[index + 1]);
      index += 1;
    }

    if (chainList.length > 0) {
      this.dispatch = () => chainList[0]?.handle(target);
    }
  }
}

export default ValidateCertificateRequest;
