
import { defineComponent } from 'vue';
import Input from '@/components/Atoms/Input.vue';
import Contact from '@/components/Molecules/Contact.vue';
import Icon from '@/components/Atoms/Icon.vue';
import Breadcrumbs from '@/components/Molecules/Breadcrumbs.vue';
import { mapState } from 'pinia';
import { useAR } from '@/store/Pinia/useAR.store';

export default defineComponent({
  name: 'SuccessView',
  data: () => ({
    classFly: false,
    subdomain: '',
  }),
  components: {
    Input,
    Contact,
    Icon,
    Breadcrumbs,
  },
  computed: {
    ...mapState(useAR, ['arData']),
    routeHome() { return this.arData.subdomain || ''; },
  },
  mounted() {
    const domain = localStorage.getItem('arData');
    if (domain) {
      this.subdomain = JSON.parse(domain).subdomain;
    }
    setTimeout(() => {
      this.classFly = true;
    }, 100);
  },
});
