
import { defineComponent } from 'vue';
import Contact from '@/components/Molecules/Contact.vue';
import Icon from '@/components/Atoms/Icon.vue';
import Input from '@/components/Atoms/Input.vue';
import Breadcrumb from '@/components/Molecules/Breadcrumbs.vue';
import { Tooltip } from 'bootstrap';
import { StateSolicitation, useSolicitation } from '@/store/Pinia/useSolicitation.store';
import { mapActions, mapState } from 'pinia';
import { useAR } from '@/store/Pinia/useAR.store';
import ModalNotPropagated from './dns/ModalNotPropagated.vue';
import { useToastGlobal } from '@/store/Pinia/useToastGlobal.store';
import { useModalLoading } from '@/store/Pinia/useModalLoading.store';
import { useDynamicForms } from '@/store/Pinia/useDynamicForms.store';
import { ESolicitationStatus } from '@/helpers/ESisar.enum';
import emissionController from '@/infra/BirdId/controllers/emission.controller';
import { domainValidationController } from '@/infra/DomainValidation/domainValidation.controller';
import dnsValidationController from '@/infra/DomainValidation/dns.controller';
import httpValidationController from '@/infra/DomainValidation/http.controller';
import { domainDnsValidationDataType, domainHttpValidationDataType, useDomainValidation } from '@/store/Pinia/useDomainValidation.store';

export default defineComponent({
  components: {
    Contact,
    Breadcrumb,
    Icon,
    Input,
    ModalNotPropagated,
  },
  data: () => ({
    model: {
      user: '',
      password: '',
    },
    form: {
      typeValidation: 'http',
      disabledUserName: true,
    },
    tooltipPass: null as Tooltip | null,
    error: '',
  }),
  computed: {
    ...mapState(useSolicitation, ['getSolicitation']),
    ...mapState(useAR, ['getSubdomain']),
    ...mapState(useDomainValidation, ['getType']),
    cdSolicitacao() {
      return this.model.user.replace(' ', ':').split(':')[0];
    },
    isValid() {
      return this.model.user && this.model.password;
    },
    controllerByType() {
      return this.form.typeValidation === 'dns'
        ? dnsValidationController
        : httpValidationController;
    },
    validationText() {
      return this.form.typeValidation === 'dns' ? 'Registro CNAME no DNS' : 'Requisição HTTP';
    },
  },
  methods: {
    ...mapActions(useToastGlobal, ['toggleToastGlobal']),
    ...mapActions(useModalLoading, ['setIsLoading']),
    ...mapActions(useSolicitation, ['setCodigoSolicitacao', 'setUserName', 'saveDataSolicitation']),
    ...mapActions(useDynamicForms, ['setPassword']),
    ...mapActions(useDomainValidation, ['setDomainHttpValidationData', 'setDomainDnsValidationData']),
    async handleLogin() {
      try {
        this.setIsLoading({
          isLoading: true,
          title: 'Aguarde enquanto estamos buscando sua solicitação.',
          message: '',
          image: 'clock',
        });

        const { situacao, isQsa } = await emissionController.getSolicitationData({
          solicitacao: this.cdSolicitacao,
          arsubdomain: this.getSubdomain,
        });

        if (situacao.id === ESolicitationStatus.EMITIDA.toString()) {
          this.error = 'Sua solicitação já se encontra com status de emitida!';
          return;
        }

        const { domainsValidation } = await domainValidationController.getDomainsValidation({
          codigo: this.cdSolicitacao,
          senha: this.model.password,
        });

        const response = await this.controllerByType.choseMethod({
          codigo: this.cdSolicitacao,
          senha: this.model.password,
          domain: domainsValidation[0].domain,
        });

        this.saveDataSolicitation({
          ...this.getSolicitation,
          isQsa,
        } as StateSolicitation);

        if (this.form.typeValidation === 'dns') {
          this.setData();
          const data = response.choosedValidationData[0] as domainDnsValidationDataType;
          this.setDomainDnsValidationData(data);
          this.$router.push({ path: 'dns-instructions' });
        }

        if (this.form.typeValidation === 'http') {
          this.setData();
          const data = response.choosedValidationData[0] as domainHttpValidationDataType;
          this.setDomainHttpValidationData(data);
          this.$router.push({ path: 'http-instructions' });
        }
      } catch (error) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const err = error as any;

        if (err.response && err.response.data.message) {
          if (err.response.data.message === 'matheuscfrancisco.dev.br already verified') {
            this.error = 'Seu domínio já está validado.';
          }

          if (
            (
              // eslint-disable-next-line operator-linebreak
              err.response?.data.message === 'Código e/ou senha incorreto(s).') ||
            err.response?.data.message === 'Solicitação não encontrada.'
          ) {
            this.error = err.response?.data.message;
            return;
          }
        }
      } finally {
        this.setIsLoading({ isLoading: false });
      }
    },
    setData() {
      this.setCodigoSolicitacao(this.cdSolicitacao);
      this.setUserName(this.model.user);
      this.setPassword(this.model.password);
    },
  },
  mounted() {
    this.tooltipPass = new Tooltip('#password-info-tooltip', {
      title:
        'A senha de emissão é a senha que você digitou enquanto preenchia seu cadastro no momento da emissão do seu Certificado. ',
      placement: 'top',
    });

    if (this.getSolicitation.userName === '') {
      this.form.disabledUserName = false;
    }

    this.model.user = this.getSolicitation.userName;
    this.form.typeValidation = this.getType;
  },
});
