
import {
  ref, reactive, onMounted, watch, onBeforeMount,
} from 'vue';

import Breadcrumb from '@/components/Molecules/Breadcrumbs.vue';
import Contact from '@/components/Molecules/Contact.vue';
import Input from '@/components/Atoms/Input.vue';
import Icon from '@/components/Atoms/Icon.vue';
import CardContainer from './A1/components/Atoms/CardContainer.vue';
import { useRoute } from 'vue-router';
import { checkIfPluginIsInstalled, createPluginConnection, makeRequestToPlugin } from './A1/services/pluginHttps';
import {
  ValidateCertificateRequest,
  EmissionApiAbstractHandler,
} from './A1/services/sisAr/ValidateCertificateRequest';
import { getOs, UserOperateSystem } from '../utils/getOs';
import { useModalControl } from './A1/store/pinia/useModalControl.store';
import useSawNewsStore from './A1/store/pinia/useSawNews.store';
import { ValidateReturnPlugin } from './A1/services/ValidateReturnPlugin';
import { useDebounceFn } from '@vueuse/core';

import ModalMobileWarning from './A1/modals/ModalMobileWarning.vue';
import ModalRequestInProgress from './A1/modals/ModalRequestInProgress.vue';
import ModalEmissionSteps from './A1/modals/ModalEmissionSteps.vue';
import ModalDownloadCertificate from './A1/modals/ModalDownloadCertificate.vue';
import ModalPluginDownload from './A1/modals/ModalPluginDownload.vue';
import ModalPluginCertificatePassword from './A1/modals/ModalPluginCertificatePassword.vue';
import ModalDifferentComputerError from './A1/modals/ModalDifferentComputerError.vue';
import ModalPluginConfirmation from './A1/modals/ModalPluginConfirmation.vue';
import ModalWrongCredentialsSisAr from './A1/modals/ModalWrongCredentialsSisAr.vue';
import ModalTryAgain from './A1/modals/ModalTryAgain.vue';
import ModalJava from './A1/modals/ModalJava.vue';
import ModalPluginDownloadConfirmation from './A1/modals/ModalPluginDownloadConfirmation.vue';
import ModalPluginExecutionConfirmation from './A1/modals/ModalPluginExecutionConfirmation.vue';
import ModalPluginNotExecuting from './A1/modals/ModalPluginNotExecuting.vue';
import ModalSetNewPassword from './A1/modals/ModalSetNewPassword.vue';
import ModaolOnboardingVideo from './A1/modals/ModalOnboardingVideo.vue';

import useEmissionStepsModal from './A1/composables/useEmissionStepsModal';
import usePluginDownloadModal from './A1/composables/usePluginDownloadModal';
import usePluginCertificatePasswordModal from './A1/composables/usePluginCertificatePasswordModal';
import useDownloadCertificateModal from './A1/composables/useDownloadCertificateModal';
import useDifferentComputerErrorModal from './A1/composables/useDifferentComputerErrorModal';
import useModalWrongCredentials from './A1/composables/useModalWrongCredentials';
import useRequestInProgressModal from './A1/composables/useRequestInProgressModal';
import useTryAgainModal from './A1/composables/useTryAgainModal';
import usePluginConfirmationModal from './A1/composables/usePluginConfirmationModal';
import usePlguinDownloadConfirmationModal from './A1/composables/usePluginDownloadConfirmationModal';
import usePluginExecutionConfirmation from './A1/composables/usePluginExecutionConfirmationModal';
import usePluginNotExecutingModal from './A1/composables/usePluginNotExecutingModal';
import useSetNewPasswordModal from './A1/composables/useSetNewPassword';
import useOnboardingVideoModal from './A1/composables/useOnboardingVideoModal';
import { usePlugin } from '@/store/Pinia/usePlugin.store';

export default {
  components: {
    Breadcrumb,
    Contact,
    CardContainer,
    'input-component': Input,
    'icon-component': Icon,
    ModalMobileWarning,
    ModalEmissionSteps,
    ModalPluginDownload,
    ModalPluginCertificatePassword,
    ModalDownloadCertificate,
    ModalWrongCredentialsSisAr,
    ModalDifferentComputerError,
    ModalTryAgain,
    ModalRequestInProgress,
    ModalPluginConfirmation,
    ModalJava,
    ModalPluginDownloadConfirmation,
    ModalPluginExecutionConfirmation,
    ModalPluginNotExecuting,
    ModalSetNewPassword,
    ModaolOnboardingVideo,
  },
  setup() {
    const pathList = {
      'Instalação em computador': 'issue/plugin',
    };

    const isAbleToContinue = ref(false);
    const token = ref('');
    const userData = reactive({
      username: '',
      baseUrl: '',
      password: '',
    });
    const userNameIsBeingEdited = ref(false);
    const passwordIsBeingEdited = ref(false);
    const passwordCrt = ref('');

    const validateCertificateRequest = ref<ValidateCertificateRequest>();
    const userError = ref('');
    const passError = ref('');

    watch(() => {
      if (userData.username.trim().length === 0) {
        return false;
      }

      if (userData.password.trim().length === 0) {
        return false;
      }

      if (userError.value.trim().length > 0) {
        return false;
      }

      if (passError.value.trim().length > 0) {
        return false;
      }

      return true;
    }, (isValid) => {
      isAbleToContinue.value = isValid;
    }, {
      immediate: true,
      deep: true,
    });

    watch(
      userData,
      async (newUserData, oldUserData) => {
        const newDataUsernameLeft = (newUserData.username as string).replaceAll('"', '').trim();
        const oldDataUsernameLeft = (oldUserData?.username as string).replaceAll('"', '').trim();

        if (newDataUsernameLeft !== oldDataUsernameLeft) {
          const arLink = await EmissionApiAbstractHandler.getArLink(
            userData.username.split(' ').shift() || '',
          );
          userData.baseUrl = !arLink.length ? userData.baseUrl : arLink;
        }
      },
      { immediate: true, deep: true },
    );

    function validatePassword(newPassword: string) {
      passError.value = '';

      if (newPassword.trim().length === 0) {
        return;
      }

      if (passwordIsBeingEdited.value) {
        return;
      }

      if (newPassword.trim().length < 8 || newPassword.trim().length > 20) {
        passError.value = 'Senha inválida';
      }
    }

    watch(
      () => userData.password,
      (newPassword) => { validatePassword(newPassword); },
      { immediate: true },
    );

    watch(
      () => passwordIsBeingEdited.value,
      (passwordInputState) => {
        if (!passwordInputState) {
          validatePassword(userData.password);
        }
      },
      { immediate: true },
    );

    function validateUserName(newUserName: string) {
      userError.value = '';

      if (newUserName.trim().length === 0) {
        return;
      }

      const splited = newUserName.trim().split(' ');

      if (userNameIsBeingEdited.value) {
        return;
      }

      if (splited.length !== 2) {
        userError.value = 'Usuário inválido';
        return;
      }

      const usernameLeftPart = splited[0];
      const usernameRightPart = splited[1];

      if (usernameLeftPart.length < 16 || usernameLeftPart.length > 20 || usernameRightPart.length !== 14) {
        userError.value = 'Usuário inválido';
      }
    }

    watch(
      () => userData.username,
      (newUserName) => { validateUserName(newUserName); },
      { immediate: true },
    );

    watch(
      () => userNameIsBeingEdited.value,
      (userNameInputState) => {
        if (!userNameInputState) {
          validateUserName(userData.username);
        }
      },
      { immediate: true },
    );

    const route = useRoute();
    const { solicitation, ar_url } = route.query;

    if (solicitation) {
      userData.username = (solicitation as string).replaceAll('"', '').trim();
    }

    if (ar_url) {
      userData.baseUrl = (ar_url as string).replaceAll('"', '').trim();
    }

    const { emissionStepsModal } = useEmissionStepsModal();
    const { downloadCertificateModal } = useDownloadCertificateModal();
    const { pluginDownloadModal } = usePluginDownloadModal();
    const { pluginCertificatePasswordModal } = usePluginCertificatePasswordModal();
    const { differentComputerErrorModal } = useDifferentComputerErrorModal();
    const { requestInProgressModal } = useRequestInProgressModal();
    const { wrongCredentialsModal } = useModalWrongCredentials();
    const { tryAgainModal } = useTryAgainModal();
    const { pluginConfimartionModal } = usePluginConfirmationModal();
    const { pluginDownloadConfirmationModal } = usePlguinDownloadConfirmationModal();
    const { pluginExecutionConfirmation } = usePluginExecutionConfirmation();
    const { pluginNotExecutingModal } = usePluginNotExecutingModal();
    const { setNewPasswordModal } = useSetNewPasswordModal();
    const { onboardingVideoModal, showOnboardingVideoModal } = useOnboardingVideoModal();

    const modalMobileWarning = ref<InstanceType<typeof ModalMobileWarning> | null>(null);
    const modalJava = ref<InstanceType<typeof ModalJava> | null>(null);

    async function navigateToChosePasswordModal() {
      const modalControlStore = useModalControl();
      const {
        showPluginCertificatePasswordModal,
        hidePluginCertificatePasswordModal,
      } = usePluginCertificatePasswordModal();

      await modalControlStore.show({
        modalId: 'pluginCertificatePasswordModal',
        show: showPluginCertificatePasswordModal,
        hide: hidePluginCertificatePasswordModal,
      });
    }

    async function issueCertificate() {
      const modalControlStore = useModalControl();

      const { setEmissionStep, showEmissionStepsModal, hideEmissionStepsModal } = useEmissionStepsModal();

      setEmissionStep(1);

      await modalControlStore.show({
        modalId: 'emissionStepsModal',
        show: showEmissionStepsModal,
        hide: hideEmissionStepsModal,
      });

      if (userData.username.length !== 0) {
        const arLink = await EmissionApiAbstractHandler.getArLink(
          userData.username.split(' ').shift() || '',
        );
        userData.baseUrl = !arLink.length ? userData.baseUrl : arLink;
      }

      await createPluginConnection().then(() => {
        makeRequestToPlugin('/emission/', 'HEAD', {}, {}).then((response) => {
          token.value = (response?.headers?.get('x-csrf-token') || '') as string;
        });
      });

      if (passwordCrt.value.length === 0 && checkIfPluginIsInstalled()) {
        await navigateToChosePasswordModal();
        return;
      }

      const makeRequest = async () => makeRequestToPlugin(
        '/emission/',
        'POST',
        {
          username: userData.username,
          password: userData.password,
          baseUrl: userData.baseUrl,
          passwordCrt: passwordCrt.value,
        },
        {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token.value}`,
        },
      );

      const validateReturnPlugin = new ValidateReturnPlugin({
        makeRequest,
        clearUserData: () => {
          userData.username = '';
          userData.password = '';
          userData.baseUrl = '';
        },
      });

      await EmissionApiAbstractHandler.getArLink(userData.username.split(' ').shift() || '').then(
        (link) => {
          userData.baseUrl = !link.length ? userData.baseUrl : link;
        },
      );

      validateCertificateRequest.value = new ValidateCertificateRequest({
        payload: {
          raUrl: userData.baseUrl,
          code: userData.username.split(' ').shift() || '',
          password: userData.password,
        },
        callback: validateReturnPlugin.dispatch,
      });

      await validateCertificateRequest.value.dispatch();
    }

    const debouncedIssueCertificate = useDebounceFn(issueCertificate, 300);

    onBeforeMount(() => {
      const pluginStore = usePlugin();
      pluginStore.clear();
      passError.value = '';
      userError.value = '';
    });

    onMounted(async () => {
      passError.value = '';
      userError.value = '';
      isAbleToContinue.value = false;
      passwordCrt.value = '';

      const osInfo = getOs();
      const sawNewsStore = useSawNewsStore();

      if (
        osInfo.os === UserOperateSystem.IOS
        || osInfo.os === UserOperateSystem.ANDROID
        || osInfo.os === UserOperateSystem.UNKNOWN
      ) {
        modalMobileWarning.value?.show();
        return;
      }

      setTimeout(() => {
        if (!sawNewsStore.sawNews) {
          showOnboardingVideoModal();
        }
      }, 2000);
    });

    async function proceed(password: string) {
      if (password.length === 0) {
        passwordCrt.value = userData.password;
        return debouncedIssueCertificate();
      }

      passwordCrt.value = password;
      return debouncedIssueCertificate();
    }

    return {
      pathList,
      modalMobileWarning,
      modalJava,
      tryAgainModal,
      downloadCertificateModal,
      pluginDownloadModal,
      pluginCertificatePasswordModal,
      differentComputerErrorModal,
      emissionStepsModal,
      pluginConfimartionModal,
      pluginDownloadConfirmationModal,
      pluginExecutionConfirmation,
      userData,
      userNameIsBeingEdited,
      passwordIsBeingEdited,
      userError,
      passError,
      isAbleToContinue,
      issueCertificate,
      navigateToChosePasswordModal,
      debouncedIssueCertificate,
      requestInProgressModal,
      wrongCredentialsModal,
      pluginNotExecutingModal,
      setNewPasswordModal,
      onboardingVideoModal,
      proceed,
      showOnboardingVideoModal,
    };
  },
};
