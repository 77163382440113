import { server } from '@/infra/server';

type ResponseType = {
  status: string,
  message: string,
}

type ResponseGetEmailsType = {
  emails: {
    domain: string,
    emails: string[],
  }[],
} & ResponseType;

type RequestGetEmailsType = {
  codigo: string,
  senha: string,
}

type RequestSendEmailType = {
  codigo: string,
  senha: string,
  domain: string,
  email: string,
}

type RequestSendCodeType = {
  codigo: string,
  senha: string,
  domain: string,
  code: string,
}

const emailValidationController = {
  getEmails: async (params: RequestGetEmailsType) => {
    const response = await server.post<ResponseGetEmailsType>('/domain-validation/email/get-emails', { ...params });
    return response.data;
  },
  sendCodeToEmail: async (params: RequestSendEmailType) => {
    const response = await server.post<ResponseType>('/domain-validation/email/send-email', { ...params });
    return response.data;
  },
  validateCode: async (params: RequestSendCodeType) => {
    const response = await server.post<ResponseType>('/domain-validation/email/validate', { ...params });
    return response.data;
  },

};

export default emailValidationController;
