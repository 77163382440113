
import { defineComponent } from 'vue';
import { mapState } from 'pinia';
import { useAR } from '@/store/Pinia/useAR.store';
import Icon from '../Atoms/Icon.vue';

export default defineComponent({
  name: 'breadCrumbs',
  props: {
    pathList: {
      type: Object,
      default: () => undefined,
    },
    className: {
      type: String,
      default: 'py-3',
    },
  },
  data: () => ({
    subdomain: '',
  }),
  mounted() {
    this.subdomain = this.arData.subdomain || '';
  },
  computed: {
    ...mapState(useAR, ['arData']),
    routeHome() {
      return this.subdomain ? this.subdomain : '';
    },
    lastElementOfPathList() {
      const objKey = Object.keys(this.pathList as string[]);
      return objKey[objKey.length - 1];
    },
  },
  components: {
    Icon,
  },
});
