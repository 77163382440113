import ModalRequestInProgress from '../modals/ModalRequestInProgress.vue';

import { ref } from 'vue';

const requestInProgressModal = ref<InstanceType<typeof ModalRequestInProgress> | null>(null);

export default function useRequestInProgressModal() {
  function showRequestInProgressModal() {
    requestInProgressModal.value?.show();
  }

  function hideRequestInProgressModal() {
    requestInProgressModal.value?.hide();
  }

  return {
    showRequestInProgressModal,
    hideRequestInProgressModal,
    requestInProgressModal,
  };
}
