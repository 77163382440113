/* eslint-disable comma-dangle */
/* eslint-disable camelcase */
import { server } from '@/infra/server';
import { DecryptedData, ObjMunicipio } from '@/store/Pinia/useDynamicForms.store';

type LoginParams = {
  username: string;
  otp: string;
};

export type ProductResponseType = {
  idProduto: string;
  nome: string;
};

export type VoucherParam = {
  voucher: string;
  arsubdomain: string;
  unidade?: string;
  token: {
    sessionId: string;
    sessionData: string;
  };
  cnpj?: string;
  produtos?: ProductResponseType[];
  hasRfb?: boolean;
};

export type ResponseLogin = {
  sessionData: string;
  sessionId: string;
  nome: string;
};

type ProductResponse = {
  id: string;
  isSSL: boolean;
  sku: string | number;
  ext_produto: string;
};

export type ResponseLoginVoucher = {
  voucher: string;
  nomeProduto: string;
  produto: ProductResponse;
  isBird: boolean;
  isPj: boolean;
  isRFB: boolean;
  idunidade: string;
  sessionData: string;
  sessionId: string;
  municipios: ObjMunicipio[];
  cpf: string;
  nome: string;
  cei: string;
  pis: string;
  dataDecrypted?: DecryptedData;
};

export type ResponseGetProductsByVoucher = {
  status: string;
  produtos: ProductResponseType[];
  idProdutoIniciado: null | string | number;
  renovacaoJaIniciada?: boolean;
  consultarCnpjRfbOuQsa?: boolean;
};

type CessTokenType = {
  sessionData: string;
  sessionId: string;
};

type ParamsGetProductsByVoucher = {
  voucher: string;
  arsubdomain: string;
  cessToken: CessTokenType;
};

type VoucherSproParams = {
  username: string;
  otp: string;
  arsubdomain: string;
  data: {
    iv: string;
    data: string;
  };
};

const loginController = {
  loginWithToken: async (
    iv: string,
    encryptedToken: string,
    order: string,
    arsubdomain: string,
    cnpj: string,
  ) => {
    const request = {
      access_token: {
        iv,
        encrypted_token: encryptedToken,
      },
      voucher: order,
      arsubdomain,
      cnpj,
    };

    const response = server.post<ResponseLoginVoucher>('solicitation/create-with-token', request);
    return response;
  },
  login: async ({ username, otp }: LoginParams) => {
    const request = {
      username,
      otp,
    };
    const response = server.post<ResponseLogin>('just-auth', request);
    return response;
  },
  validateVoucher: async (voucher: VoucherParam) => {
    const response = server.post<ResponseLoginVoucher>('login', voucher);
    return response;
  },
  validateVoucherSPRO: async (params: VoucherSproParams) => {
    const response = await server.post<ResponseLoginVoucher>('login-with-order-encrypted', {
      ...params,
    });

    return response.data;
  },
  getProductsByVoucher: async ({ voucher, arsubdomain, cessToken }: ParamsGetProductsByVoucher) => {
    const { data: response } = await server.post<{ message: ResponseGetProductsByVoucher }>(
      'get-products-by-voucher',
      {
        voucher,
        arsubdomain,
        cessToken,
      },
    );

    return { ...response.message };
  },
};

export default loginController;
