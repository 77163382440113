
import { defineComponent } from 'vue';
import { Modal } from 'bootstrap';
import Icon from '@/components/Atoms/Icon.vue';

export default defineComponent({
  data() {
    return {
      modal: null as null | Modal,
    };
  },
  components: {
    Icon,
  },
  mounted() {
    this.modal = new Modal(document.getElementById('modal-not-propagated') as HTMLElement, {});
  },
  methods: {
    showModal() {
      this.modal?.show();
    },
    hideModal() {
      this.modal?.hide();
    },
  },
});
