import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7d6e43d4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "form-container pb-md-5" }
const _hoisted_2 = ["disabled"]
const _hoisted_3 = ["for"]
const _hoisted_4 = { class: "form-control-icon" }
const _hoisted_5 = {
  key: 0,
  class: "invalid-feedback"
}
const _hoisted_6 = { class: "text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_Input = _resolveComponent("Input")!

  return (_openBlock(), _createElementBlock("form", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fields, (field) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "form-item",
        key: field.id
      }, [
        (field.type === 'select')
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              disabled: field.id === 'municipio' ? false : field.disable,
              class: "input-wrapper"
            }, [
              _createElementVNode("label", {
                for: field.id,
                class: "input-label p-small",
                style: 'padding-bottom: 0.5rem'
              }, _toDisplayString(field.label) + " * ", 9, _hoisted_3),
              _createElementVNode("div", _hoisted_4, [
                (!!field.icon)
                  ? (_openBlock(), _createBlock(_component_Icon, {
                      key: 0,
                      name: field.icon,
                      class: "icon"
                    }, null, 8, ["name"]))
                  : _createCommentVNode("", true),
                _createVNode(_component_v_select, {
                  id: field.id,
                  label: "nome",
                  style: {"margin":"0"},
                  class: _normalizeClass(['form-select', _ctx.errors[field.name] ? 'invalid' : '']),
                  options: _ctx.getOptions(field),
                  placeholder: field.placeholder,
                  reduce: (el) => el.value || el.nome,
                  modelValue: _ctx.model[field.name],
                  "onUpdate:modelValue": ($event: any) => ((_ctx.model[field.name]) = $event)
                }, {
                  "no-options": _withCtx(() => [
                    _createTextVNode("Nenhuma opção encontrada")
                  ]),
                  _: 2
                }, 1032, ["id", "class", "options", "placeholder", "reduce", "modelValue", "onUpdate:modelValue"])
              ]),
              (!!_ctx.errors[field.name])
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.errors[field.name]), 1)
                  ]))
                : _createCommentVNode("", true)
            ], 8, _hoisted_2))
          : (_openBlock(), _createBlock(_component_Input, {
              key: 1,
              id: field.id,
              name: field.id,
              placeholder: field.placeholder,
              label: field.label,
              icon: field.icon || '',
              type: field.type,
              mask: field.mask === null ? undefined : field.mask,
              "is-disabled": field.disable,
              modelValue: _ctx.model[field.id],
              "onUpdate:modelValue": ($event: any) => ((_ctx.model[field.id]) = $event),
              errorLog: _ctx.errors[field.id],
              minLength: field.min_length?.toString(),
              isRequired: field.required
            }, null, 8, ["id", "name", "placeholder", "label", "icon", "type", "mask", "is-disabled", "modelValue", "onUpdate:modelValue", "errorLog", "minLength", "isRequired"]))
      ]))
    }), 128))
  ]))
}