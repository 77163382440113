export default {
  windowsOSSelected: [
    {
      nome: 'Cartão AWP 5.1.8 - Windows |10| 8.1 |8| 7| 64 bits',
      link: 'https://site.solutinet.com.br/drivers/Cartao/AWP/Windows/AWP_5.1.8_SR1_x64%20(Admin).exe',
      type: 'AWP',
    },
    {
      nome: 'Cartão AWP 5.1.8 - Windows |10| 8.1| 8| 7| 32 bits',
      link: 'https://site.solutinet.com.br/drivers/Cartao/AWP/Windows/AWP_5.1.8_SR1_x32%20(Admin).exe',
      type: 'AWP',
    },
    {
      nome: 'Cartão AWP 5.1.7 - Windows |10| 8.1 |8| 7| 64 bits',
      link: 'https://drive.google.com/file/d/1JipdbIkqkZJGZYPOEy_eRlnAC-nP8jTp/view?usp=sharing',
      type: 'AWP',
    },
    {
      nome: 'Cartão AWP 5.1.7 - Windows |10| 8.1 |8| 7| 32 bits',
      link: 'https://drive.google.com/file/d/1Jg5n9JX075VxoQfqPGPx3GypPBG--wjE/view?usp=sharing',
      type: 'AWP',
    },
    {
      nome: 'Cartão AWP 5.1.1 - Windows |10 | 8.1 | 8 |7| 64 bits',
      link: 'https://drive.google.com/file/d/1Qpf4gx6uzZkcti_fBHzjxklhRr4mkoph/view',
      type: 'AWP',
    },
    {
      nome: 'Cartão AWP 5.1.1 - Windows |10| 8.1| 8 |7| 32 bits',
      link: 'https://site.solutinet.com.br/drivers/Cartao/AWP/Windows/AWP_5.1.1_SR1_x32%20(Admin).msi',
      type: 'AWP',
    },
    {
      nome: 'Cartão Gemalto Versão 10.8 - Windows - |10| 8.1| 8| 7| 64 bits',
      link: 'https://site.solutinet.com.br/drivers/Token/Safenet/Windows/iti-x64-10.8.msi',
      type: 'GEMALTO',
    },
    {
      nome: 'Cartão Gemalto 10.8 -Windows | 10 |8.1| 8 |7| 32 bits',
      link: 'https://site.solutinet.com.br/drivers/Cartao/Gemalto/Windows/iti-x32-10.8.msi',
      type: 'GEMALTO',
    },
    {
      nome: 'Cartão Morpho Versão Admin - Windows | 10 | 8.1| 8| 7| 64 bits',
      link: 'https://site.solutinet.com.br/drivers/Cartao/Morpho/Windows/SafeSign%20IC_30124_X64%20ADMIN.exe',
      type: 'MORPHO',
    },
    {
      nome: 'Cartão Morpho Versão Admin - Windows |10 | 8.1| 8| 7 |32 bits',
      link: 'https://site.solutinet.com.br/drivers/Cartao/Morpho/Windows/SafeSign%20IC_30124_X32%20ADMIN.exe',
      type: 'MORPHO',
    },
  ],
  macOSSelected: [
    {
      nome: 'Cartão AWP Versão 10.13 à 11.2 - macOS - High Sierra | Mojave |Catalina | Big Sur',
      link: 'https://site.solutinet.com.br/drivers/Cartao/AWP/Mac/AWP_5.2.2%20(10.13%20a%2011.2).dmg',
      type: 'AWP',
    },
    {
      nome: 'Cartão AWP Versão 10.12 - macOS - Sierra',
      link: 'https://site.solutinet.com.br/drivers/Cartao/AWP/Mac/AWP_5.2.0%20(10.12).dmg',
      type: 'AWP',
    },
    {
      nome: 'Cartão Morpho Versão 10.13 à 10.15 - macOS - High Sierra | Mojave | Catalina',
      link: 'https://site.solutinet.com.br/drivers/Cartao/Morpho/Mac/SafeSign_3.0.77%20(10.13%20a%2010.15).pkg',
      type: 'MORPHO',
    },
    {
      nome: 'Cartão Morpho Versão 10.6 à 10.12 - macOS - Snow Leopard | Lion | Mountain Lion | Mavericks| Yosemite| El Capitan e Sierra:',
      link: 'https://site.solutinet.com.br/drivers/Cartao/Morpho/Mac/SafeSign_3.0.0.5%20(10.6%20a%2010.12).dmg',
      type: 'MORPHO',
    },
  ],
  linuxOSSelected: [
    {
      nome: 'Cartão AWP 5.2.0 - Linux - Ubuntu 64 Bits',
      link: 'https://site.solutinet.com.br/drivers/Cartao/AWP/Linux/AWP_Debian_Ubuntu_x64_5.2.0_SR2_amd64.deb',
      type: 'AWP',
    },
    {
      nome: 'Cartão AWP 5.2.0 - Linux - Ubuntu 32 Bits',
      link: 'https://site.solutinet.com.br/drivers/Cartao/AWP/Linux/AWP_Debian_Ubuntu_x32_5.2.0_SR2_i386.rpm',
      type: 'AWP',
    },
    {
      nome: 'Cartão Morpho - Linux Debian - Ubuntu',
      link: 'https://site.solutinet.com.br/drivers/Cartao/Morpho/Linux/soluti-safesign-ubuntu.tar.gz',
      type: 'MORPHO',
    },
  ],
};
