
import { defineComponent, PropType } from 'vue';
import Icon from '@/components/Atoms/Icon.vue';

export default defineComponent({
  name: 'InputForm',
  props: {
    label: {
      type: String,
    },
    placeholder: {
      type: String,
      default: '',
    },
    errorLog: {
      type: String,
      default: '',
    },
    isDisabled: {
      type: Boolean,
    },
    icon: {
      type: String,
      default: '',
    },
    iconColor: {
      type: String,
      default: 'primary',
    },
    type: {
      type: String,
      default: 'text',
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    mask: {
      type: Array as PropType<Array<string>>,
    },
    options: {
      type: Array as PropType<Array<string>>,
    },
    modelValue: {
      type: String,
    },
    autocomplete: {
      type: String,
      default: 'on',
    },
    isNumeric: {
      type: Boolean,
    },
    tooltipTitle: {
      type: String,
    },
    maxLength: {
      type: String,
    },
    minLength: {
      type: String,
    },
  },
  components: { Icon },
  data: () => ({
    showPassword: false,
  }),
  computed: {
    requiredSymbol() {
      return this.$props.isRequired && this.$props.label ? '*' : '';
    },
    getColorIcon() {
      if (this.$props.isDisabled) {
        return 'gray-dark';
      }

      if (this.$props.errorLog !== '') {
        return 'danger';
      }

      if (this.$props.iconColor) {
        return `${this.$props.iconColor}`;
      }

      return 'primary';
    },
    name() {
      return this.$props.label?.toLowerCase();
    },
  },
  methods: {
    toggleShowPassword() {
      this.showPassword = !this.showPassword;
    },
    updateValue(value: string) {
      this.$emit('input', value);
    },
  },
});
