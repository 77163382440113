
import { defineComponent } from 'vue';
import Icon from '@/components/Atoms/Icon.vue';
import Contact from '@/components/Molecules/Contact.vue';
import ModalValidationBirdVue from '@/components/templates/ModalValidationBird.vue';
import Loading from '@/components/Atoms/Loading.vue';
import allValidates from '@/utils/validates';
import { useSolicitation } from '@/store/Pinia/useSolicitation.store';
import { useAR } from '@/store/Pinia/useAR.store';
import { useDynamicForms, STATE_DEFAULT } from '@/store/Pinia/useDynamicForms.store';
import { mapActions, mapState } from 'pinia';
import browserDeviceCheck from '@/utils/browserDeviceCheck';
import arController from '@/infra/BirdId/controllers/ar.controller';
import ModalSentDesktopVue from '@/components/templates/ModalSentDesktop.vue';
import loginController, { ResponseLoginVoucher } from '@/infra/BirdId/controllers/login.controller';
import { useMainStore } from '@/store/Pinia/useMain.store';
import saveDataVoucher from '@/utils/saveDataVoucher';
import { useRouteBirdId } from '@/store/Pinia/useRouteBirdId.store';
import { useDomainValidation } from '@/store/Pinia/useDomainValidation.store';
import { useModalLoading } from '@/store/Pinia/useModalLoading.store';
import { useToastGlobal } from '@/store/Pinia/useToastGlobal.store';
import { AxiosError } from 'axios';
import config from '../../config';

type ParamsToLoginVar = {
  iv: string;
  token: string;
  order: string;
  cnpj: string;
  transaction: string;
};

export default defineComponent({
  components: {
    Icon,
    Contact,
    ModalValidationBirdVue,
    ModalSentDesktopVue,
    Loading,
  },
  data: () => ({
    isLoadingHome: false,
    isMobile: false,
    showCookiesAlert: true,
    model: {
      cpf: '',
      otp: '',
      termos: false,
    },
  }),
  methods: {
    ...mapActions(useMainStore, ['setUnidade']),
    ...mapActions(useDynamicForms, ['setCPF', 'setFormData']),
    ...mapActions(useSolicitation, ['setNegociationId']),
    handleNavigate(route: string) {
      this.$router.push(`/${route}`);
    },
    queryToStorage() {
      const oldLogin = { cpf: '', unidade: '', voucher: '' };

      oldLogin.cpf = this.$route.query.username?.toString() || '';
      oldLogin.unidade = this.$route.query.unidade?.toString() || '';
      oldLogin.voucher = this.$route.query.voucher?.toString() || '';

      if (this.$route.query.voucher !== '') {
        localStorage.setItem('voucher', JSON.stringify(oldLogin.voucher));
      }

      if (allValidates.validateCPF(this.$route.query.username?.toString() || '')) {
        this.model.cpf = this.$route.query.username?.toString() || '';
      }

      const preFilled = {
        ...STATE_DEFAULT,
        tel: this.$route.query.telefone?.toString() || '',
        cpf: '',
        email: this.$route.query.email?.toString() || '',
        cnpj: this.$route.query.cnpj?.toString() || '',
        razaoSocial: this.$route.query.razaoSocial?.toString() || '',
        uf: this.$route.query.uf?.toString() || '',
        municipio: this.$route.query.municipio?.toString() || '',
      };

      this.setFormData(preFilled);
      this.setNegociationId(this.$route.query.token?.toString() || '');
      this.setUnidade(this.$route.query.unidade?.toString() || '');
    },
    handleActionButton() {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const btn: any = this.$refs.btnTokenA3;
      if (this.isMobile) {
        btn.setAttribute('data-bs-toggle', 'modal');
        btn.setAttribute('data-bs-target', '#modalSentDesktop');
      } else {
        btn.removeAttribute('click');
      }
    },

    closeAlert() {
      this.showCookiesAlert = false;
      localStorage.setItem('cookies', JSON.stringify(false));
    },
  },
  computed: {
    ...mapState(useMainStore, ['cookies']),
  },
  async beforeRouteEnter(to, from, next) {
    const main = useMainStore();
    const routeBird = useRouteBirdId();
    const arStore = useAR();
    const solicitation = useSolicitation();
    const validationDomain = useDomainValidation();
    const useLoading = useModalLoading();
    const useToast = useToastGlobal();
    const dynamicForms = useDynamicForms();

    const iv = to.query['access_token[iv]'] as string;
    const token = to.query['access_token[encrypted_token]'];
    const { order, cnpj, transaction } = to.query;

    const queryParams = {
      iv,
      token,
      order,
      cnpj,
      transaction,
    } as ParamsToLoginVar;

    const arSubDomain = to.params.arsubdomain || arStore.arData.subdomain;

    const getArData = async (arname: string) => {
      try {
        const { data: dataAr } = await arController.getArData(arname);

        return dataAr;
      } catch (error) {
        localStorage.setItem('ar-subdomain-error', to.fullPath || '');
        return next({ name: 'WithoutRoute' });
      }
    };

    try {
      useLoading.setIsLoading({
        isLoading: true,
        title: 'Carregando...',
        image: 'clock',
      });

      main.clearData();
      routeBird.clearRoutes();
      solicitation.clearData();
      dynamicForms.clearData();

      const dataAr = await getArData(`${arSubDomain}`);
      const modalSave = { ...dataAr, subdomain: arSubDomain };

      arStore.setArData(modalSave);

      if (queryParams.iv && queryParams.token && queryParams.order) {
        const { data } = await loginController.loginWithToken(
          queryParams.iv,
          queryParams.token,
          queryParams.order,
          to.params.arsubdomain as string,
          queryParams.cnpj || '',
        );

        if (queryParams.cnpj) {
          dynamicForms.setCNPJ(queryParams.cnpj);
        }

        solicitation.setIsRFB(data.isRFB);

        const model = {
          ...data,
          voucher: to.query.order || '',
        };

        saveDataVoucher(model as ResponseLoginVoucher);
        routeBird.setRouterDone('voucher');

        return next({ name: 'Dados do Certificado Digital' });
      }

      if (
        // eslint-disable-next-line operator-linebreak
        to.query.route === 'check-domain-validation' &&
        // eslint-disable-next-line operator-linebreak
        to.query.user !== '' &&
        to.query.choosemethod !== ''
      ) {
        validationDomain.setType(to.query.choosemethod as string);
        solicitation.setUserName(`${to.query.user}` || '');
        next({ path: `/domain-validation/${to.query.route}` });
      }

      return next();
    } catch (error) {
      const axiosError = error as AxiosError;
      const errorData = axiosError.response?.data as { message: string };

      if (transaction !== '') {
        window.location.href = `${config.app.vlineUrl}${transaction}?message=${
          errorData.message || axiosError.message
        }`;
        return next();
      }

      useToast.toggleToastGlobal({
        isShow: true,
        message: `Erro de navegação. Motivo: ${errorData.message || axiosError.message}`,
      });
      return next();
    } finally {
      setTimeout(() => {
        useLoading.setIsLoading({ isLoading: false });
      }, 1500);
    }
  },
  mounted() {
    this.queryToStorage();
    this.isMobile = browserDeviceCheck();
    this.showCookiesAlert = this.cookies;
    this.handleActionButton();
  },
});
