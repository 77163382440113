import ModalPluginNotExecutingVue from '../modals/ModalPluginNotExecuting.vue';

import { ref } from 'vue';

const pluginNotExecutingModal = ref<InstanceType<typeof ModalPluginNotExecutingVue> | null>(null);

export default function usePluginNotExecutingModal() {
  function showPluginNotExecutingModal() {
    pluginNotExecutingModal.value?.show();
  }

  function hidePluginNotExecutingModal() {
    pluginNotExecutingModal.value?.hide();
  }

  return {
    pluginNotExecutingModal,
    showPluginNotExecutingModal,
    hidePluginNotExecutingModal,
  };
}
