import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "mb-5 nav nav--wizard d-flex justify-content-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.routes, (route, index) => {
      return (_openBlock(), _createElementBlock("li", {
        key: route.path,
        class: _normalizeClass(['nav-item', _ctx.getColorItem(`${route.path}`)])
      }, [
        _createVNode(_component_router_link, {
          to: `${route.path}`,
          content: index+1,
          "aria-current": "page",
          class: _normalizeClass(['nav-link', _ctx.getColorItem(`${route.path}`)])
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(route.name), 1)
          ]),
          _: 2
        }, 1032, ["to", "content", "class"])
      ], 2))
    }), 128))
  ]))
}