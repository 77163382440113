
import { ComponentOptions, defineComponent } from 'vue';
import DynamicForms from '@/components/Molecules/DynamicForms.vue';
import { mapActions, mapState } from 'pinia';
import { STATE_DEFAULT, useSolicitation } from '@/store/Pinia/useSolicitation.store';
import { FieldType, dynamicFormsController } from '@/infra/sisAr/dynamicForms.controller';
import { useRouteBirdId } from '@/store/Pinia/useRouteBirdId.store';
import { FormDataType, useDynamicForms } from '@/store/Pinia/useDynamicForms.store';
import { useAR } from '@/store/Pinia/useAR.store';
import ModalValidationBirdSpro from '@/components/templates/ModalValidationBirdSpro.vue';
import { ApplyMaskToCPF } from '@/utils/formatDocument';
import { useModalLoading } from '@/store/Pinia/useModalLoading.store';
import { useMainStore } from '@/store/Pinia/useMain.store';
import { useToastGlobal } from '@/store/Pinia/useToastGlobal.store';
import { FinishJourneyAndEmitType, finishJourneyAndEmit } from '@/utils/finishJourney';
import emissionController from '@/infra/BirdId/controllers/emission.controller';
import { ESolicitationStatus } from '@/helpers/ESisar.enum';
import getMunicipioByNameAndUf from '@/utils/getMunicipioByNameAndUF';

/**
 * Função para criar um payload de solicitação utilizando os parâmetros fornecidos.
 * @param {string} d - Dados Base64.
 * @param {string} i - CPF do Representante Legal Base64.
 * @param {string} s - SKU do Produto Base64.
 * @param {string} v - Initialization Vector (IV) Base64.
 * @param {string} data - Dados criptografados
 * @returns {Object} - Payload de solicitação.
 */

type ParamsRoute = {
  arsubdomain: string;
};

type QueryStringParamsRoute = {
  i: string;
  d: string;
  s: string;
  v: string;
  data: any;
};

export default defineComponent({
  name: 'FormDataSolutiPro',
  components: {
    DynamicForms,
    ModalValidationBirdSpro,
  },
  data: () => ({
    form: {
      codVoucher: '',
      fields: [{}] as FieldType[],
      isPj: false,
      isSSL: false,
    },
    model: {
      password: '',
      confirmPassword: '',
    },
    validation: {
      uppercase: false,
      lowercase: false,
      hasNumber: false,
      minLength: false,
    },
    errorPassword: '',
    isValid: false,
    dynamicCount: 0,
    error: '',
  }),
  watch: {
    'model.password': function w(current: string) {
      const lastLetter = current.slice(-1);

      if (/[\\"/'/`/´]/g.test(lastLetter)) {
        this.errorPassword = 'caracter inválido';
      } else {
        this.errorPassword = '';
      }

      const lowerCaseLetters = /[a-z]/g;
      if (current.match(lowerCaseLetters)) {
        this.validation.lowercase = true;
      } else {
        this.validation.lowercase = false;
      }
      const upperCaseLetters = /[A-Z]/g;
      if (current.match(upperCaseLetters)) {
        this.validation.uppercase = true;
      } else {
        this.validation.uppercase = false;
      }
      const numbers = /[0-9]/g;
      if (current.match(numbers)) {
        this.validation.hasNumber = true;
      } else {
        this.validation.hasNumber = false;
      }
      if (current.length >= 8) {
        this.validation.minLength = true;
      } else {
        this.validation.minLength = false;
      }

      if (current.length > 0 && this.model.confirmPassword) {
        this.checkSamePassword();
      }
    },
    'model.confirmPassword': function w(current: string) {
      if (current.length > 0) {
        this.checkSamePassword();
      }
    },
  },
  methods: {
    ...mapActions(useDynamicForms, ['setFormData', 'setFormInfo', 'setPassword']),
    ...mapActions(useRouteBirdId, ['setRouterDone']),
    ...mapActions(useModalLoading, ['setIsLoading']),
    ...mapActions(useToastGlobal, ['toggleToastGlobal']),
    async saveAndNavigate() {
      if (this.isValid) {
        const modelDynamic = this.$refs.dinamycComponent as ComponentOptions;
        this.setFormData(modelDynamic.model as FormDataType);
        this.$router.push('/birdId/pass');
        this.setRouterDone('form');
      }
    },
    handleIsValid(value: boolean) {
      this.isValid = value;
    },
    openModal() {
      const modal = this.$refs.modalValidationBirdSpro as typeof ModalValidationBirdSpro;
      modal.modalOpen();
    },
    checkSamePassword() {
      if (this.model.password !== this.model.confirmPassword) {
        this.errorPassword = 'As senhas não correspondem.';
      } else {
        this.errorPassword = '';
      }
    },
    async finishJorney() {
      this.setIsLoading({
        isLoading: true,
        title: 'Aguarde um pouquinho! Estamos enviando sua Solicitação',
        image: 'clock',
      });

      try {
        const modelDynamic = this.$refs.dinamycComponent as ComponentOptions;
        this.setFormData(modelDynamic.model as FormDataType);

        const municipio = getMunicipioByNameAndUf(this.formData.municipio, this.formData.uf);

        if (!municipio) {
          throw new Error('Não foi possível encontrar o município');
        }

        const telefoneFormatted = this.formData.telefone.replace('55', '').replace(/[^0-9]/g, '');

        const formattedFormData = { ...this.getFormData };

        Object.entries(this.getFormData).forEach(([key, value]) => {
          if (value === '') delete formattedFormData[key as keyof FormDataType];
        });

        const finishJourneyAndEmitParams = {
          sessionData: this.getSolicitationToken.sessionData,
          sessionId: this.getSolicitationToken.sessionId,
          formData: {
            ...formattedFormData,
            senha: this.model.password,
            senhaConfirm: this.model.confirmPassword,
            municipio: municipio.idmunicipio,
            telefone: telefoneFormatted,
            email: this.formData.email.toLowerCase(),
            uf: this.formData.uf,
            cnpj: this.formData.cnpj?.replace(/\D/g, ''),
            razaoSocial: this.formData.razao_social,
            idunidade: this.idunidade,
            isRFB: this.getSolicitation.isRFB,
          },
        } as FinishJourneyAndEmitType;

        const response = await finishJourneyAndEmit(finishJourneyAndEmitParams);

        const solicitationData = await emissionController.getSolicitationData({
          solicitacao: response.codigioSolicitacao,
          arsubdomain: this.arData.subdomain || '',
        });

        this.setPassword(this.model.password);

        if (Number(solicitationData.situacao.id) === ESolicitationStatus.APROVADA) {
          this.$router.push({ name: 'ReservedDocument' });
          return;
        }

        if (response.isQsa && this.getSolicitation.isSSL) {
          this.$router.push({ path: '/domain-validation' });
          return;
        }

        if (!this.getSolicitation.isPj && (this.hasCei || this.hasPis)) {
          this.$router.push({ path: '/upload-documents-pis-cei' });
          return;
        }

        if (!this.getSolicitation.isPj || response.isQsa) {
          const route = this.getSolicitation.isBirdId
            ? '/installation-guide-birdid'
            : '/installation-guide-token';
          this.$router.push({ path: route });
          return;
        }

        this.$router.push({ path: '/upload-documents' });

        return;
      } catch (error) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const err = error as any;
        this.error = err.message;
        let errorMessage = 'Houve um erro ao tentar atualizar os dados da solicitação';
        if (err.response) {
          if (err.response.data.message && err.response?.data.message !== '') {
            errorMessage = err.response?.data.message;
          }
        }

        this.toggleToastGlobal({
          type: 'danger',
          isShow: true,
          message: errorMessage,
        });
        this.setIsLoading({ isLoading: false });
      } finally {
        setTimeout(() => {
          this.setIsLoading({ isLoading: false });
        }, 300);
      }
    },
  },
  computed: {
    ...mapState(useDynamicForms, ['formData', 'getFormData', 'getPassword']),
    ...mapState(useSolicitation, ['getProduto', 'getSolicitation', 'getSolicitationToken']),
    ...mapState(useAR, ['arData']),
    ...mapState(useMainStore, ['idunidade']),
    hasCei(): boolean {
      return this.getFormData.cei !== '';
    },
    hasPis(): boolean {
      return this.getFormData.pis !== '';
    },
    isValidPassword() {
      // eslint-disable-next-line object-curly-newline
      const { uppercase, lowercase, hasNumber, minLength } = this.validation;
      return (
        // eslint-disable-next-line operator-linebreak
        uppercase &&
        // eslint-disable-next-line operator-linebreak
        lowercase &&
        // eslint-disable-next-line operator-linebreak
        hasNumber &&
        // eslint-disable-next-line operator-linebreak
        minLength &&
        // eslint-disable-next-line operator-linebreak
        this.model.confirmPassword &&
        !this.errorPassword
      );
    },
  },
  mounted() {
    this.form.isPj = this.getSolicitation.isPj;
    this.form.isSSL = this.getSolicitation.isSSL;

    this.openModal();
  },
  async beforeCreate() {
    const ar = useAR();
    const solicitation = useSolicitation();
    const produto = solicitation.getProduto;
    const model = {
      productId: produto.id,
      sku: produto.sku,
      arsubdomain: ar.arData.subdomain || '',
    };

    const { fields, produto: productName } = await dynamicFormsController.getFields(model);

    solicitation.setProduto({ ...solicitation.getProduto, nome: productName });

    let formattedFields = [...fields];

    formattedFields = formattedFields.map((field) => {
      if (
        // eslint-disable-next-line operator-linebreak
        field.id === 'cnpj' ||
        field.id === 'razao_social'
      ) {
        return {
          ...field,
          disable: true,
          order: field.id === 'cnpj' ? -2 : -1,
        };
      }
      return { ...field };
    });

    const fieldsOrdened = formattedFields
      .sort((a: FieldType, b: FieldType) => (a.order < b.order ? -1 : 1))
      .sort((x: FieldType) => (x.disable ? -1 : 1));

    this.form.fields = fieldsOrdened as FieldType[];

    const fieldsInfo = fields.map((field: FieldType) => ({
      id: field.id,
      icon: field.icon,
      label: field.label,
    }));

    this.setFormInfo(fieldsInfo);
    this.dynamicCount += 1;
  },
  async beforeRouteEnter(to) {
    const dynamicForms = useDynamicForms();
    const solicitation = useSolicitation();
    const ar = useAR();

    dynamicForms.clearData();

    const { arsubdomain } = to.params as ParamsRoute;
    const { d: cpfBase64, i: productId, s: sku } = to.query as QueryStringParamsRoute;
    const cpf = window.atob(cpfBase64);

    await ar.fetchAraData(arsubdomain);

    solicitation.setEncryptedData({
      iv: to.query.v as string,
      data: decodeURIComponent(to.query.data as string),
    });

    solicitation.setProduto({
      ...STATE_DEFAULT.produto,
      id: window.atob(productId),
      sku: window.atob(sku),
    });

    dynamicForms.setCPF(ApplyMaskToCPF(cpf));
  },
});
