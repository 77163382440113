
import { defineComponent } from 'vue';
import Icon from '@/components/Atoms/Icon.vue';

interface InputFileEvent extends Event {
    target: HTMLInputElement;
}

interface InputFileDropEvent extends Event {
  dataTransfer: DataTransfer;
}

export default defineComponent({
  name: 'UploadComponent',
  components: {
    Icon,
  },
  data: () => ({
    isDragging: false,
  }),
  props: {
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    dropFile(event: InputFileEvent) {
      this.$emit('addFile', event.target.files);
      this.isDragging = false;
    },
    dragOver(e: InputFileEvent) {
      e.preventDefault();
      this.isDragging = true;
    },
    dragleave() {
      this.isDragging = false;
    },
    drop(e: InputFileDropEvent) {
      e.preventDefault();
      this.$emit('addFile', e.dataTransfer.files);
      this.isDragging = false;
    },
    inputChange(event: InputFileEvent) {
      const input = this.$refs.file as HTMLInputElement;
      this.$emit('addFile', event.target.files);
      input.value = '';
    },
  },
});
