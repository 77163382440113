import { server } from '@/infra/server';
import config from '../../../config';

type ResponseGenerateVoucher = {
  status: string;
  mensagem: string;
};

const gvsController = {
  generateVoucher: async (name: string, cpf: string, idNogociation: string) => {
    const birdIDTrial = config.app.skuBirdIdTrial;
    const { data: response } = await server.post<ResponseGenerateVoucher>('generate-voucher', {
      name,
      cpf,
      idNogociation,
      sku: birdIDTrial,
    });

    return {
      status: response.status,
      voucher: response.mensagem,
    };
  },
};

export default gvsController;
