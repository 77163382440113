import { defineStore } from 'pinia';
import { useStorage } from '@vueuse/core';

export type ProductType = {
  id: string;
  nome: string;
  cei: string;
  pis: string;
  token: string;
  sku: string;
  productId: string;
};

export type TokenType = {
  sessionData: string;
  sessionId: string;
};

export type StateSolicitation = {
  cessToken: TokenType;
  solicitaionToken: TokenType;
  produto: ProductType;
  voucher: string;
  isSSL: boolean;
  isBirdId: boolean;
  isPj: boolean;
  isQsa: boolean;
  isRFB: boolean;
  cdSolicitacao: string;
  userName: string;
  encryptedData: {
    iv: string;
    data: string;
  };
};

export const STATE_DEFAULT = {
  cessToken: {
    sessionData: '',
    sessionId: '',
  } as TokenType,
  solicitaionToken: {
    sessionData: '',
    sessionId: '',
  } as TokenType,
  produto: {
    id: '',
    nome: '',
    cei: '',
    pis: '',
    token: '',
    sku: '',
    productId: '',
  } as ProductType,
  isRFB: false,
  isSSL: false,
  voucher: '',
  isBirdId: false,
  isPj: false,
  isQsa: false,
  cdSolicitacao: '',
  userName: '',
  encryptedData: {
    iv: '',
    data: '',
  },
};

export const useSolicitation = defineStore({
  id: 'solicitationStore',
  state: () => ({
    solicitation: useStorage('solicitation', {
      cessToken: {
        sessionData: '',
        sessionId: '',
      } as TokenType,
      solicitaionToken: {
        sessionData: '',
        sessionId: '',
      } as TokenType,
      produto: {
        id: '',
        nome: '',
        cei: '',
        pis: '',
        token: '',
        sku: '',
        productId: '',
      } as ProductType,
      isRFB: false,
      isSSL: false,
      voucher: '',
      isBirdId: false,
      isPj: false,
      isQsa: false,
      cdSolicitacao: '',
      userName: '',
    } as StateSolicitation),
  }),
  //  region [GETTERS]
  getters: {
    getSolicitation(): StateSolicitation {
      return this.solicitation;
    },
    getCessToken(): TokenType {
      return this.solicitation.cessToken;
    },
    getSolicitationToken(): TokenType {
      return this.solicitation.solicitaionToken;
    },
    getProduto(): ProductType {
      return this.solicitation.produto;
    },
  },
  // end_region [GETTERS]
  actions: {
    setCessToken(token: TokenType) {
      this.solicitation.cessToken = token;
    },
    setNegociationId(token: string) {
      this.solicitation.produto.token = token;
    },
    setCodigoSolicitacao(codigo: string) {
      this.solicitation.cdSolicitacao = codigo;
    },
    saveDataSolicitation(payload: StateSolicitation) {
      this.solicitation = {
        ...this.solicitation,
        ...payload,
      };
    },
    setUserName(payload: string) {
      this.solicitation.userName = payload;
    },
    setIsRFB(payload: boolean) {
      this.solicitation.isRFB = payload;
    },
    setProduto(payload: ProductType) {
      const { produto } = this.solicitation;
      this.solicitation.produto = {
        ...produto,
        ...payload,
      };
    },
    setEncryptedData(payload: { iv: string; data: string }) {
      this.solicitation.encryptedData = payload;
    },
    clearData() {
      this.solicitation = {
        ...STATE_DEFAULT,
      };
    },
    cleanVoucher() {
      this.solicitation.voucher = '';
    },
  },
});
