/* eslint-disable import/prefer-default-export */
const EPanel = {
  EStatus: {
    AGUARDANDO_CSR: '0',
    PAR_CHAVE_GERADO: '1',
    CERTIFICADO_JA_EMITIDO: '2',
    ERRO_SOLICITACAO: '8',
    ERRO_AO_EMITIR: '9',
  },
};

export { EPanel };
