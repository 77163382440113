
import { defineComponent } from 'vue';
import { mapState } from 'pinia';
import { useModalLoading } from '@/store/Pinia/useModalLoading.store';
import { Modal } from 'bootstrap';

export default defineComponent({
  name: 'ModalLoading',
  data: () => ({}),
  computed: {
    ...mapState(useModalLoading, ['modalLoading']),
  },
  watch: {
    'modalLoading.isLoading': function w(current: boolean) {
      if (current) {
        this.modalOpen();
      } else {
        this.modalClose();
      }
    },
  },
  methods: {
    modalOpen() {
      const modal = new Modal('#modalLoading');
      modal.show();
    },
    modalClose() {
      const myModalEl = document.getElementById('modalLoading');
      const modal = Modal.getInstance(myModalEl || '');
      modal?.hide();
    },
  },
});
