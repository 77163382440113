import { AbstractHandler } from './AbstractHandler';
import { ChainTarget } from '../../domain/ChainOfResponsibility/Handler';
import useDifferentComputerErrorModal from '../../composables/useDifferentComputerErrorModal';
import { useModalControl } from '../../store/pinia/useModalControl.store';

export class ReturnCode9001 extends AbstractHandler {
  public async handle(target: ChainTarget): Promise<any> {
    const response = target?.response;
    const code = response?.code;

    if (code === 9001) {
      // timeout to avoid plugin chain from opening modal
      setTimeout(async () => {
        await ReturnCode9001.showPluginModal();
      }, 1000);
      return null;
    }

    return super.handle(target);
  }

  public static async showPluginModal(): Promise<void> {
    const differentComputerErrorModal = useDifferentComputerErrorModal();
    const modalControlStore = useModalControl();

    await modalControlStore.show({
      modalId: 'pluginDownloadModal',
      show: differentComputerErrorModal.showDifferentComputerErrorModal,
      hide: differentComputerErrorModal.hideDifferentComputerErrorModal,
    });
  }
}

export default ReturnCode9001;
