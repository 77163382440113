import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "main-div"
}
const _hoisted_2 = { class: "content-div" }
const _hoisted_3 = {
  key: 1,
  class: "d-flex align-items-center justify-content-center w-100 center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Footer = _resolveComponent("Footer")!
  const _component_ModalLoading = _resolveComponent("ModalLoading")!
  const _component_ToastGlobal = _resolveComponent("ToastGlobal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.showMenu)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_Header),
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_router_view)
          ]),
          _createVNode(_component_Footer)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_router_view)
        ])),
    _createVNode(_component_ModalLoading),
    _createVNode(_component_ToastGlobal)
  ], 64))
}