
import { defineComponent, PropType } from 'vue';
import { Modal } from 'bootstrap';
import Input from '../Atoms/Input.vue';
import Icon from '../Atoms/Icon.vue';
import { VoucherParam } from '@/infra/BirdId/controllers/login.controller';
import { mapActions } from 'pinia';
import { useDynamicForms } from '@/store/Pinia/useDynamicForms.store';
import { useModalLoading } from '@/store/Pinia/useModalLoading.store';

export default defineComponent({
  name: 'ModalRFB',
  components: {
    Input,
    Icon,
  },
  props: {
    createSolicitation: {
      type: Function,
      required: true,
    },
    request: {
      type: Object as PropType<VoucherParam>,
    },
  },
  data: () => ({
    model: {
      cnpj: '',
    },
    modal: null as any,
    error: '',
  }),
  methods: {
    ...mapActions(useDynamicForms, ['setCNPJ']),
    ...mapActions(useModalLoading, ['setIsLoading']),
    modalOpen() {
      this.modal.show();
    },
    modalClose() {
      this.modal.hide();
    },
    async handleNextStep() {
      if (this.model.cnpj === '') {
        this.error = 'CNPJ é obrigatório.';
        return;
      }

      setTimeout(() => {
        this.setIsLoading({
          isLoading: true,
          title: 'Aguarde enquanto localizamos seu Voucher/Pedido',
          image: 'zoom',
          message: '',
        });
      }, 100);

      const model = { ...this.request, cnpj: this.model.cnpj };

      await this.$props.createSolicitation(model);

      this.setCNPJ(this.model.cnpj);

      this.modalClose();
    },
  },
  mounted() {
    const modal = document.getElementById('modalRfb') as HTMLElement;
    this.modal = new Modal(modal, {});
  },
});
