
import { PropType, defineComponent } from 'vue';
import Icon from '@/components/Atoms/Icon.vue';

export default defineComponent({
  name: 'FilesLoader',
  emits: ['handleDeleteFile'],
  components: {
    Icon,
  },
  props: {
    filesLoaded: {
      type: Array as PropType<File[]>,
      default: Array,
    },
    extensionTypes: {
      type: Array as PropType<string[]>,
      required: true,
    },
  },
  methods: {
    isInvalid(item: File) {
      const hasValidFile = this.extensionTypes.includes(item.type.split('/')[1]);
      if (this.bytesToMegabytes(item.size) > 25) {
        return 'Limite máximo de MB excedido.';
      }
      return hasValidFile ? '' : 'Formato inválido.';
    },
    deleteFile(file: File) {
      this.$emit('handleDeleteFile', this.filesLoaded.indexOf(file));
    },
    bytesToMegabytes(bytes: number) {
      return bytes / (1024 * 1024);
    },
  },
});
