
import { defineComponent } from 'vue';
import { Modal } from 'bootstrap';
import Input from '../Atoms/Input.vue';
import Icon from '../Atoms/Icon.vue';
import { mapActions, mapState } from 'pinia';
import { useDynamicForms } from '@/store/Pinia/useDynamicForms.store';
import { useModalLoading } from '@/store/Pinia/useModalLoading.store';
import { ApplyMaskToCPF, RemoveMaskCPF } from '@/utils/formatDocument';
import loginController from '@/infra/BirdId/controllers/login.controller';
import { AxiosError } from 'axios';
import saveDataVoucher from '@/utils/saveDataVoucher';
import { useAR } from '@/store/Pinia/useAR.store';
import { useSolicitation } from '@/store/Pinia/useSolicitation.store';

export default defineComponent({
  name: 'ModalValidationBirdSpro',
  components: {
    Input,
    Icon,
  },
  props: {
    hotreload: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    model: {
      cpf: '',
      otp: '',
      termos: false,
    },
    modal: null as any,
    error: '',
  }),
  computed: {
    ...mapState(useAR, ['arData', 'getSubdomain']),
    ...mapState(useSolicitation, ['getSolicitation']),
    isValid() {
      return !!this.model.termos && this.model.otp !== '' && this.model.otp.length === 7;
    },
  },
  methods: {
    ...mapActions(useDynamicForms, ['setCNPJ', 'setFormData']),
    ...mapActions(useModalLoading, ['setIsLoading']),
    modalOpen() {
      this.modal.show();
    },
    modalClose() {
      this.modal.hide();
    },
    async handleNextStep() {
      try {
        if (!this.isValid) {
          this.error = 'Preencha todos os campos';
          return;
        }

        setTimeout(() => {
          this.setIsLoading({
            isLoading: true,
            title: 'Aguarde enquanto localizamos seu Voucher/Pedido',
            image: 'zoom',
            message: '',
          });
        }, 100);

        const payload = await loginController.validateVoucherSPRO({
          username: RemoveMaskCPF(this.model.cpf),
          otp: this.model.otp,
          data: this.getSolicitation.encryptedData,
          arsubdomain: this.getSubdomain,
        });

        saveDataVoucher(payload);

        const { formData } = useDynamicForms();

        this.setCNPJ(payload.dataDecrypted?.cnpj || '');

        this.setFormData({
          ...formData,
          nome: payload.nome,
          cpf: this.model.cpf,
        });
        this.hotreload();
        setTimeout(() => {
          this.modalClose();
        }, 200);
      } catch (error) {
        const err = error as AxiosError<{ message: string }>;
        if (err.response && err.response.data) {
          this.error = err.response.data.message || 'Erro ao validar o OTP';
        }
      } finally {
        this.setIsLoading({ isLoading: false });
      }
    },
  },
  mounted() {
    const forms = useDynamicForms();

    const modal = document.getElementById('modalValidationBirdSpro') as HTMLElement;
    this.modal = new Modal(modal, {});

    this.model.cpf = ApplyMaskToCPF(forms.formData.cpf);
  },
});
