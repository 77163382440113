
import Icon from '@/components/Atoms/Icon.vue';
import { defineComponent } from 'vue';
import VersionButton from './VersionButton.vue';
import config from '../../../config';

export default defineComponent({
  name: 'FooterComponent',
  url: config,
  components: {
    Icon,
    VersionButton,
  },
});
