
import { defineComponent } from 'vue';
import Contact from '@/components/Molecules/Contact.vue';
import Icon from '@/components/Atoms/Icon.vue';
import TextArea from '@/components/Atoms/TextArea.vue';
import Breadcrumb from '@/components/Molecules/Breadcrumbs.vue';
import CRSValidation from '@/utils/CRSValidation';
import { mapState, mapActions } from 'pinia';
import { useSSLCertificate } from '@/store/Pinia/useSSLCertificate.store';
import { useDynamicForms } from '@/store/Pinia/useDynamicForms.store';
import { useAR } from '@/store/Pinia/useAR.store';
import { useToastGlobal } from '@/store/Pinia/useToastGlobal.store';
import Loading from '@/components/Atoms/Loading.vue';
import { panelController } from '@/infra/SSLCertificate/panel.controller';
import { EPanel } from '@/helpers/EPanel.enum';
import { AxiosError } from 'axios';
import formatCSR from '@/utils/formatCSR';
// import formatCSR from '@/utils/formatCSR';

export default defineComponent({
  name: 'HomeView',
  components: {
    Contact,
    Icon,
    TextArea,
    Breadcrumb,
    Loading,
  },
  data: () => ({
    model: {
      csr: '',
      signedHash: '',
    },
    errors: {
      csr: '',
      signedHash: '',
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    validationVerifier: null as any, // TODO: set the right type for the interval
    isLoading: false,
  }),
  computed: {
    ...mapState(useSSLCertificate, ['sslCertificate']),
    ...mapState(useDynamicForms, ['getPassword']),
    ...mapState(useAR, ['arData']),
    isDisaled() {
      if (!this.hastermDigitalHash) {
        return this.model.csr === '';
      }

      return this.model.csr === '' || this.model.signedHash === '';
    },
    isValid() {
      return !Object.values(this.errors).find((x) => x !== '') && !this.isDisaled;
    },
    hastermDigitalHash() {
      return this.sslCertificate.digitaltermHash !== '';
    },
    pathlist() {
      return {
        'Guia de instalação SSL': 'ssl-certificate/installation-guide-ssl',
        'Downolad de Certificado Digital com CSR': '',
      };
    },
  },
  watch: {
    'model.csr': function w(curr) {
      if (!CRSValidation(curr)) {
        this.errors.csr = 'Insira um CSR válido';
      } else {
        this.errors.csr = '';
      }
    },
    'model.signedHash': function w() {
      this.errors.signedHash = '';
    },
  },
  methods: {
    ...mapActions(useToastGlobal, ['toggleToastGlobal']),
    handleSubmit() {
      if (this.hastermDigitalHash) {
        return this.submitWithHash();
      }

      return this.submitWithoutHash();
    },
    async submitWithHash() {
      try {
        if (this.isValid && this.model.csr !== '' && this.model.signedHash !== '') {
          const model = {
            solicitacao: this.sslCertificate.user,
            senha: this.getPassword,
            csr: formatCSR(this.model.csr),
            digitaltermSignature: this.model.signedHash,
            arsubdomain: this.arData.subdomain || '',
          };

          this.isLoading = true;

          await panelController.emit(model);

          this.validationVerifier = setInterval(async () => {
            await this.verifyValidation();
          }, 5000);
        }

        this.errors.signedHash = 'É necessário inserir o hash do Termo Digital assinado.';
      } catch (error) {
        this.errors.signedHash = 'Erro ao enviar o HASH assinado';
      }
    },
    async submitWithoutHash() {
      try {
        if (this.isValid && this.model.csr !== '') {
          const model = {
            solicitacao: this.sslCertificate.user,
            senha: this.getPassword,
            csr: formatCSR(this.model.csr),
            digitaltermSignature: '',
            arsubdomain: this.arData.subdomain || '',
          };

          this.isLoading = true;

          await this.verifyValidation();

          const responseEmit = await panelController.emit(model);

          if (responseEmit.mensagem.erro) {
            this.isLoading = false;
            throw new Error(responseEmit.mensagem.erro?.mensagem);
          }

          this.validationVerifier = setInterval(async () => {
            await this.verifyValidation();
          }, 5000);
        }
      } catch (error) {
        const err = error as AxiosError;
        this.toggleToastGlobal({
          isShow: true,
          message: err.message || 'Erro ao emitir o certificado',
        });
        clearInterval(this.validationVerifier);
      }
    },
    async verifyValidation() {
      try {
        const response = await panelController.validate({
          password: this.getPassword,
          solicitation: this.sslCertificate.user,
          arsubdomain: this.arData.subdomain || '',
        });

        if (response.mensagem.emissao) {
          if (response.mensagem.emissao.status === EPanel.EStatus.CERTIFICADO_JA_EMITIDO) {
            this.$router.push('/ssl-certificate/csr-download');
            clearInterval(this.validationVerifier);
          }

          if (response.mensagem.emissao.status === EPanel.EStatus.ERRO_SOLICITACAO) {
            this.isLoading = false;
            clearInterval(this.validationVerifier);
            const errorDefault = 'Erro ao validar informações, tente novamente mais tarde';
            throw new Error(response.mensagem.emissao.obs || errorDefault);
          }
        }
      } catch (error) {
        this.toggleToastGlobal({
          isShow: true,
          message: 'Erro ao validar informações, tente novamente mais tarde',
        });
        clearInterval(this.validationVerifier);
      }
    },
    downloadHash() {
      const blob = new Blob([this.sslCertificate.digitaltermHash]);
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'digitalterm_hash.hex';
      link.click();
      link.remove();
    },
    goToCsrInstructions() {
      const routeData = this.$router.resolve({ path: '/faq/generate-csr-request' });
      window.open(routeData.href, '_blank');
    },
  },
  async beforeRouteEnter(to, from, next) {
    const sslStore = useSSLCertificate();
    const dynamicFormsStore = useDynamicForms();

    const { user } = sslStore.$state.sslCertificate;
    const { senha } = dynamicFormsStore.$state.formData;

    if (user === '' && senha === '') {
      next('/ssl-certificate/login-ssl');
    }

    next();
  },
});
