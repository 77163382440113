
import { defineComponent } from 'vue';
import Icon from '@/components/Atoms/Icon.vue';

export default defineComponent({
  name: 'ModalAreYouShure',
  components: {
    Icon,
  },

});
