
import { defineComponent } from 'vue';
import Contact from '@/components/Molecules/Contact.vue';
import Icon from '@/components/Atoms/Icon.vue';
import Input from '@/components/Atoms/Input.vue';
import Breadcrumb from '@/components/Molecules/Breadcrumbs.vue';
import sentFilesController from '@/infra/sisAr/sentFiles.controller';
import { useToastGlobal } from '@/store/Pinia/useToastGlobal.store';
import { useSolicitation } from '@/store/Pinia/useSolicitation.store';
import { useDynamicForms } from '@/store/Pinia/useDynamicForms.store';
import { mapActions } from 'pinia';

export default defineComponent({
  name: 'HomeView',
  components: {
    Contact,
    Icon,
    Input,
    Breadcrumb,
  },
  data: () => ({
    model: {
      codigoSolicitacao: '',
      password: '',
    },
    error: '',
  }),
  computed: {

    isValid() {
      return !!this.model.codigoSolicitacao
          && !!this.model.password
          && !this.error;
    },
    requestCodeTooltipText() {
      return 'Você pode encontrar o código da solicitação em seu e-mail com o texto: “Emissão de Certificado Digital APROVADA” no assunto.';
    },
  },
  methods: {
    ...mapActions(useToastGlobal, ['toggleToastGlobal']),
    ...mapActions(useDynamicForms, ['setPassword']),
    ...mapActions(useSolicitation, ['setCodigoSolicitacao']),
    async submit() {
      try {
        if (this.error === '' && this.isValid && this.model.password) {
          const response = await sentFilesController.login(
            this.model.codigoSolicitacao,
            this.model.password,
          );

          if (response) {
            this.setPassword(this.model.password);
            this.setCodigoSolicitacao(this.model.codigoSolicitacao);
            this.$router.push('/pending-upload-documents');
          }
        }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        if (error.message === 'Código e/ou senha incorreto(s).') {
          this.toggleToastGlobal({
            isShow: true,
            message: error.message,
          });
        } else if (error.message === 'Solicitação não pode enviar documentos: Solicitação está aprovada.') {
          this.toggleToastGlobal({
            isShow: true,
            message: error.message,
          });
        } else {
          this.toggleToastGlobal({
            isShow: true,
            message: 'Erro ao realizar o login, tente novamente mais tarde.',
          });
        }
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    const dynamicFormsStore = useDynamicForms();
    const solicitationStore = useSolicitation();

    const pass = dynamicFormsStore.getPassword;

    if (solicitationStore.getSolicitation.cdSolicitacao !== '' && pass !== '') {
      next({ name: 'pendingUploadFiles' });
    } else {
      next();
    }
  },
});
