/* eslint-disable import/prefer-default-export */
const GVSStatusEnum = {
  INVALID_AUTHENTICATION: {
    status: '0',
    mensagem: 'Autenticação inválida/HMAC inválido.',
  },
  VOUCHER_SUCCESS: {
    status: '1',
    mensagem: 'Voucher',
  },
  NEGOTIATION_NOT_FOUND: {
    status: '2',
    mensagem: 'Negociação não encontrada.',
  },
  INACTIVE_NEGOTIATION: {
    status: '3',
    mensagem:
      'A Negociação não está ativa, favor entrar em contato com o Departamento Comercial da Soluti.',
  },
  UNAVAILABLE_PRODUCT: {
    status: '4',
    mensagem: 'Produto não disponível/inexistente.',
  },
  MISSING_SEQUENCE_NUMBER: {
    status: '5',
    mensagem: 'Número da sequência não informado.',
  },
  MISSING_SUGGESTION: {
    status: '6',
    mensagem: 'Informe a sugestão de uso.',
  },
  MISSING_CPF_OR_CNPJ: {
    status: '7',
    mensagem: 'Informe o CPF/CNPJ da sugestão de uso.',
  },
  MISSING_VOUCHER_RESTRICTION: {
    status: '8',
    mensagem: 'Informe se o voucher será de uso restrito ou não.',
  },
  INVALID_RESTRICTION_VALUE: {
    status: '9',
    mensagem: 'Informe true para voucher restrito ou false para não restrito.',
  },
  INVALID_CNPJ: {
    status: '10',
    mensagem: 'O CNPJ informado é inválido.',
  },
  INVALID_CPF: {
    status: '11',
    mensagem: 'O CPF informado é inválido.',
  },
};

export { GVSStatusEnum };
