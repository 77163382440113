
import { defineComponent } from 'vue';
import Contact from '@/components/Molecules/Contact.vue';
import Icon from '@/components/Atoms/Icon.vue';
import Input from '@/components/Atoms/Input.vue';
import Loading from '@/components/Atoms/Loading.vue';
import { EPanel } from '@/helpers/EPanel.enum';
import { mapActions, mapState } from 'pinia';
import { useDynamicForms } from '@/store/Pinia/useDynamicForms.store';
import { useSolicitation } from '@/store/Pinia/useSolicitation.store';
import { useAR } from '@/store/Pinia/useAR.store';
import { panelController } from '@/infra/SSLCertificate/panel.controller';
import { useToastGlobal } from '@/store/Pinia/useToastGlobal.store';
import { useSSLCertificate } from '@/store/Pinia/useSSLCertificate.store';
import {
  DomainsValidationType,
  domainValidationController,
} from '@/infra/DomainValidation/domainValidation.controller';
import { Tooltip } from 'bootstrap';
import emissionController from '@/infra/BirdId/controllers/emission.controller';
import { ESolicitationStatus } from '@/helpers/ESisar.enum';

export default defineComponent({
  name: 'HomeView',
  components: {
    Contact,
    Icon,
    Input,
    Loading,
  },
  data: () => ({
    user: '',
    password: '',
    error: '',
    userTooltip: null as Tooltip | null,
    passwordTooltip: null as Tooltip | null,
    isLoading: false,
  }),
  computed: {
    ...mapState(useAR, ['arData']),
    ...mapState(useSSLCertificate, ['sslCertificate']),
    isValid() {
      return !!this.user && !!this.password && !this.error;
    },
    userTooltipText() {
      return 'Você pode encontrar seu código de usuário em seu e-mail com o texto: “Emissão de Certificado Digital APROVADA” no assunto.';
    },
    passwordTooltipText() {
      return 'A Senha de Emissão foi criada por você no momento do preenchimento dos dados do seu Certificado.';
    },
    pathList() {
      return {
        'Guia de instalação SSL': 'ssl-certificate/installation-guide-ssl',
        'Downolad de Certificado Digital com CSR': '',
      };
    },
  },
  methods: {
    ...mapActions(useToastGlobal, ['toggleToastGlobal']),
    ...mapActions(useSSLCertificate, ['setUser', 'setDigitalTermHash']),
    ...mapActions(useDynamicForms, ['setPassword']),
    ...mapActions(useSolicitation, ['setCodigoSolicitacao']),
    async submit() {
      try {
        this.isLoading = true;
        if (this.error === '' && this.isValid && this.password) {
          this.$router.push('');
        }

        this.setUser(this.user.trim());
        this.setCodigoSolicitacao(this.user.split(' ')[0]);
        this.setPassword(this.password);

        const solicitation = await emissionController.getSolicitationData({
          arsubdomain: this.arData.subdomain || '',
          solicitacao: this.user.split(' ')[0],
        });

        if (solicitation.produto.isSSL) {
          const response = await domainValidationController.getDomainsValidation({
            codigo: this.user.split(' ')[0].trim(),
            senha: this.password,
          });

          if (!this.checkDomainValidity(response.domainsValidation)) {
            return this.$router.push('/domain-validation/validation');
          }
        }

        const statusSolicitacao = Number(solicitation.situacao.id);

        if (
          // eslint-disable-next-line operator-linebreak
          statusSolicitacao !== ESolicitationStatus.APROVADA &&
          statusSolicitacao !== ESolicitationStatus.EMITIDA
        ) {
          throw new Error(
            `Solicitação ainda não está pronta para emissão. Status:
        ${solicitation.situacao.descricao}`,
          );
        }

        const validateResponse = await panelController.validate({
          password: this.password,
          solicitation: this.user,
          arsubdomain: this.arData.subdomain || '',
        });

        if (validateResponse.mensagem.erro) {
          throw new Error(validateResponse.mensagem.erro.mensagem);
        }

        switch (validateResponse.mensagem.emissao?.status) {
          case EPanel.EStatus.AGUARDANDO_CSR:
            this.setDigitalTermHash(validateResponse.mensagem.emissao.digitalterm_hash || '');
            this.$router.push('/ssl-certificate/csr-signature');
            break;
          case EPanel.EStatus.CERTIFICADO_JA_EMITIDO:
            this.$router.push('/ssl-certificate/csr-download');
            break;
          default:
            this.toggleToastGlobal({
              isShow: true,
              type: 'info',
              message:
                // eslint-disable-next-line operator-linebreak
                validateResponse.mensagem.emissao?.obs ||
                'Erro ao validar solicitação, entre em contato com o suporte.',
            });
            break;
        }

        return null;
      } catch (error) {
        const err = error as ErrorEvent;
        return this.toggleToastGlobal({
          type: 'danger',
          isShow: true,
          message: err.message,
        });
      } finally {
        this.isLoading = false;
      }
    },
    checkDomainValidity(domainsValidation: DomainsValidationType[]): boolean {
      let allArevalid = true;

      domainsValidation.forEach((domainObject) => {
        if (!domainObject.verified_at) {
          allArevalid = false;
        }
      });

      return allArevalid;
    },
  },
  mounted() {
    this.user = this.sslCertificate.user;

    this.userTooltip = new Tooltip('#user-info-tooltip', {
      title: this.userTooltipText,
      placement: 'right',
    });

    this.passwordTooltip = new Tooltip('#password-info-tooltip', {
      title: this.passwordTooltipText,
      placement: 'right',
    });
  },
  async beforeRouteEnter(to, from, next) {
    const sslStore = useSSLCertificate();
    const dynamicFormsStore = useDynamicForms();
    const arStore = useAR();
    const toast = useToastGlobal();

    const { user } = sslStore.$state.sslCertificate;
    const { senha } = dynamicFormsStore.$state.formData;
    const { subdomain } = arStore.$state.arData;

    if (user !== '' && senha !== '') {
      try {
        const response = await panelController.validate({
          password: senha,
          solicitation: user,
          arsubdomain: subdomain || '',
        });

        if (response.mensagem.emissao?.status === EPanel.EStatus.AGUARDANDO_CSR) {
          next('/ssl-certificate/csr-signature');
        }

        next();
      } catch (error) {
        toast.toggleToastGlobal({
          isShow: true,
          message: 'Erro ao validar informações, tente novamente mais tarde',
        });
      }
    }

    next();
  },
});
