
import { defineComponent } from 'vue';
import Icon from '@/components/Atoms/Icon.vue';
import Breadcrumb from '@/components/Molecules/Breadcrumbs.vue';
import Contact from '@/components/Molecules/Contact.vue';
import { mapActions, mapState } from 'pinia';
import { useSolicitation } from '@/store/Pinia/useSolicitation.store';
import { useAR, ArDataType } from '@/store/Pinia/useAR.store';
import browserDeviceCheck from '@/utils/browserDeviceCheck';
import DocumentToPrint from './DocumentToPrint.vue';
import { RouterLink } from 'vue-router';

export default defineComponent({
  name: 'ReservedDocumentView',
  components: {
    Contact,
    Icon,
    Breadcrumb,
    DocumentToPrint,
    RouterLink,
  },
  data: () => ({
    form: {
      arData: {} as ArDataType,
      codigoSolicitacao: '',
      user: '',
    },
    isMobile: browserDeviceCheck(),
  }),
  computed: {
    ...mapState(useSolicitation, ['getSolicitation']),
    ...mapState(useAR, ['arData']),
  },
  mounted() {
    this.form.arData = this.arData;
    this.form.codigoSolicitacao = this.getSolicitation.cdSolicitacao;
    this.form.user = this.getSolicitation.userName;

    this.cleanVoucher();
  },
  methods: {
    ...mapActions(useSolicitation, ['cleanVoucher']),
    imprimir() {
      window.print();
    },
  },
});
