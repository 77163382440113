
import { defineComponent } from 'vue';
import Icon from '@/components/Atoms/Icon.vue';
import Contact from '@/components/Molecules/Contact.vue';
import Breadcrumb from '@/components/Molecules/Breadcrumbs.vue';
import Wizzard from '@/components/Molecules/Wizzard.vue';
import Input from '@/components/Atoms/Input.vue';
import { mapState } from 'pinia';
import { useSolicitation } from '@/store/Pinia/useSolicitation.store';
import { useAR } from '@/store/Pinia/useAR.store';
import { Tooltip } from 'bootstrap';

export default defineComponent({
  name: 'SuccessUploadFilesView',
  components: {
    Icon,
    Contact,
    Input,
    Breadcrumb,
    Wizzard,
  },
  data: () => ({
    model: {
      codigoSolicitacao: '',
      documento: '',
    },
    form: {
      codigoSolicitacao: '',
    },
    tooltipMessage: 'Clique para copiar',
    alert: {
      isShow: false,
      message: '',
      type: 'info',
    },
    count: 1,
  }),
  computed: {
    ...mapState(useSolicitation, ['getSolicitation']),
    ...mapState(useAR, ['arData']),
    getIconAlert() {
      if (this.alert.type === 'danger') return 'circle-xmark';
      if (this.alert.type === 'warning') return 'circle-info';
      if (this.alert.type === 'info') return 'bell';

      return 'circle-check';
    },
  },
  methods: {
    async copyTextToClipboard() {
      await navigator.clipboard.writeText(this.form.codigoSolicitacao);
      const textTooltip = document.getElementsByClassName('tooltip-inner');

      if (textTooltip.length > 0) {
        textTooltip[0].innerHTML = 'Código copiado';
      }
      setTimeout(() => {
        textTooltip[0].innerHTML = 'Clique para copiar';
      }, 3000);
    },
    createTooltip(id: string) {
      const tooltip = new Tooltip(id);
      tooltip.show();
    },
  },
  mounted() {
    this.form.codigoSolicitacao = this.getSolicitation.cdSolicitacao;
  },
});
