
import { PropType, defineComponent } from 'vue';
import { ObjMunicipio } from '@/store/Pinia/useMain.store';
import { mapActions, mapState } from 'pinia';
import { useDynamicForms, FormDataType } from '@/store/Pinia/useDynamicForms.store';
import { FieldType } from '@/infra/sisAr/dynamicForms.controller';
import allValidates from '@/utils/validates';
import Input from '../Atoms/Input.vue';
import Icon from '../Atoms/Icon.vue';

export default defineComponent({
  name: 'dynamicFormsComponent',
  components: {
    Input,
    Icon,
  },
  props: {
    fields: {
      type: Array as PropType<FieldType[]>,
      required: true,
    },
  },
  data: () => ({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    model: {} as any,
    form: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      fields: [{}] as any,
      municipios: [] as ObjMunicipio[],
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    errors: {} as any,
  }),
  computed: {
    ...mapState(useDynamicForms, ['getMunicipiosByState', 'getFormData', 'getFormDataDefault']),
  },
  beforeUpdate() {
    const hasErros = Object.entries(this.errors).some(([, value]) => value);
    const requiredFields = this.form.fields
      .filter((field: { required: boolean }) => field.required)
      .map((field: { id: string }) => field.id);
    const emptyFields = Object.keys(this.model).filter((key: string) => !this.model[key]);
    const hasEmptyRequired = emptyFields.some((field: string) => requiredFields.includes(field));

    this.$emit('isValid', !hasErros && !hasEmptyRequired);
  },
  methods: {
    ...mapActions(useDynamicForms, ['setUf']),
    getOptions(field: FieldType) {
      if (field.id === 'municipio') {
        return this.form.municipios;
      }
      const options = Object.entries(field.values).map(([key, value]) => ({
        nome: value,
        value: key,
      }));
      return options;
    },

    fillWithMatchingAttributes(sourceObject: FormDataType) {
      if (!sourceObject || typeof sourceObject !== 'object' || Array.isArray(sourceObject)) {
        throw new Error('The first argument must be an object.');
      }

      if (!this.model || typeof this.model !== 'object' || Array.isArray(this.model)) {
        throw new Error('The second argument must be an object.');
      }

      Object.entries(sourceObject).forEach(([key, value]) => {
        if (Object.prototype.hasOwnProperty.call(this.model, key)) {
          this.model[key] = value;
        }
      });
    },
    validateField(
      fieldName: string,
      minLength: number,
      maxLength: number,
      currentValue?: string,
      oldValue?: string,
      isRequired?: boolean,
      isNumeric?: boolean,
    ) {
      // if (currentValue && currentValue.length >= minLength && currentValue.length <= maxLength) {
      //   this.errors[fieldName] = '';
      //   return;
      // }

      if (minLength && currentValue) {
        const realValue = isNumeric ? currentValue.replace(/\D/g, '') : currentValue;
        if (realValue.length < minLength) {
          this.errors[fieldName] = `Este campo deve ter no mínimo ${minLength} caracteres`;
          return;
        }
      }

      if (maxLength && currentValue) {
        const realValue = isNumeric ? currentValue.replace(/\D/g, '') : currentValue;
        if (realValue.length > maxLength) {
          this.errors[fieldName] = `Este campo deve ter no máximo ${maxLength} caracteres`;
          return;
        }
      }

      if (currentValue && currentValue.length > 0) {
        this.errors[fieldName] = '';
        return;
      }

      this.errors[fieldName] = 'Este campo é obrigatório';

      if (currentValue === '' && !oldValue) {
        this.errors[fieldName] = '';
      }

      if (!isRequired) {
        this.errors[fieldName] = '';
      }
    },
    validateFieldWithValidation(
      fieldName: string,
      message: string,
      handler: (value: string) => boolean,
      currentValue?: string,
      oldValue?: string,
      isRequired?: boolean,
    ) {
      if (currentValue && currentValue.length > 0) {
        if (handler(currentValue)) {
          this.errors[fieldName] = '';
          return;
        }
        this.errors[fieldName] = message;
        return;
      }

      this.errors[fieldName] = 'Este campo é obrigatório';

      if (currentValue === '' && !oldValue) {
        this.errors[fieldName] = '';
      }

      if (!isRequired) {
        this.errors[fieldName] = '';
      }
    },
    setWatchs() {
      const modelKeys = Object.keys(this.model);

      modelKeys.forEach((key) => {
        const field = this.fields.find((x: FieldType) => key === x.id);
        const validationMethodName = field?.validationMethod;

        if (key === 'municipio') {
          return;
        }

        if (key === 'uf') {
          this.$watch(`model.${key}`, (curr: string, old: string) => {
            if (curr && curr.length > 0) {
              this.setUf(curr);
              this.form.municipios = this.getMunicipiosByState;
              this.errors.uf = '';
              return;
            }

            this.errors.uf = 'Este campo é obrigatório';

            if (curr === '' && !old) {
              this.errors.uf = '';
            }
          });
          return;
        }

        if (validationMethodName && validationMethodName !== null) {
          this.$watch(
            `model.${key}`,
            (curr: string, old: string) =>
              // eslint-disable-next-line implicit-arrow-linebreak
              this.validateFieldWithValidation(
                key,
                `Digite um ${key} válido`,
                allValidates[validationMethodName],
                curr,
                old,
                field?.required,
              ),
            // eslint-disable-next-line function-paren-newline
          );
          return;
        }
        this.$watch(`model.${key}`, (curr: string, old: string) => {
          this.validateField(
            key,
            field?.min_length as number,
            field?.max_length as number,
            curr,
            old,
            field?.required,
            field?.isNumeric,
          );
        });
      });
    },
  },
  created() {
    this.form.fields = this.fields;
    setTimeout(() => {
      const newModel = this.fields.reduce(
        (acc, curr) => ({ ...acc, [curr.id]: '' }),
        {} as FormDataType,
      );
      this.errors = { ...newModel };
      this.model = { ...newModel };
      const formData = { ...this.getFormData };
      this.fillWithMatchingAttributes(formData);
      this.setWatchs();
    }, 100);
  },
});
