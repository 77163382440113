import ModalEmissionSteps from '../modals/ModalEmissionSteps.vue';
import { ref } from 'vue';

const emissionStepsModal = ref<InstanceType<typeof ModalEmissionSteps> | null>(null);

export default function useEmissionStepsModal() {
  function showEmissionStepsModal() {
    emissionStepsModal.value?.show();
  }

  function hideEmissionStepsModal() {
    emissionStepsModal.value?.hide();
  }

  function setEmissionStep(step: number) {
    if (step !== emissionStepsModal.value?.step) {
      emissionStepsModal.value?.setEmissionStep(step);
    }
  }

  return {
    emissionStepsModal,
    showEmissionStepsModal,
    hideEmissionStepsModal,
    setEmissionStep,
  };
}
