/* eslint-disable no-await-in-loop */
/* eslint-disable no-plusplus */
import { server } from '@/infra/server';
import { useSolicitation } from '@/store/Pinia/useSolicitation.store';
import { useDynamicForms } from '@/store/Pinia/useDynamicForms.store';

export type DocumentType = {
  name: string;
  extensao: string;
  file: string;
}

export type RequestBodyTypeSendFiles = {
  solicitacao: string;
  pass: string;
  docs: DocumentType[],
}

async function convertFileToBlob(file: File): Promise<Blob> {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = () => {
      resolve(new Blob([reader.result as ArrayBuffer], { type: file.type }));
    };
  });
}

async function convertBlobToBase64(blob: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = () => {
      resolve(reader.result as string);
    };
    reader.onerror = (error) => {
      reject(error);
    };
  });
}

// CONVERT Files do base64
const prepareObjDoc = async (file: File) => {
  const fileBlob = await convertFileToBlob(file);
  const doc = await convertBlobToBase64(fileBlob);
  return {
    name: file.name.split('.')[0],
    extensao: `.${file.type.split('/')[1]}`,
    file: doc.split(',')[1],
  };
};

const sentFilesController = {
  sendFiles: async (filesLoaded: File[]) => {
    const extentionsAllows = ['png', 'jpg', 'jpeg', 'doc', 'docs', 'docx', 'pdf'];

    const sendFile = async (request: RequestBodyTypeSendFiles) => {
      const response = await server.post('send-files', request);
      if (response.status !== 200) {
        throw new Error('Error to send file');
      }
      return response;
    };

    const convertFileAndSend = async (file: File) => {
      const files = await prepareObjDoc(file);
      const dynamicFormStore = useDynamicForms();
      const solicitationStore = useSolicitation();
      const request = {
        solicitacao: solicitationStore.getSolicitation.cdSolicitacao,
        pass: dynamicFormStore.getPassword,
        docs: [
          files as DocumentType,
        ],
      };
      const data = await sendFile(request);
      return data;
    };

    const filtered = filesLoaded.filter((file) => extentionsAllows.includes(file.type.split('/')[1]));
    for (let index = 0; index < filtered.length; index++) {
      await convertFileAndSend(filtered[index]);
    }
  },
  login: async (codigo: string, senha: string) => {
    const response = await server.post('get-files', {
      codigo,
      senha,
    });
    return response.data;
  },
  list: async (codigo: string, senha: string) => {
    const response = await server.post('get-files', {
      codigo,
      senha,
    });

    const files = response.data.list.map((fileName: string) => new File([''], fileName, { type: `/${fileName.split('.')[1]}` }));

    return files;
  },
  delete: async (codigo: string, senha: string, fileName: string) => {
    const response = await server.post('delete-file', {
      codigo,
      senha,
      filename: fileName,
    });

    return response;
  },
};

export default sentFilesController;
