
import { defineComponent } from 'vue';
import Icon from '@/components/Atoms/Icon.vue';
import FAQSidebarBody from './FAQSidebarBody.vue';

export default defineComponent({
  name: 'FAQSidebar',
  components: {
    Icon,
    FAQSidebarBody,
  },
});
