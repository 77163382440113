

import { defineComponent } from 'vue';
import Icon from '@/components/Atoms/Icon.vue';
import { mapState } from 'pinia';
import { useDynamicForms, FieldInfoType } from '@/store/Pinia/useDynamicForms.store';
import { useSolicitation } from '@/store/Pinia/useSolicitation.store';

export default defineComponent({
  name: 'dynamicSummaryComponent',
  components: {
    Icon,
  },
  computed: {
    ...mapState(useDynamicForms, ['formData', 'formInfo']),
    ...mapState(useSolicitation, ['getSolicitation', 'getProduto']),
    fieldsWithValuesWithoutCpf() {
      const fields = this.formInfo as FieldInfoType[];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const data = this.formData as any;

      return fields
        .map((field: FieldInfoType) => (
          {
            ...field,
            value: data[field.id as keyof typeof data],
          }
        ))
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .filter((field: any) => field.value && field.id !== 'cpf');
    },
  },
});
