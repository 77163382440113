
import { defineComponent } from 'vue';
import Icon from '@/components/Atoms/Icon.vue';
import Input from '@/components/Atoms/Input.vue';
import Breadcrumb from '@/components/Molecules/Breadcrumbs.vue';
import Contact from '@/components/Molecules/Contact.vue';
import { mapState } from 'pinia';
import { useSolicitation } from '@/store/Pinia/useSolicitation.store';
import { useAR, ArDataType } from '@/store/Pinia/useAR.store';
import ModalOnlyUseOnDesktop from '@/components/templates/ModalOnlyUseOnDesktop.vue';
import browserDeviceCheck from '@/utils/browserDeviceCheck';
import ModalAreYouShure from './modals/ModalAreYouShure.vue';
import ModalDownloadInstruction from './modals/ModalDownloadInstruction.vue';

export default defineComponent({
  name: 'HomeView',
  components: {
    Contact,
    ModalAreYouShure,
    ModalDownloadInstruction,
    ModalOnlyUseOnDesktop,
    Icon,
    Input,
    Breadcrumb,
  },
  data: () => ({
    form: {
      arData: {} as ArDataType,
    },
    isMobile: browserDeviceCheck(),
  }),
  computed: {
    ...mapState(useSolicitation, ['getSolicitation']),
    ...mapState(useAR, ['arData', 'getLinkEmissor']),
    issuerUrl() {
      return this.getLinkEmissor;
    },
    pathlist() {
      return { 'Guia de instalação SSL': '#' };
    },
  },
  mounted() {
    this.form.arData = this.arData;
  },
  methods: {
    issuerButtonClick() {
      if (this.isMobile) {
        return (
          document.getElementById('modal-only-desktop-issuer-button') as HTMLButtonElement
        ).click();
      }

      return (document.getElementById('modal-are-you-shure-button') as HTMLButtonElement).click();
    },
    issuerLinkClick() {
      if (this.isMobile) {
        return (
          document.getElementById('modal-only-desktop-issuer-button') as HTMLButtonElement
        ).click();
      }

      return (document.getElementById('dowload-link') as HTMLAnchorElement).click();
    },
  },
});
