
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'TextArea',
  props: {
    rows: {
      type: String,
      default: '3',
    },
    placeholder: {
      type: String,
    },
    name: {
      type: String,
    },
    label: {
      type: String,
    },
    modelValue: {
      type: String,
    },
    isDisabled: {
      type: Boolean,
    },
    isRequired: {
      type: Boolean,
    },
    errorLog: {
      type: String,
    },
    size: {
      type: String,
    },
    resizable: {
      type: Boolean,
      default: true,
    },
    overflowHidden: {
      type: Boolean,
    },
  },
  computed: {
    getColorIcon() {
      if (this.$props.isDisabled) {
        return 'gray-dark';
      }

      if (this.$props.errorLog !== '') {
        return 'danger';
      }

      return 'primary';
    },
    requiredSymbol() {
      return this.$props.isRequired && this.$props.label ? '*' : '';
    },
  },
});
