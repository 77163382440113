import { RouteRecordRaw } from 'vue-router';
import Container from '@/components/Container.vue';
import DomainValidationView from '@/views/DomainValidation/DomainValidationView.vue';
import ValidationSuccess from '@/views/DomainValidation/ValidationSuccessView.vue';
import HttpValidationInstructions from '@/views/DomainValidation/http/HttpValidationInstructions.vue';
import DnsValidationInstructions from '@/views/DomainValidation/dns/DnsValidationInstructions.vue';
import CheckDomainValidation from '@/views/DomainValidation/CheckDomainValidation.vue';

const domainValidation: Array<RouteRecordRaw> = [
  {
    path: '/domain-validation',
    component: Container,
    redirect: {
      name: 'Validation',
    },
    children: [
      {
        path: 'check-domain-validation',
        name: 'CheckDomainValidation',
        component: CheckDomainValidation,
      },
      {
        path: 'validation',
        name: 'Validation',
        component: DomainValidationView,
      },
      {
        path: 'validation-success/:type',
        name: 'ValidationSuccess',
        props: {
          type: String,
        },
        component: ValidationSuccess,
      },
      {
        path: 'http-instructions',
        name: 'HttpValidationInstructions',
        component: HttpValidationInstructions,
      },
      {
        path: 'dns-instructions',
        name: 'DnsValidationInstructions',
        component: DnsValidationInstructions,
      },
    ],
  },
];

export default domainValidation;
