import { useAR } from '@/store/Pinia/useAR.store';
import {
  createRouter,
  createWebHistory,
  NavigationGuardNext,
  RouteLocationNormalized,
  RouteRecordRaw,
} from 'vue-router';

import Error404View from '@/views/Base/Error404View.vue';
import HomeView from '@/views/HomeView.vue';
import DownloadPageTokenA3 from '@/views/TokenA3/DownloadPageTokenA3.vue';
import Error404WithoutArView from '@/views/Base/Error404WithoutArView.vue';
import InstallationGuideTokenView from '@/views/TokenA3/InstallationGuideTokenView/InstallationGuideTokenView.vue';
import DocumentsUploadView from '@/views/DocumentsUpload/DocumentsUploadView.vue';
import DocumentsUploadPisCeiView from '@/views/DocumentsUpload/DocumentsUploadPisCeiView.vue';
import PendingDocumentsUploadView from '@/views/DocumentsUpload/PendingDocumentsUploadView.vue';
import DocumentsLoginView from '@/views/DocumentsUpload/DocumentsLoginView.vue';
import SuccessUploadFilesView from '@/views/DocumentsUpload/SuccessUploadFilesView.vue';
import InstallationGuideBirdIdView from '@/views/BirdId/InstallationGuideBirdIdView.vue';
import PluginLogin from '@/SignaturePlugin/LoginView.vue';
import pluginEmissionSuccess from '@/SignaturePlugin/A1/DigitalCertificateSuccesEmission.vue';
import DigitalCertificateInstallationGuideVue from '@/SignaturePlugin/A1/DigitalCertificateInstallationGuide.vue';
import LoginView from '@/views/SSLCertificate/Login.vue';
import ReservedDocument from '@/views/ReservedDocument/ReservedDocument.vue';
import routesBird from './BirdIdRoute';
import routesDomainValidation from './domainValidation';
import routesFAQ from './FAQRoute';
import InstallationGuideView from '@/views/SSLCertificate/InstallationGuide.vue';
import CSRAndSignatureHashView from '@/views/SSLCertificate/CSRAndSignatureHash.vue';
import DownloadCertificateCSRView from '@/views/SSLCertificate/DownloadCertificateCSR.vue';
import config from '../../config';
import { useMainStore } from '@/store/Pinia/useMain.store';
import FormSolutiPro from '@/views/BirdId/FormSolutiPro/FormSolutiPro.vue';

async function routeGuard(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
) {
  const arData = useAR();

  const solutiGroup = JSON.parse(JSON.stringify(config.app.partnerNames));
  const isGroup = solutiGroup.includes(arData.arData.subdomain || '');
  if (to.name === 'pendingUploadFiles' && !isGroup) {
    return next('/');
  }

  if (to.params.arsubdomain) {
    const subdomain = to.params.arsubdomain as string;
    await arData.fetchAraData(subdomain);
  }

  if (arData.$state.arData.subdomain === '') {
    return next('/error404');
  }
  return next();
}

const routes: Array<RouteRecordRaw> = [
  ...routesBird,
  ...routesFAQ,
  ...routesDomainValidation,
  {
    path: '',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/:arsubdomain',
    name: 'homeAr',
    component: HomeView,
  },
  {
    path: '/error404',
    name: 'WithoutRoute',
    component: Error404WithoutArView,
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'Error404',
    component: Error404View,
  },
  {
    path: '/tokenA3',
    name: 'downloadA3',
    component: DownloadPageTokenA3,
  },
  {
    path: '/upload-documents',
    name: 'uploadFiles',
    component: DocumentsUploadView,
  },
  {
    path: '/pending-upload-documents',
    name: 'pendingUploadFiles',
    component: PendingDocumentsUploadView,
  },
  {
    path: '/login-documents',
    name: 'uploadLogin',
    component: DocumentsLoginView,
  },
  {
    path: '/upload-documents-pis-cei',
    name: 'uploadFilesPisCei',
    component: DocumentsUploadPisCeiView,
  },
  {
    path: '/installation-guide-birdid',
    name: 'installationBird',
    component: InstallationGuideBirdIdView,
  },
  {
    path: '/:arsubdomain/installation-guide-birdid',
    name: 'installationBirdAr',
    component: InstallationGuideBirdIdView,
  },
  {
    path: '/installation-guide-birdid',
    name: 'installationBird',
    component: InstallationGuideBirdIdView,
  },
  {
    path: '/success-upload-files',
    name: 'SuccessUploadFiles',
    component: SuccessUploadFilesView,
  },
  {
    path: '/installation-guide-token',
    name: 'installationToken',
    component: InstallationGuideTokenView,
  },
  {
    path: '/:arsubdomain/installation-guide-token',
    name: 'installationTokenAr',
    component: InstallationGuideTokenView,
  },
  {
    path: '/issue/plugin',
    name: 'pluginLogin',
    component: PluginLogin,
  },
  {
    path: '/:arsubdomain/issue/plugin',
    name: 'pluginLoginAR',
    component: PluginLogin,
  },
  {
    path: '/issue/plugin/success',
    name: 'pluginEmissionSuccess',
    component: pluginEmissionSuccess,
  },
  {
    path: '/guia-de-instalacao-de-certificado-digital',
    name: 'guiaDeInstalacaoDeCertificadoDigitalAr',
    component: DigitalCertificateInstallationGuideVue,
  },
  {
    path: '/:arsubdomain/guia-de-instalacao-de-certificado-digital',
    name: 'guiaDeInstalacaoDeCertificadoDigital',
    component: DigitalCertificateInstallationGuideVue,
  },
  {
    path: '/reserved-document',
    name: 'ReservedDocument',
    component: ReservedDocument,
  },
  // SSL CERTIFICATE
  {
    path: '/ssl-certificate/login-ssl',
    name: 'LoginSSl',
    component: LoginView,
  },
  {
    path: '/:arsubdomain/ssl-certificate/login-ssl',
    name: 'LoginSSlWithAr',
    component: LoginView,
  },
  {
    path: '/ssl-certificate/installation-guide-ssl',
    name: 'installationGuideSSL',
    component: InstallationGuideView,
  },
  {
    path: '/:arsubdomain/ssl-certificate/installation-guide-ssl',
    name: 'installationGuideSSLAR',
    component: InstallationGuideView,
  },
  {
    path: '/ssl-certificate/csr-signature',
    name: 'CSRAndSignatureHash',
    component: CSRAndSignatureHashView,
  },
  {
    path: '/ssl-certificate/csr-download',
    name: 'DownloadCertificateCSR',
    component: DownloadCertificateCSRView,
  },
  {
    path: '/:arsubdomain/spro-contadores/',
    name: 'FormSolutiPro',
    component: FormSolutiPro,
  },
  {
    path: '/teste',
    name: 'Teste',
    component: FormSolutiPro,
  },
];

const routeWithourGuard = [
  '/',
  '/error404',
  '/:pathMatch(.*)*',
  '/:arsubdomain',
  '/issue/plugin',
  '/issue/plugin/success',
  '/guia-de-instalacao-de-certificado-digital',
  '/:arsubdomain/guia-de-instalacao-de-certificado-digital',
  '/installation-guide-birdid',
  '/ssl-certificate/login-ssl',
];

export const routesWithGuard = routes.map((route: RouteRecordRaw) => {
  if (!routeWithourGuard.includes(route.path)) {
    return {
      ...route,
      beforeEnter: routeGuard,
    };
  }
  return route;
});

export const router = createRouter({
  history: createWebHistory(),
  routes: routesWithGuard,
});

router.beforeEach(() => {
  const main = useMainStore();
  main.fetchEnviroment();
});

export default router;
