import { vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '../../assets/images/aguardando.gif'
import _imports_1 from '../../assets/images/zoom.gif'
import _imports_2 from '../../assets/images/upload.gif'


const _withScopeId = n => (_pushScopeId("data-v-07222a23"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "modalLoading",
  class: "modal modal-lg fade",
  key: "modalGlobal",
  tabindex: "-1",
  "data-bs-keyboard": "false",
  "data-bs-backdrop": "static",
  "aria-labelledby": "modalLoadingStaticBackdropLabel",
  "aria-hidden": "true"
}
const _hoisted_2 = { class: "modal-dialog modal-dialog-centered" }
const _hoisted_3 = { class: "modal-content" }
const _hoisted_4 = { class: "modal-body" }
const _hoisted_5 = {
  src: _imports_0,
  alt: "clock-iamge",
  class: "img-wait"
}
const _hoisted_6 = {
  src: _imports_1,
  alt: "zoom-image",
  class: "img-zoom"
}
const _hoisted_7 = {
  src: _imports_2,
  alt: "fileUpload-image",
  class: "img-wait"
}
const _hoisted_8 = {
  id: "close",
  class: "btn-modal",
  type: "button",
  "data-bs-dismiss": "modal",
  "aria-label": "Close",
  ref: "btnClose"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _withDirectives(_createElementVNode("img", _hoisted_5, null, 512), [
            [_vShow, _ctx.modalLoading.image === 'clock']
          ]),
          _withDirectives(_createElementVNode("img", _hoisted_6, null, 512), [
            [_vShow, _ctx.modalLoading.image === 'zoom']
          ]),
          _withDirectives(_createElementVNode("img", _hoisted_7, null, 512), [
            [_vShow, _ctx.modalLoading.image === 'fileUpload']
          ]),
          _withDirectives(_createElementVNode("h4", { class: "mt-4 text-center" }, _toDisplayString(_ctx.modalLoading.title), 513), [
            [_vShow, _ctx.modalLoading.title !== '']
          ]),
          _withDirectives(_createElementVNode("h6", { class: "text-center" }, _toDisplayString(_ctx.modalLoading.message), 513), [
            [_vShow, _ctx.modalLoading.message !== '']
          ]),
          _createElementVNode("button", _hoisted_8, null, 512)
        ])
      ])
    ])
  ]))
}