import { AbstractHandler } from '../../../domain/ChainOfResponsibility/AbstractHandler';
import { StartChain } from './StartChain';
import { ReturnKnownErrors400 } from './ReturnKnownErrors400';
import { ReturnUnknownErrors } from './ReturnUnknownErrors';
import { ReturnSisArInvalidSolicitationStatus } from './ReturnSisArInvalidSolicitationStatus';
import { ReturnSisArValidSolicitationStatusA3 } from './ReturnSisArValidSolicitationStatusA3';
import { ReturnSisArValidSolicitationStatusA1 } from './ReturnSisArValidSolicitationStatusA1';
import { SetDownloadModalIssuedAt } from './SetDownloadModalIssuedAt';

export default [
  new StartChain(),
  new ReturnKnownErrors400(),
  new ReturnUnknownErrors(),
  new ReturnSisArInvalidSolicitationStatus(),
  new SetDownloadModalIssuedAt(),
  new ReturnSisArValidSolicitationStatusA3(),
  new ReturnSisArValidSolicitationStatusA1(),
] as AbstractHandler[];
