import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "faq-layout" }
const _hoisted_2 = { class: "container-fluid" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "d-none d-md-block col-md-4 col-xxl-3" }
const _hoisted_5 = { id: "sidebar" }
const _hoisted_6 = { class: "col-sm-12 col-md-8 col-xxl-9" }
const _hoisted_7 = { class: "content" }
const _hoisted_8 = { class: "breadcrumb-password-view pt-3 row" }
const _hoisted_9 = { class: "title-installation-guide text-start mt-2 page--title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FAQSidebar = _resolveComponent("FAQSidebar")!
  const _component_Breadcrumb = _resolveComponent("Breadcrumb")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Contact = _resolveComponent("Contact")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_FAQSidebar)
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_Breadcrumb, { pathList: _ctx.breadcrumbPathList }, null, 8, ["pathList"])
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("h2", null, _toDisplayString(_ctx.pageName), 1)
            ]),
            _createVNode(_component_router_view),
            _createVNode(_component_Contact)
          ])
        ])
      ])
    ])
  ]))
}