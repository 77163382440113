
import { defineComponent } from 'vue';
import Icon from '@/components/Atoms/Icon.vue';
import Contact from '@/components/Molecules/Contact.vue';
import { mapState } from 'pinia';
import { useAR, ArDataType } from '@/store/Pinia/useAR.store';

export default defineComponent({
  name: 'tokenA3View',
  components: {
    Icon,
    Contact,
  },
  data: () => ({
    form: {
      arData: {} as ArDataType,
    },
  }),
  computed: {
    ...mapState(useAR, ['arData']),
    routeHome() { return this.form.arData.subdomain ? this.form.arData.subdomain : ''; },
    siteArFormatted() {
      return this.arData.siteAR
        .replace('http://', '')
        .replace('https://', '')
        .replace('/', '');
    },
  },
  mounted() {
    this.form.arData = this.arData;
  },
  beforeMount() {
    setTimeout(() => {
      this.form.arData = { ...this.arData };
    }, 1000);
  },
});
