
import { defineComponent } from 'vue';
import Icon from '@/components/Atoms/Icon.vue';

export default defineComponent({
  name: 'FAQSidebar',
  components: {
    Icon,
  },
  data: () => ({
    menuSSLInstallation: {
      'Gerar requisição (CRS)': 'generate-csr-request',
      'Assinar termo digital': 'sign-digital-term',
      'Instalar Open SSL - Windows': 'install-open-ssl-windows',
      'AWS KMS': 'aws-kms',
    },
    active: true,
  }),
  methods: {
    handleClick(setActive = true) {
      this.active = setActive || !this.isCurrentRoutePluginHelpPage;
    },
  },
  computed: {
    isCurrentRoutePluginHelpPage() {
      return this.$router.currentRoute.value.path.includes('digital-certificate-plugin');
    },
    isActive() {
      return this.active && !this.isCurrentRoutePluginHelpPage;
    },
  },
});
