export default {
  windowsOSSelected: [
    {
      nome: 'Token ePass2003 Windows |10 | 8.1| 8 | 7|',
      link: 'https://site.solutinet.com.br/drivers/Token/ePass2003/Windows/ePass2003%20(Setup).exe',
      type: 'Feithian',
    },
    {
      nome: 'Token ePass2003 Administrador - Windows | 10| 8.1| 8| 7|',
      link: 'https://site.solutinet.com.br/drivers/Token/ePass2003/Windows/ePass2003%20(Admin).exe',
      type: 'Feithian',
    },
    {
      nome: 'SafeNet Authentication Client Versão 10.8 - Windows - 10 | 8.1| 8 | 7 | 64 bits',
      link: 'https://site.solutinet.com.br/drivers/Token/Safenet/Windows/iti-x64-10.8.msi',
      type: 'Safenet',
    },
    {
      nome: 'SafeNet Authentication Client Versão 10.8 - Windows | 10 | 8.1| 8 | 7 | 32 bits',
      link: 'https://drive.google.com/file/d/1Ypr9-kp2FE4rMo3VMDY7JcIkQaWO1uzM/view',
      type: 'Safenet',
    },
    {
      nome: 'SafeNet Authentication Client Versão 9.0 - Windows | 10 | 8.1| 8 | 7 | 32/64 bits',
      link: 'https://site.solutinet.com.br/drivers/Token/Safenet/Windows/SafeNetAuthenticationClient-x32-x64-9.0.exe',
      type: 'Safenet',
    },
    {
      nome: 'SafeNet Authentication Client Versão 5.1 PKI - Windows |10 | 8.1| 8 | 7| 64 bits',
      link: 'https://site.solutinet.com.br/drivers/Token/Safenet/Windows/PKIClient-x64-5.1-SP1.msi',
      type: 'Safenet',
    },
    {
      nome: 'SafeNet Authentication Client Versão 5.1 PKI - Windows | 10 | 8.1| 8 | 7| 32 bits',
      link: 'https://site.solutinet.com.br/drivers/Token/Safenet/Windows/PKIClient-x32-5.1-SP1.msi',
      type: 'Safenet',
    },
    {
      nome: 'DX Safe | Windows',
      link: 'https://publicado.dexon.ind.br/DXSafe/Instaladores/DXSafe_1.x/Instalador%20DXSafe%20Middleware%20-%201.0.21.exe',
      type: 'DXSafe',
    },
    {
      nome: 'Windows 64 Bits (Versão 3.5.3)',
      link: 'https://safesign.gdamericadosul.com.br/content/windows_x64_3.5.3.0_aet.zip',
      type: 'GD',
    },
    {
      nome: 'Windows 32 Bits (Versão 3.5.3)',
      link: 'https://safesign.gdamericadosul.com.br/content/windows_x86_3.5.3.0_aet.zip',
      type: 'GD',
    },
    {
      nome: 'Windows 64 Bits (Versão 3.7.1 (Minidriver))',
      link: 'https://safesign.gdamericadosul.com.br/content/windows_x64_3.7.10.0_aet.zip',
      type: 'GD',
    },
    {
      nome: 'Windows 64 Bits (Versão 3.8 (Minidriver))',
      link: 'https://safesign.gdamericadosul.com.br/content/SafeSign IC MiniDriver 3.8.0.0-AET.000.x64.zip',
      type: 'GD',
    },
  ],
  macOSSelected: [
    {
      nome: 'Token ePass2003 Versão 11.0 à 11.6 - macOS Chipset M1 - Big Sur',
      link: 'https://site.solutinet.com.br/drivers/Token/ePass2003/Mac/ePass2003%20(Macbook%20Air%20M1).dmg',
      type: 'Feithian',
    },
    {
      nome: 'Token ePass2003 Versão 11.0 à 11.2 - macOS - Big Sur',
      link: 'https://site.solutinet.com.br/drivers/Token/ePass2003/Mac/ePass2003-Castle-mac-(11.0%20a%2011.2).dmg',
      type: 'Feithian',
    },
    {
      nome: 'Token ePass2003 Versão 10.15 - macOS - Catalina',
      link: 'https://site.solutinet.com.br/drivers/Token/ePass2003/Mac/ePass2003_10.15%20.dmg',
      type: 'Feithian',
    },
    {
      nome: 'Token ePass2003 Versão 10.14 - macOS - Mojave',
      link: 'https://site.solutinet.com.br/drivers/Token/ePass2003/Mac/ePass2003%20(10.14).dmg',
      type: 'Feithian',
    },
    {
      nome: 'Token ePass2003 Versão 10.6 à 10.13 - macOS - Snow Leopard | Lion| Mountain Lion | Mavericks | Yosemite | El Capitan | Sierra | High Sierra',
      link: 'https://site.solutinet.com.br/drivers/Token/ePass2003/Mac/ePass2003%20(10.6%20a%2010.13).dmg',
      type: 'Feithian',
    },
    {
      nome: 'Token Safenet Authentication Client Versão 11.0 à 11.6 - macOS - Big Sur',
      link: 'https://site.solutinet.com.br/drivers/Token/Safenet/Mac/SafeNetAuthenticationClient%20(11.0%20a%2011.2).dmg',
      type: 'Safenet',
    },
    {
      nome: 'Token Safenet Authentication Client Versão 10.12 à 10.15 - macOS - Sierra | High Sierra | Mojave |Catalina',
      link: 'https://site.solutinet.com.br/drivers/Token/Safenet/Mac/SafeNetAuthenticationClient.10.0.26%20(10.12%20a%2010.15).dmg',
      type: 'Safenet',
    },
    {
      nome: 'Token Safenet Authentication Client Versão 10.9 à 10.11 - macOS - Mavericks | Yosemite | El Capitan',
      link: 'https://site.solutinet.com.br/drivers/Token/Safenet/Mac/SafeNetAuthenticationClient.9.0.43.0%20(10.9%20a%2010.11).dmg',
      type: 'Safenet',
    },
    {
      nome: 'Mac OS 12.5 e 11.6 (Versão 3.8.0)',
      link: 'https://safesign.gdamericadosul.com.br/content/SafeSignMacOS3.8.zip',
      type: 'GD',
    },
    {
      nome: 'Mac OS 10.15.7, 11.6 ou 12.1 (Versão 3.7.1)',
      link: 'https://safesign.gdamericadosul.com.br/content/SafeSign IC Standard MacOS macos 3.7.1.0-AET.000 x86_64.zip',
      type: 'GD',
    },
    {
      nome: 'Mac OS 10.14 (Versão 3.6.1)',
      link: 'https://safesign.gdamericadosul.com.br/content/SafeSignMacOS3.6.1',
      type: 'GD',
    },
    {
      nome: 'Mac OS 10.13 (Versão 3.5.0)',
      link: 'https://safesign.gdamericadosul.com.br/content/350/safesign ic standard 3.5.0.0-aet.000 macta x86_64.app.zip',
      type: 'GD',
    },
  ],
  linuxOSSelected: [
    {
      nome: 'Token ePass2003- Linux',
      link: 'https://site.solutinet.com.br/drivers/Token/ePass2003/Linux/ePass2003_Castle_20141128.tar.gz',
      type: 'Feithian',
    },
    {
      nome: 'SafeNet Authentication Client Versão 9.1 - Linux RMP - Fedora | CentOS | RHEL',
      link: 'https://site.solutinet.com.br/drivers/Token/Safenet/Linux/SafeNet_RPM_9.1.7-0.tar.gz',
      type: 'Safenet',
    },
    {
      nome: 'SafeNet Authentication Client Versão 9.0 - Linux RMP - Fedora | CentOS | RHE',
      link: 'https://site.solutinet.com.br/drivers/Token/Safenet/Linux/SafeNet_RPM_9.0.43.zip',
      type: 'Safenet',
    },
    {
      nome: 'SafeNet Authentication Client Versão 10.7 - Linux Debian - Ubuntu e Mint',
      link: 'https://www.globalsign.com/en/safenet-drivers/USB/10.7/Safenet_Linux_Installer_DEB_x64.zip',
      type: 'Safenet',
    },
    {
      nome: 'SafeNet Authentication Client Versão 9.0 - Linux Debian - Ubuntu e Mint',
      link: 'https://site.solutinet.com.br/drivers/Token/Safenet/Linux/SafeNet_Debian_Ubuntu_9.0.43.zip',
      type: 'Safenet',
    },

    {
      nome: 'Ubuntu 22.04 (Versão 3.8.0)',
      link: 'https://safesign.gdamericadosul.com.br/content/SafeSign_IC_Standard_Linux_ub2204_3.8.0.0_AET.000.zip',
      type: 'GD',
    },
    {
      nome: 'Ubuntu 20.04 (Versão 3.7.0)',
      link: 'https://safesign.gdamericadosul.com.br/content/SafeSign_IC_Standard_Linux_3.7.0.0_AET.000_ub2004_x86_64.rar',
      type: 'GD',
    },
    {
      nome: 'Ubuntu 18.04 (Versão 3.7.0)',
      link: 'https://safesign.gdamericadosul.com.br/content/SafeSign_IC_Standard_Linux_3.7.0.0_AET.000_ub1804_x86_64.rar',
      type: 'GD',
    },
    {
      nome: 'Ubuntu 16.04 LTS (Versão 3.5.0)',
      link: 'https://safesign.gdamericadosul.com.br/content/SafeSignub1604.tar',
      type: 'GD',
    },
    {
      nome: 'Ubuntu 14.04 LTS (Versão 3.5.0)',
      link: 'https://safesign.gdamericadosul.com.br/content/SafeSignub1404.tar',
      type: 'GD',
    },
    {
      nome: 'Cent OS 7 (Versão 3.5.0)',
      link: 'https://safesign.gdamericadosul.com.br/content/centos.rpm',
      type: 'GD',
    },
  ],
};
