<template>
  <div
    id="modal-await"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="modalEmailValidation"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <p class="w-100 text-start fs-5 fw-600 m-0">Sem tempo agora?</p>

           <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          />
        </div>

        <div class="modal-body">
          <div class="mb-5">
            <img src="@/assets/images/waiting-room.svg" alt="waiting-room">
          </div>

          <p class="text-start">
            Sem problemas! Vamos enviar um e-mail com as instruções para você terminar mais tarde!
          </p>
        </div>

        <div class="modal-footer">
          <div class="w-100 d-flex justify-content-between">
            <button
              class="btn btn-grayscale-light"
              type="button"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              Voltar para as instruções
            </button>

            <button
              class="btn btn-primary"
              @click="redirectToLaterPage"
            >
              <span>Avançar</span>
              <Icon name="arrow-right"/>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import Icon from '@/components/Atoms/Icon.vue';
import { mapState } from 'pinia';
import { useSolicitation } from '@/store/Pinia/useSolicitation.store';

export default defineComponent({
  components: {
    Icon,
  },
  computed: {
    ...mapState(useSolicitation, ['getSolicitation']),
  },
  methods: {
    redirectToLaterPage() {
      document.getElementsByClassName('btn-close').item(0)?.click();
      if (!this.getSolicitation.isQsa) {
        return this.$router.push({ path: '/domain-validation/validation-success/feedback-http-dns-later-wo-qsa' });
      }
      return this.$router.push({ path: '/domain-validation/validation-success/feedback-http-dns-later-w-qsa' });
    },
  },
});
</script>
