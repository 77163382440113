
import { defineComponent } from 'vue';
import { mapState } from 'pinia';
import { useRouteBirdId, RoutesType } from '@/store/Pinia/useRouteBirdId.store';

export default defineComponent({
  name: 'navWizzard',
  props: {
    currentPage: {
      type: String,
      default: '/',
    },
  },
  data: () => ({
    routes: [{}],
  }),
  computed: {
    ...mapState(useRouteBirdId, ['getRoutesDone']),
  },
  methods: {
    getColorItem(route: keyof RoutesType) {
      if (route === this.currentPage) {
        return 'active';
      }
      if (this.getRoutesDone[route] || this.currentPage === 'alldone') {
        return 'disabled nav--wizard__nav-item--success';
      }
      return '';
    },
  },
  mounted() {
    const { options } = this.$router;
    const birdIdRoutes = options.routes.find((value) => value.path === '/birdId');
    const formatted = birdIdRoutes?.children || [];
    this.routes = formatted.filter((value) => value.name !== 'checkinfo');
  },
});
