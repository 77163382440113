
import Contact from '@/components/Molecules/Contact.vue';
import { defineComponent } from 'vue';
import browserDeviceCheck from '@/utils/browserDeviceCheck';
import FAQSidebar from '@/components/Organisms/FAQSidebar.vue';
import Breadcrumb from '@/components/Molecules/Breadcrumbs.vue';

export default defineComponent({
  name: 'FAQlayout',
  components: {
    Breadcrumb,
    Contact,
    FAQSidebar,
  },
  data: () => ({
    isMobile: false,
    pageName: '',
    breadcrumbPathList: {},
  }),
  mounted() {
    this.isMobile = browserDeviceCheck();
    this.setbreadcrumbPathList();
  },
  watch: {
    $route() {
      this.setbreadcrumbPathList();
    },
  },
  methods: {
    setbreadcrumbPathList() {
      this.pageName = this.$route.meta.pageName as string;
      this.breadcrumbPathList = { Ajuda: 'faq', [this.pageName]: '#' };
    },
  },
});
