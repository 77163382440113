import { AbstractHandler } from './AbstractHandler';
import { ChainTarget } from '../../domain/ChainOfResponsibility/Handler';
import useEmissionStepsModal from '../../composables/useEmissionStepsModal';
import { useModalControl } from '../../store/pinia/useModalControl.store';
import { wait } from '@/utils/helpers';

export class ReturnCode20 extends AbstractHandler {
  public async handle(target: ChainTarget): Promise<any> {
    const waitingTimeMinutesInMs = 30000;
    let response = target?.response;
    const code = response?.data?.code;

    if (code !== 20) {
      return super.handle(target);
    }

    ReturnCode20.showStepModal();

    await wait(waitingTimeMinutesInMs);

    response = await AbstractHandler.makePluginRequest(target?.makeRequest);
    return this.handle({ ...target, response });
  }

  public static async showStepModal(): Promise<void> {
    const emissionStep = useEmissionStepsModal();

    const modalControlStore = useModalControl();

    emissionStep.setEmissionStep(2);

    await modalControlStore.show({
      modalId: 'emissionStepsModal',
      show: emissionStep.showEmissionStepsModal,
      hide: emissionStep.hideEmissionStepsModal,
    });
  }
}

export default ReturnCode20;
