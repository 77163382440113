import { AbstractHandler as DomainAbstractHandler } from '../../domain/ChainOfResponsibility/AbstractHandler';

export abstract class AbstractHandler extends DomainAbstractHandler {
  protected static MAX_ATTEMPTS = 3;

  protected static firstChainLink?: AbstractHandler;

  public static async makePluginRequest(callPluginApi?: () => any): Promise<any> {
    if (!callPluginApi) {
      return null;
    }

    const response = await callPluginApi()
      ?.then((res: any) => {
        AbstractHandler.MAX_ATTEMPTS = 3;
        return res;
      })
      ?.catch((error: any) => {
        const { status, data } = error?.response || { status: 500, data: null };

        if (AbstractHandler.MAX_ATTEMPTS > 0) {
          AbstractHandler.MAX_ATTEMPTS -= 1;
          return AbstractHandler.makePluginRequest(callPluginApi);
        }

        if (status !== 400 || !data || typeof data?.code !== 'number') {
          return {
            code: 0,
            message: 'Erro ao emitir certificado',
            detail: `Erro não informado, status da requisição: ${status}`,
          };
        }

        return data;
      });

    return response;
  }
}

export default AbstractHandler;
