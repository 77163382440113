
import { defineComponent } from 'vue';
import Icon from '@/components/Atoms/Icon.vue';
import Modal from '@/components/Molecules/Modal.vue';

export default defineComponent({
  components: {
    Icon,
    Modal,
  },
});
