import { defineStore } from 'pinia';
import { RemovableRef, useLocalStorage } from '@vueuse/core';

type SawNewsState = {
  sawNews: RemovableRef<boolean>;
};

const useSawNewsStore = defineStore({
  id: 'sawNewsStore',
  state: () => ({
    sawNews: useLocalStorage('sawNews', false),
  } as SawNewsState),
  actions: {
    setSawNews() {
      this.sawNews = true;
    },
  },
  getters: {
    hasSeenNews(): boolean {
      return this.sawNews;
    },
  },
});

export default useSawNewsStore;
