import Input from '@/components/Atoms/Input.vue';
import Icon from '@/components/Atoms/Icon.vue';
import Wizzard from '@/components/Molecules/Wizzard.vue';
import Breadcrumbs from '@/components/Molecules/Breadcrumbs.vue';
import Contact from '@/components/Molecules/Contact.vue';

export default {
  // ATOMS
  Input,
  Icon,
  // MOLECULES
  Wizzard,
  Breadcrumbs,
  Contact,
};
