

import systemController from '@/infra/base/system.controller';
import { defineComponent } from 'vue';
import config from '../../../config';

type VersionObjectType = {
  tag: string | null,
  buildDate: string | null,
  branch: string | null,
  commit: string | null,
  buildNumber: string | null,
  repo: string | null,
}

interface IModelVersion {
  front: VersionObjectType,
  back: VersionObjectType,
}

export default defineComponent({
  name: 'VersionButtonComponent',
  data: () => ({
    model: {
      front: {
        tag: null,
        buildDate: null,
        branch: null,
        commit: null,
        buildNumber: null,
        repo: null,
      },
      back: {
        tag: null,
        buildDate: null,
        branch: null,
        commit: null,
        buildNumber: null,
        repo: null,
      },
    } as IModelVersion,
  }),
  methods: {
    async getDataVersion() {
      const response: VersionObjectType = await systemController.gerVersion();
      const frontObj = {
        ...config.version,
        buildDate: config.version.date,
        buildNumber: config.version.build,
      };
      const model = { front: { ...frontObj }, back: { ...response } };
      this.model = model;
    },
  },
  mounted() {
    this.getDataVersion();
  },
});
