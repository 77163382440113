
import { defineComponent } from 'vue';
import { Modal } from 'bootstrap';

export default defineComponent({
  name: 'ModalRFBError',
  data: () => ({
    model: {
      cnpj: '',
    },
    modal: null as any,
    error: '',
  }),
  methods: {
    modalOpen() {
      this.modal.show();
    },
    modalClose() {
      this.modal.hide();
    },
  },
  mounted() {
    const modal = document.getElementById('modalRfbError') as HTMLElement;
    this.modal = new Modal(modal, {});
  },
});
