import { RemovableRef, useStorage } from '@vueuse/core';
import { defineStore } from 'pinia';

export type SSLCertificateType = {
  user: string;
  digitaltermSignature: string;
  csr: string;
  pem: string;
  digitaltermHash: string;
}

export type SSLCertificateStoreType = {
  sslCertificate: RemovableRef<SSLCertificateType>;
}

const STORAGE_DEFAULT = {
  user: '',
  digitaltermSignature: '',
  csr: '',
  pem: '',
  digitaltermHash: '',
} as SSLCertificateType;

export const useSSLCertificate = defineStore({
  id: 'sslCertificateStore',
  state: () => ({
    sslCertificate: useStorage('sslCertificate', {
      user: '',
      digitaltermSignature: '',
      csr: '',
      pem: '',
      digitaltermHash: '',
    }),
  } as SSLCertificateStoreType),
  actions: {
    clearDataSSL() {
      this.sslCertificate = STORAGE_DEFAULT;
    },
    setUser(payload: string) {
      this.sslCertificate.user = payload;
    },
    setDigitalTermHash(payload: string) {
      this.sslCertificate.digitaltermHash = payload;
    },
  },
});
