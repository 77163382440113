import ModalWrongCredentials from '../modals/ModalWrongCredentialsSisAr.vue';

import { ref } from 'vue';

const wrongCredentialsModal = ref<InstanceType<typeof ModalWrongCredentials> | null>(null);

export default function useWrongCredentialsModal() {
  function showWrongCredentialsModal() {
    wrongCredentialsModal.value?.show();
  }

  function hideWrongCredentialsModal() {
    wrongCredentialsModal.value?.hide();
  }

  return {
    wrongCredentialsModal,
    showWrongCredentialsModal,
    hideWrongCredentialsModal,
  };
}
