import { RemovableRef, useStorage } from '@vueuse/core';
import { defineStore } from 'pinia';
import { nextTick } from 'vue';

export type ToastGlobalTypes = 'info' | 'warning' | 'danger' | 'success';

export type PayloadType = {
  isShow: boolean;
  message?: string;
  icon?: string;
  type?: ToastGlobalTypes;
};

export type ToastGlobalType = {
  toast: RemovableRef<PayloadType>
}

export const useToastGlobal = defineStore({
  id: 'toastGlobalStore',
  state: () => ({
    toast: useStorage('toast', {
      isShow: false,
      type: 'danger',
      message: 'Error',
    }),
  } as ToastGlobalType),
  actions: {
    async toggleToastGlobal(payload: PayloadType) {
      this.setType(payload.type ?? 'danger');
      await nextTick();
      this.toast = { ...this.toast, ...payload };
    },
    setType(type: ToastGlobalTypes) {
      this.toast.type = type;
    },
  },
});
