import { EmissionApiAbstractHandler } from './EmissionApiAbstractHandler';
import { ChainTarget } from '../../../domain/ChainOfResponsibility/Handler';
import useModalWrongCredentials from '@/SignaturePlugin/A1/composables/useModalWrongCredentials';
import { useModalControl } from '@/SignaturePlugin/A1/store/pinia/useModalControl.store';

export class ReturnKnownErrors400 extends EmissionApiAbstractHandler {
  public async handle(target: ChainTarget): Promise<any> {
    const canHandle = target?.response?.status === 400
      || target?.response?.status === 401
      || target?.response?.status === 404
      || target?.response?.data?.message === '401 - Unauthorized';

    if (!canHandle) {
      return super.handle(target);
    }

    return ReturnKnownErrors400.showWrongCredentialsModal();
  }

  public static async showWrongCredentialsModal(): Promise<void> {
    const modalControlStore = useModalControl();
    const wrongCredentials = useModalWrongCredentials();

    await modalControlStore.show({
      modalId: 'wrongCredentialsModal',
      show: wrongCredentials.showWrongCredentialsModal,
      hide: wrongCredentials.hideWrongCredentialsModal,
    });
  }
}

export default ReturnKnownErrors400;
