
import { defineComponent } from 'vue';
import Icon from '@/components/Atoms/Icon.vue';

export default defineComponent({
  name: 'ModalSuccessfullySent',
  components: {
    Icon,
  },
  methods: {
    redirect() {
      this.$router.push('/');
    },
  },
});
