
import { PropType, defineComponent } from 'vue';
import Icon from '../../../../components/Atoms/Icon.vue';

interface IOptionsSelectable {
  label: string;
  value: string;
  id?: number;
}

export default defineComponent({
  name: 'inputSelect',
  components: {
    Icon,
  },
  props: {
    label: {
      type: String,
      required: false,
    },
    icon: {
      type: String,
      required: false,
    },
    options: {
      type: Array as PropType<IOptionsSelectable[]>,
    },
    isDisabled: {
      type: Boolean,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
    },
    errorLog: {
      type: String,
      default: '',
    },
    modelValue: {
      type: Object,
    },
    labelSelectable: {
      type: String,
    },
  },
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value: any) {
        this.$emit('update:modelValue', value);
      },
    },
    requiredSymbol() {
      return this.$props.isRequired && this.$props.label ? '*' : '';
    },
    getColorIcon() {
      if (this.$props.isDisabled) {
        return 'gray-dark';
      }

      if (this.$props.errorLog !== '') {
        return 'danger';
      }

      return 'primary';
    },
  },
  watch: {
    options() {
      this.value = undefined;
    },
  },
});
