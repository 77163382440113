import { EmissionApiAbstractHandler } from './EmissionApiAbstractHandler';
import { ChainTarget } from '../../../domain/ChainOfResponsibility/Handler';

export class StartChain extends EmissionApiAbstractHandler {
  public async handle(target: ChainTarget): Promise<any> {
    EmissionApiAbstractHandler.firstChainLink = this;

    if (!target?.response) {
      const response = await EmissionApiAbstractHandler.validateStatus(target.payload);
      Object.assign(target, { response });
      return super.handle(target);
    }

    return super.handle(target);
  }
}

export default StartChain;
