import ModalPluginExecutionConfirmation from '../modals/ModalPluginExecutionConfirmation.vue';

import { ref } from 'vue';

const pluginExecutionConfirmation = ref<InstanceType<
  typeof ModalPluginExecutionConfirmation
> | null>(null);

export default function usePluginExecutionConfirmation() {
  function showPluginExecutionConfirmation() {
    pluginExecutionConfirmation.value?.show();
  }

  function hidePluginExecutionConfirmation() {
    pluginExecutionConfirmation.value?.hide();
  }

  return {
    pluginExecutionConfirmation,
    showPluginExecutionConfirmation,
    hidePluginExecutionConfirmation,
  };
}
