import ModalPluginDownloadConfirmationVue from '../modals/ModalPluginDownloadConfirmation.vue';

import { ref } from 'vue';

const pluginDownloadConfirmationModal = ref<InstanceType<
  typeof ModalPluginDownloadConfirmationVue
> | null>(null);

export default function useDownloadCertificateModal() {
  function showPluginDownloadConfirmationModal() {
    pluginDownloadConfirmationModal.value?.show();
  }

  function hidePluginDownloadConfirmationModal() {
    pluginDownloadConfirmationModal.value?.hide();
  }

  return {
    pluginDownloadConfirmationModal,
    showPluginDownloadConfirmationModal,
    hidePluginDownloadConfirmationModal,
  };
}
