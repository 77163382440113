
import { defineComponent } from 'vue';
import Selectable from '@/components/Atoms/Selectable.vue';
import Input from '@/components/Atoms/Input.vue';
import Icon from '@/components/Atoms/Icon.vue';
import { Modal } from 'bootstrap';
import browserDeviceCheck from '@/utils/browserDeviceCheck';
import emailValidationController from '@/infra/DomainValidation/email.controller';
import { mapActions, mapState } from 'pinia';
import { useToastGlobal } from '@/store/Pinia/useToastGlobal.store';
import { useDynamicForms } from '@/store/Pinia/useDynamicForms.store';
import { useSolicitation } from '@/store/Pinia/useSolicitation.store';
import { AxiosError } from 'axios';

interface SelectableOption {
  id: number;
  value: string;
  label: string;
  nome: string;
}

export default defineComponent({
  components: {
    Selectable,
    Input,
    Icon,
  },
  props: {
    selectedDomain: {
      type: String,
      required: true,
    },
    error: {
      type: String,
    },
  },
  data: () => ({
    isMobile: browserDeviceCheck(),
    emailOptions: [] as SelectableOption[],
    selectedEmail: '',
    emailSelectableKey: 1,
    emailWasSended: false,
    secondsToResendCode: 0,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    secondsCountDown: null as any, // TODO: set the right type for the interval
    code: '',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    modal: null as any,
  }),
  computed: {
    ...mapState(useDynamicForms, ['getPassword']),
    ...mapState(useSolicitation, ['getSolicitation']),
    emailIsSelected() {
      if (this.selectedEmail !== '') {
        return true;
      }
      return false;
    },
    canResendCode() {
      if (this.secondsToResendCode === 0) {
        return true;
      }
      return false;
    },
  },
  methods: {
    ...mapActions(useToastGlobal, ['toggleToastGlobal']),
    startSecondsCountDown() {
      clearInterval(this.secondsCountDown);
      this.secondsCountDown = setInterval(() => {
        if (this.secondsToResendCode > 0) {
          this.secondsToResendCode -= 1;
          return;
        }
        clearInterval(this.secondsCountDown);
      }, 1000);
    },
    clearData() {
      this.selectedEmail = '';
      this.secondsToResendCode = 0;
      clearInterval(this.secondsCountDown);
      this.emailWasSended = false;
      this.code = '';
    },
    exitModal() {
      this.clearData();
      this.emailSelectableKey += 1;
    },
    async fetchEmails() {
      const response = await emailValidationController.getEmails({
        codigo: this.getSolicitation.cdSolicitacao,
        senha: this.getPassword,
      });
      const emailList = response.emails.find(
        (emailListOptoin) =>
          // eslint-disable-next-line implicit-arrow-linebreak
          this.selectedDomain.includes(emailListOptoin.domain),
        // eslint-disable-next-line function-paren-newline
      ) as {
        domain: string;
        emails: string[];
      };

      this.emailOptions = emailList.emails.map((email, index) => ({
        id: index,
        label: email,
        value: email,
        nome: email,
      }));
    },
    async sendCodeToEmail() {
      try {
        this.secondsToResendCode = 30;
        this.startSecondsCountDown();
        await emailValidationController.sendCodeToEmail({
          codigo: this.getSolicitation.cdSolicitacao,
          senha: this.getPassword,
          domain: this.selectedDomain,
          email: this.selectedEmail,
        });
      } catch (error) {
        const err = error as AxiosError<{ message: string }>;
        let message = 'Erro ao enviar código para o email';
        if (err.response?.data.message === 'matheuscfrancisco.dev.br already verified') {
          message = `${err.response?.data.message.replace(
            ' already verified',
            '',
          )} já foi validado.`;
        }

        this.toggleToastGlobal({
          isShow: true,
          type: 'danger',
          message,
        });
      } finally {
        this.emailWasSended = true;
      }
    },
    modalOpen() {
      this.modal.show();
    },
    modalClose() {
      this.modal.hide();
    },
    async validateEmail() {
      this.$emit('validate-email', this.code);
    },
  },
  mounted() {
    const modal = document.getElementById('modal-email-validation') as HTMLElement;
    this.modal = new Modal(modal, {});
  },
});
