import { server } from '@/infra/server';

export type ReponseGetDomainsType = {
  status: string,
  message: string,
  domains: string[],
  domainValidationTypes: {
    'dns_validation': string,
    'http_validation': string,
    'email_validation': string,
  },
};

export type ResponseType = {
  status: string,
  message: string,
}

export type DomainsValidationType = {
  domain: string,
  'verified_at': string,
}

export type ResponseDomainsValidationType = {
  domainsValidation: DomainsValidationType[],
} & ResponseType;

export type RequestDomainsValidationType = {
  codigo: string,
  senha: string,
}

export const domainValidationController = {
  getDomains: async (codigo: string, senha: string) => {
    const response = await server.post<ReponseGetDomainsType>('/domain-validation/get-domains-validation', { codigo, senha });
    return response.data;
  },
  getDomainsValidation: async (params: RequestDomainsValidationType) => {
    const response = await server.post<ResponseDomainsValidationType>('/domain-validation/is-valid', { ...params });
    return response.data;
  },
};
