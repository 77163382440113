import { EmissionApiAbstractHandler } from './EmissionApiAbstractHandler';
import useTryAgainModal from '../../../composables/useTryAgainModal';
import { ChainTarget } from '../../../domain/ChainOfResponsibility/Handler';
import { useModalControl } from '@/SignaturePlugin/A1/store/pinia/useModalControl.store';

export class ReturnUnknownErrors extends EmissionApiAbstractHandler {
  public async handle(target: ChainTarget): Promise<any> {
    const canProceed = target?.response?.status >= 200 && target?.response?.status < 300;

    if (canProceed) {
      return super.handle(target);
    }

    return ReturnUnknownErrors.showTryAgainModal();
  }

  public static async showTryAgainModal(): Promise<void> {
    const tryAgainModal = useTryAgainModal();
    const modalControlStore = useModalControl();

    tryAgainModal.setTryAgainModalAction(() => {
      const handle = EmissionApiAbstractHandler.firstChainLink?.handle({ response: null });
      return handle;
    });

    await modalControlStore.show({
      modalId: 'tryAgainModal',
      show: tryAgainModal.showTryAgainModal,
      hide: tryAgainModal.hideTryAgainModal,
    });
  }
}

export default ReturnUnknownErrors;
