import { AbstractHandler } from './AbstractHandler';
import { ChainTarget } from '../../domain/ChainOfResponsibility/Handler';
import useEmissionStepsModal from '../../composables/useEmissionStepsModal';
import { useModalControl } from '../../store/pinia/useModalControl.store';
import { wait } from '@/utils/helpers';

export class StartChain extends AbstractHandler {
  constructor() {
    super();
    AbstractHandler.firstChainLink = this;
  }

  public async handle(target: ChainTarget): Promise<any> {
    let response = target?.response;

    AbstractHandler.MAX_ATTEMPTS = 3;

    await StartChain.showStepModal();

    if (!response) {
      response = await AbstractHandler.makePluginRequest(target?.makeRequest);
    }

    Object.assign(target, { response });

    return super.handle(target);
  }

  public static async showStepModal(): Promise<void> {
    const waitSomeSecondsInMilliseconds = 1200;
    const modalControlStore = useModalControl();
    const emissionStep = useEmissionStepsModal();

    await modalControlStore.show({
      modalId: 'emissionStepsModal',
      show: emissionStep.showEmissionStepsModal,
      hide: emissionStep.hideEmissionStepsModal,
    });

    await wait(waitSomeSecondsInMilliseconds);
  }
}

export default StartChain;
