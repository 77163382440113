function ApplyMaskToCPF(cpf: string) {
  const cpfFormatted = cpf.replace(/\D/g, '');

  const newCpf = cpfFormatted.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, '$1.$2.$3-$4');

  return newCpf;
}

function RemoveMaskCPF(cpf: string) {
  return cpf.replace(/\D/g, '');
}

function applyCnpjMask(cnpj: string): string {
  const cnpjFormatted = cnpj.replace(/\D/g, '');
  return cnpjFormatted.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, '$1.$2.$3/$4-$5');
}

export { ApplyMaskToCPF, RemoveMaskCPF, applyCnpjMask };
