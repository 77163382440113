/* eslint-disable no-template-curly-in-string */
export default {
  app: {
    urlServer: 'https://emitir-api.emissaoonline.com.br/api',
    urlServerPanel: 'https://apiemissaoweb.vaultid.com.br/',
    partnerNames: '["arsoluti", "ardigisec", "arobjectti", "arrp", "arpartner"]',
    skuBirdIdTrial: '2970',
    negociations: '["34333","34334", "34544"]',
    poolServer: '${VUE_APP_POOL_URL_SERVER}',
    vlineUrl: 'https://vline.soluti.com.br/',
  },
  version: {
    commit: '69017c670a13d8d1ed9bf4430a9b9ff2afc7c320',
    branch: '',
    tag: '2.22.1',
    repo: 'online-emission-frontend',
    build: '870',
    date: '2024-08-13T23:16:19.1723590979Z',
  },
};
