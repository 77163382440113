import { wait } from '@/utils/helpers';
import { defineStore } from 'pinia';

export interface PluginModal {
  modalId: string;
  hide: () => void;
  show: () => void;
  [key: string]: any;
}

type State = {
  currentModal: PluginModal;
};

export const useModalControl = defineStore({
  id: 'modalControlStore',
  state: () => ({
    currentModal: {
      modalId: '',
      hide: () => null,
      show: () => null,
    },
  } as State),
  actions: {
    async show(payload: PluginModal) {
      if (this.currentModal.modalId === payload?.modalId) {
        return;
      }

      await wait(500);

      this.currentModal.hide();

      await wait(500);

      this.close();

      this.currentModal = payload;

      this.currentModal.show();
    },
    close() {
      this.currentModal.hide();

      this.currentModal = {
        modalId: '',
        hide: () => null,
        show: () => null,
      };
    },
  },
});

export default useModalControl;
