import ModalSetNewPasswordVue from '../modals/ModalSetNewPassword.vue';

import { ref } from 'vue';

const setNewPasswordModal = ref<InstanceType<typeof ModalSetNewPasswordVue> | null>(null);

export default function useSetNewPasswordModal() {
  function showSetNewPasswordModal() {
    setNewPasswordModal.value?.show();
  }

  function hideSetNewPasswordModal() {
    setNewPasswordModal.value?.hide();
  }

  return {
    setNewPasswordModal,
    showSetNewPasswordModal,
    hideSetNewPasswordModal,
  };
}
