
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Error404WithourArView',
  data: () => ({
    subdomain: '',
    baseUrl: '',
  }),

  mounted() {
    this.baseUrl = window.location.origin.replace('https://', '').replace('http://', '');
    const subdomain = localStorage.getItem('ar-subdomain-error');
    if (subdomain) {
      this.subdomain = subdomain.replace('/', '');
    }
  },
});
