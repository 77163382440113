/* eslint-disable camelcase */
import { server } from '@/infra/server';

type ResponseType = {
  status: string;
  message: string;
};

export type DnsValidationObject = {
  verification_type: string;
  domain: string;
  host: string;
  target: string;
};

type ResponseChoseMethodType = {
  choosedValidationData: [DnsValidationObject];
} & ResponseType;

type RequestChoseMethodType = {
  codigo: string;
  senha: string;
  domain: string;
};

type RequestValidateType = {
  codigo: string;
  senha: string;
  domain: string;
};

type ResponseValidateDomainDNS = ResponseType & {
  domainValidated: {
    verified_at: null | string;
    domain: string;
  };
};

const dnsValidationController = {
  choseMethod: async (params: RequestChoseMethodType) => {
    const response = await server.post<ResponseChoseMethodType>(
      '/domain-validation/dns/choose-method',
      { ...params },
    );

    return response.data;
  },
  validate: async (params: RequestValidateType) => {
    const response = await server.post<ResponseValidateDomainDNS>(
      '/domain-validation/dns/validate',
      {
        ...params,
      },
    );
    return response.data;
  },
};

export default dnsValidationController;
