import { ref } from 'vue';

import ModalPluginCertificatePassword from '../modals/ModalPluginCertificatePassword.vue';

const pluginCertificatePasswordModal = ref<InstanceType<typeof ModalPluginCertificatePassword>
  | null>(null);

export default function usePluginCertificatePasswordModal() {
  function showPluginCertificatePasswordModal() {
    pluginCertificatePasswordModal.value?.show();
  }

  function hidePluginCertificatePasswordModal() {
    pluginCertificatePasswordModal.value?.hide();
  }

  return {
    pluginCertificatePasswordModal,
    showPluginCertificatePasswordModal,
    hidePluginCertificatePasswordModal,
  };
}
