/* eslint-disable max-len */
/* eslint-disable no-useless-escape */
/* eslint-disable arrow-body-style */
/* eslint-disable no-mixed-operators */
/* eslint-disable no-plusplus */
/* eslint-disable radix */

const allValidates = {
  validateEmail: (email: string) => {
    if (String(email).toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
      return true;
    }
    return false;
  },
  validateCPF: (value: string) => {
    let Soma = 0;

    if (value === undefined) {
      return false;
    }

    const strCPF = value.replace('.', '').replace('.', '').replace('-', '');
    if (strCPF === '00000000000' || strCPF === '11111111111' || strCPF === '22222222222' || strCPF === '33333333333'
    || strCPF === '44444444444' || strCPF === '55555555555' || strCPF === '66666666666' || strCPF === '77777777777'
    || strCPF === '88888888888' || strCPF === '99999999999' || strCPF.length !== 11) {
      return false;
    }

    for (let i = 1; i <= 9; i++) {
      Soma += parseInt(strCPF.substring(i - 1, i)) * (11 - i);
    }

    let Resto = (Soma * 10) % 11;
    if ((Resto === 10) || (Resto === 11)) {
      Resto = 0;
    }

    if (Resto !== parseInt(strCPF.substring(9, 10))) {
      return false;
    }

    Soma = 0;
    for (let k = 1; k <= 10; k++) {
      Soma += parseInt(strCPF.substring(k - 1, k)) * (12 - k);
    }

    Resto = (Soma * 10) % 11;
    if ((Resto === 10) || (Resto === 11)) {
      Resto = 0;
    }

    if (Resto !== parseInt(strCPF.substring(10, 11))) {
      return false;
    }

    return true;
  },
  validateCNPJ: (value: string) => {
    // Verifica se a variável cnpj é igua a "undefined", exibindo uma msg de erro
    if (value === undefined) {
      return false;
    }

    const strCNPJ = value.replace('.', '').replace('.', '').replace('/', '').replace('-', '');

    if (strCNPJ === '00000000000000' || strCNPJ === '11111111111111' || strCNPJ === '22222222222222' || strCNPJ === '33333333333333'
      || strCNPJ === '44444444444444' || strCNPJ === '55555555555555' || strCNPJ === '66666666666666' || strCNPJ === '77777777777777'
      || strCNPJ === '88888888888888' || strCNPJ === '99999999999999' || strCNPJ.length !== 14) {
      return false;
    }

    let tamanho = strCNPJ.length - 2;
    let numeros = strCNPJ.substring(0, tamanho);
    const digitos = strCNPJ.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;

    for (let i = tamanho; i >= 1; i--) {
      soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
      if (pos < 2) {
        pos = 9;
      }
    }

    let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado !== parseInt(digitos.charAt(0))) {
      return false;
    }

    tamanho += 1;
    numeros = strCNPJ.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let k = tamanho; k >= 1; k--) {
      soma += parseInt(numeros.charAt(tamanho - k)) * pos--;
      if (pos < 2) {
        pos = 9;
      }
    }

    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado !== parseInt(digitos.charAt(1))) {
      return false;
    }

    return true;
  },
  validatePhone(phone: string) {
    if (phone.length < 11) {
      return false;
    }
    return true;
  },
  domainValidation(text: string): boolean {
    const regex = /^((?!\d+\.)([\p{L}0-9][\p{L}0-9\-]{0,24}[\p{L}0-9])\.)+[\p{L}]{2,}$/u;
    const match = text.match(regex);
    return match !== null;
  },
  validateName(value: string) {
    if (value.match(/^[a-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÒÖÚÇÑ ]+$/i)) {
      return true;
    }
    return false;
  },
  validateOrganizationIdAndSStatementId(value: string) {
    // const REGEX_UUID = '/^[0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{12}$/';
    const REGEX_UUID = '^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$';
    const match = value.match(REGEX_UUID);
    return match !== null;
  },
};

export default allValidates;
