
import { defineComponent } from 'vue';
import { useModalLoading } from '@/store/Pinia/useModalLoading.store';
import { mapActions, mapState } from 'pinia';
import Icon from '@/components/Atoms/Icon.vue';
import Wizzard from '@/components/Molecules/Wizzard.vue';
import Contact from '@/components/Molecules/Contact.vue';
import { useDomainValidation } from '@/store/Pinia/useDomainValidation.store';
import { useDynamicForms } from '@/store/Pinia/useDynamicForms.store';
import { useSolicitation } from '@/store/Pinia/useSolicitation.store';
import { useToastGlobal } from '@/store/Pinia/useToastGlobal.store';
import { Tooltip } from 'bootstrap';
import dnsValidationController from '@/infra/DomainValidation/dns.controller';
import ModalNotPropagated from './ModalNotPropagated.vue';
import ModalSuccessValidationDNS from './ModalSuccessValidationDNS.vue';
import { useAR } from '@/store/Pinia/useAR.store';

export default defineComponent({
  components: {
    Icon,
    Wizzard,
    Contact,
    ModalNotPropagated,
    ModalSuccessValidationDNS,
  },
  data() {
    return {
      copyToClipboardTooltipHost: null as Tooltip | null,
      copyToClipboardTooltipTarget: null as Tooltip | null,
      copyToClipboardTooltipInsert: null as Tooltip | null,
      domain: '',
      host: '',
      target: '',
    };
  },
  computed: {
    ...mapState(useDomainValidation, ['domainDnsValidationData']),
    ...mapState(useDynamicForms, ['getPassword']),
    ...mapState(useSolicitation, ['getSolicitation']),
    ...mapState(useDynamicForms, ['getPassword']),
    ...mapState(useAR, ['getSubdomain']),
    insertValue(): string {
      return `${this.host}.${this.domain}`;
    },
  },
  methods: {
    ...mapActions(useModalLoading, ['setIsLoading']),
    ...mapActions(useToastGlobal, ['toggleToastGlobal']),
    async validateDomain() {
      try {
        this.setIsLoading({
          isLoading: true,
          title: 'Aguarde enquanto validamos seu domínio.',
          message: '',
          image: 'clock',
        });

        await dnsValidationController.validate({
          codigo: this.getSolicitation.cdSolicitacao,
          senha: this.getPassword,
          domain: this.domain,
        });

        setTimeout(() => {
          this.setIsLoading({ isLoading: false });
        }, 1000);

        if (!this.getSolicitation.isQsa) {
          return this.$router.push({
            path: '/domain-validation/validation-success/feedback-http-dns-later-wo-qsa',
          });
        }

        return this.$router.push({
          path: '/domain-validation/validation-success/feedback-http-dns-later-w-qsa',
        });
      } catch (error) {
        setTimeout(() => {
          this.setIsLoading({ isLoading: false });
        }, 1000);

        setTimeout(() => {
          // eslint-disable-next-line max-len
          (this.$refs.modalNotPropagated as InstanceType<typeof ModalNotPropagated>).showModal();
        }, 1000);

        return null;
      }
    },
    async handleCheckSolicitationAndDomain() {
      try {
        this.setIsLoading({
          isLoading: true,
          title: 'Aguarde enquanto estamos buscando sua solicitação.',
          message: '',
          image: 'clock',
        });

        const modalSuccess = this.$refs.ModalSuccessValidationDNS as InstanceType<
          typeof ModalSuccessValidationDNS
        >;

        const modalNotPropagated = this.$refs.modalNotPropagated as InstanceType<
          typeof ModalNotPropagated
        >;

        const response = await dnsValidationController.validate({
          codigo: this.getSolicitation.cdSolicitacao,
          domain: this.domain,
          senha: this.getPassword,
        });

        const isValidDomain = !!response.domainValidated.verified_at;

        if (!isValidDomain) {
          modalNotPropagated.showModal();
          return;
        }

        if (!this.getSolicitation.isQsa && isValidDomain) {
          this.$router.push({
            path: '/domain-validation/validation-success/feedback-http-email-dns-ontime-wo-qsa',
          });
          return;
        }

        if (response.status === 'success' && isValidDomain) {
          modalSuccess.setModalDefault();
          modalSuccess.showModal();
          return;
        }
      } catch (error) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const err = error as any;
        let errorMessage = 'Houve um erro ao tentar buscar os seus dados.';
        if (err.response) {
          if (
            // eslint-disable-next-line operator-linebreak
            (err.response.data.message &&
              // eslint-disable-next-line operator-linebreak
              err.response?.data.message === 'Código e/ou senha incorreto(s).') ||
            err.response?.data.message === 'Solicitação não encontrada.'
          ) {
            errorMessage = err.response?.data.message;
          }
        }

        this.toggleToastGlobal({
          type: 'danger',
          isShow: true,
          message: errorMessage,
        });
      } finally {
        this.setIsLoading({ isLoading: false });
      }
    },
    copyToClipboard(content: string, reference: string) {
      navigator.clipboard.writeText(content);

      if (reference === 'host') {
        return this.copyToClipboardTooltipHost?.setContent({ '.tooltip-inner': 'Código copiado!' });
      }

      if (reference === 'target') {
        return this.copyToClipboardTooltipTarget?.setContent({
          '.tooltip-inner': 'Código copiado!',
        });
      }

      if (reference === 'insert') {
        return this.copyToClipboardTooltipInsert?.setContent({
          '.tooltip-inner': 'Código copiado!',
        });
      }

      return null;
    },
    resetContent(reference: string) {
      if (reference === 'host') {
        return this.copyToClipboardTooltipHost?.setContent({
          '.tooltip-inner': 'Clique para copiar',
        });
      }

      if (reference === 'target') {
        return this.copyToClipboardTooltipTarget?.setContent({
          '.tooltip-inner': 'Clique para copiar',
        });
      }

      if (reference === 'insert') {
        return this.copyToClipboardTooltipTarget?.setContent({
          '.tooltip-inner': 'Clique para copiar',
        });
      }

      return null;
    },
  },
  async mounted() {
    this.copyToClipboardTooltipHost = new Tooltip('#copy-host-icon', {
      title: 'Clique para copiar',
      placement: 'right',
    });

    this.copyToClipboardTooltipTarget = new Tooltip('#copy-target-icon', {
      title: 'Clique para copiar',
      placement: 'right',
    });

    this.copyToClipboardTooltipInsert = new Tooltip('#copy-insert-icon', {
      title: 'Clique para copiar',
      placement: 'right',
    });

    this.domain = this.domainDnsValidationData.domain;
    this.host = this.domainDnsValidationData.host;
    this.target = this.domainDnsValidationData.target;
  },
  beforeRouteEnter(to, from, next) {
    const solicitationStore = useSolicitation();

    if (solicitationStore.getSolicitation.cdSolicitacao === '') {
      next('/');
    }
    next();
  },
});
