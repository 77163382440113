import { ref } from 'vue';

import ModalPluginCertificatePasswordTip from '../modals/ModalPluginCertificatePasswordTip.vue';

const pluginCertificatePasswordTipModal = ref<InstanceType<typeof ModalPluginCertificatePasswordTip>
  | null>(null);

export default function usePluginCertificatePasswordTip() {
  function showPluginCertificatePasswordTip() {
    pluginCertificatePasswordTipModal.value?.show();
  }

  function hidePluginCertificatePasswordTip() {
    pluginCertificatePasswordTipModal.value?.hide();
  }

  return {
    pluginCertificatePasswordTipModal,
    showPluginCertificatePasswordTip,
    hidePluginCertificatePasswordTip,
  };
}
