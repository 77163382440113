import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-899286fc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "toast-container top-0 end-0" }
const _hoisted_2 = { class: "alert__icon" }
const _hoisted_3 = { class: "alert__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      id: "toast-new-message",
      class: _normalizeClass(["toast alert alert-dismissible", [_ctx.typeAlert]])
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_Icon, {
          name: _ctx.toast.icon || "ban"
        }, null, 8, ["name"])
      ]),
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.toast.message), 1),
      _createElementVNode("button", {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleToastGlobal({ isShow: false }))),
        type: "button",
        class: "btn-close",
        "data-bs-dismiss": "toast",
        "aria-label": "Fechar"
      }, [
        _createVNode(_component_Icon, { name: "xmark" })
      ])
    ], 2)
  ]))
}