
import { defineComponent } from 'vue';
import Icon from '@/components/Atoms/Icon.vue';
import Contact from '@/components/Molecules/Contact.vue';
import Wizzard from '@/components/Molecules/Wizzard.vue';
import Selectable from '@/components/Atoms/Selectable.vue';
import Upload from '@/components/Molecules/Upload.vue';
import Camera from '@/components/Molecules/Camera.vue';
import { typesCompany, documentsList } from '@/store/JSON/documentsJson';
import mobileAndTabletCheck from '@/utils/browserDeviceCheck';
import { mapActions, mapState } from 'pinia';
import { useToastGlobal } from '@/store/Pinia/useToastGlobal.store';
import sentFilesController from '@/infra/sisAr/sentFiles.controller';
import { useModalLoading } from '@/store/Pinia/useModalLoading.store';
import FilesLoader from '@/components/Molecules/FilesLoader.vue';
import ModalSuccessfullySentVue from '@/components/templates/ModalSuccessfullySent.vue';
import LoadingVue from '@/components/Atoms/Loading.vue';
import { useDynamicForms } from '@/store/Pinia/useDynamicForms.store';
import { useSolicitation } from '@/store/Pinia/useSolicitation.store';

type DocumentsSegment = 'Sociedade' | 'Empresário Individual' | 'Das demais Entidades de Direito Privado' | 'Das Entidades de Direito Público';

interface IObjectDocument {
  nome: string;
  type: DocumentsSegment,
  list: string[],
  notas: string[],
}

export default defineComponent({
  name: 'DocumentsUpload',
  components: {
    Icon,
    Wizzard,
    Selectable,
    Contact,
    Camera,
    Upload,
    FilesLoader,
    ModalSuccessfullySentVue,
    LoadingVue,
  },
  data: () => ({
    form: {
      types: typesCompany,
      documents: [] as Array<IObjectDocument>,
    },
    companySegmentSeleted: '',
    companySegmentId: 0,
    companyTypeSelected: '',
    companyType: {} as IObjectDocument,
    isMobile: false,
    filesLoaded: [] as Array<File>,
    sentFiles: [] as Array<File>,
    extentionsAllows: ['png', 'jpg', 'jpeg', 'doc', 'docs', 'docx', 'pdf'],
    isLoading: false,
  }),
  watch: {
    companySegmentSeleted(curr: string) {
      this.form.documents = documentsList.filter((x: IObjectDocument) => x.type === curr);
    },
    companyTypeSelected(curr) {
      const company = this.form.documents.find((x: IObjectDocument) => x.nome === curr);
      if (company) {
        this.companyType = company;
      }
    },
  },
  computed: {
    ...mapState(useDynamicForms, ['getPassword']),
    ...mapState(useSolicitation, ['getSolicitation']),
    isInvalid() {
      return this.filesLoaded.length === 0 || this.findDocumentError();
    },
    hasNotes() {
      return this.companyType.notas && this.companyType.notas.length > 0;
    },
    hasSendFiles() {
      return this.filesLoaded.length > 0;
    },
    selectedCompanySegmentLabel() {
      if (this.companySegmentSeleted === 'Das demais Entidades de Direito Privado') {
        return 'Entidade de Direito Privado';
      }

      if (this.companySegmentSeleted === 'Das Entidades de Direito Público') {
        return 'Entidade de Direito Público';
      }

      return this.companySegmentSeleted;
    },
  },
  methods: {
    ...mapActions(useToastGlobal, ['toggleToastGlobal']),
    ...mapActions(useModalLoading, ['setIsLoading']),
    handleAddFile(newFiles: File[]) {
      if (this.filesLoaded.length >= 10) {
        return;
      }
      if (this.filesLoaded.length > 0) {
        this.filesLoaded = [...this.filesLoaded, ...newFiles];
      } else {
        this.filesLoaded = [...newFiles];
      }
    },
    deleteLoadedFile(indexFile: number) {
      this.filesLoaded.splice(indexFile, 1);
    },
    async deleteSentFile(index: number) {
      this.isLoading = true;

      sentFilesController.delete(
        this.getSolicitation.cdSolicitacao,
        this.getPassword,
        this.sentFiles[index].name,
      ).then(() => {
        const filtered = this.sentFiles.filter((x: File, i: number) => i !== index);
        this.sentFiles = filtered;

        this.toggleToastGlobal({
          isShow: true,
          type: 'success',
          icon: 'circle-check',
          message: 'Documento excluído com sucesso!',
        });
      }).finally(() => {
        this.isLoading = false;
      });
    },
    async sendFiles() {
      try {
        this.setIsLoading(
          {
            isLoading: true,
            title: 'Aguarde enquanto enviamos seus documentos',
            message: '',
            image: 'fileUpload',
          },
        );

        await sentFilesController.sendFiles(this.filesLoaded);

        this.toggleToastGlobal({
          isShow: true,
          type: 'success',
          icon: 'circle-check',
          message: 'Documento enviado com sucesso!',
        });

        await this.getSentFiles();
        this.filesLoaded = [];
      } catch (error) {
        this.toggleToastGlobal({
          isShow: true,
          message: 'Houve um erro ao enviar seus documentos, tente novamente mais tarde',
        });
      } finally {
        this.setIsLoading({ isLoading: false });
      }
    },
    findDocumentError() {
      return !!this.filesLoaded.find((x: File) => !this.extentionsAllows.includes(x.type.split('/')[1]));
    },
    async getSentFiles() {
      this.sentFiles = await sentFilesController.list(
        this.getSolicitation.cdSolicitacao,
        this.getPassword,
      );
    },
    handleCompanySegmentChange() {
      this.companyTypeSelected = '';
      this.companyType = {} as IObjectDocument;
      this.companySegmentId += 1;
    },
    exitPage() {
      const dynamicFormsStore = useDynamicForms();
      const solicitationStore = useSolicitation();

      solicitationStore.getSolicitation.cdSolicitacao = '';
      dynamicFormsStore.$state.formData.senha = '';

      this.$router.push({ name: 'uploadLogin' });
    },
  },
  async mounted() {
    this.isMobile = mobileAndTabletCheck();
    await this.getSentFiles();
  },
  beforeRouteEnter(to, from, next) {
    const dynamicFormsStore = useDynamicForms();
    const solicitationStore = useSolicitation();

    if (solicitationStore.getSolicitation.cdSolicitacao !== '' && dynamicFormsStore.getPassword !== '') {
      next();
    } else {
      next({ name: 'uploadLogin' });
    }
  },
});
