/* eslint-disable quote-props */
/* eslint-disable no-promise-executor-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-console */

import axios from 'axios';

const possibleServerIPs = ['local1.local.sign.soluti.com.br', 'local2.local.sign.soluti.com.br'];
const possibleServerPorts = ['4127', '7251', '10241', '20239', '30233'];

let pluginAxiosInstance: any = null;

function checkIfPluginIsInstalled(): boolean {
  return !!pluginAxiosInstance;
}

async function searchForPluginUrl(): Promise<string> {
  let baseURL = '';

  for (const ip of possibleServerIPs) {
    for (const port of possibleServerPorts) {
      try {
        const response = await axios.get(`https://${ip}:${port}/emission/hello`, { timeout: 200 });

        if (response.status === 200) {
          baseURL = `https://${ip}:${port}`;
          return baseURL;
        }
      } catch (error) {
        // console.log('Error connecting to server on IP: ', ip, ' and port: ', port);
      }
    }
  }

  return baseURL;
}

async function createPluginConnection(): Promise<void> {
  pluginAxiosInstance = null;

  const baseURL = await searchForPluginUrl();

  if (baseURL === '') return;

  pluginAxiosInstance = axios.create({
    baseURL,
  });
}

async function makeRequestToPlugin(
  url: string,
  method: any,
  data: any,
  headers: any,
): Promise<any> {
  if (!pluginAxiosInstance) {
    return new Promise((resolve) => resolve({
      headers: {
        get(): string {
          return '';
        },
      } as any,
      status: 503,
      data: {
        error: 'Plugin não disponível.',
        message: '',
      },
    }));
  }

  const response = await pluginAxiosInstance({
    url,
    method,
    data,
    headers,
  });

  return response;
}

export { createPluginConnection, checkIfPluginIsInstalled, makeRequestToPlugin };
