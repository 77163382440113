import { ref } from 'vue';

const triesCount = ref(0);

export default function useError400TriesCount() {
  function incrementTriesCount() {
    triesCount.value += 1;
  }

  function resetTriesCount() {
    triesCount.value = 0;
  }

  return {
    triesCount,
    incrementTriesCount,
    resetTriesCount,
  };
}
