
import Header from '@/components/Organisms/Header.vue';
import Footer from '@/components/Organisms/Footer.vue';
import ModalLoading from '@/components/templates/ModalLoading.vue';
import { defineComponent } from 'vue';
import { Tooltip } from 'bootstrap';
import ToastGlobal from './components/templates/ToastGlobal.vue';

export default defineComponent({
  components: {
    Footer,
    ModalLoading,
    ToastGlobal,
    Header,
  },
  data: () => ({
    hasAr: false,
  }),
  created() {
    setTimeout(() => {
      const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
      [...tooltipTriggerList].map((tooltipTriggerEl) => new Tooltip(tooltipTriggerEl));
    }, 50);
  },
  computed: {
    showMenu() {
      return this.$route.name !== 'WithoutRoute' && this.$route.name !== 'Error404';
    },
  },
  beforeCreate() {
    setTimeout(() => {
      const arData = localStorage.getItem('arData');
      if (arData) {
        this.hasAr = true;
      }
    }, 1300);
  },
});
