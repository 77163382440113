/* eslint-disable max-len */
import ModalDifferentComputerErrorModal from '../modals/ModalDifferentComputerError.vue';

import { ref } from 'vue';

const differentComputerErrorModal = ref<InstanceType<
  typeof ModalDifferentComputerErrorModal
> | null>(null);

export default function useDifferentComputerErrorModal() {
  function showDifferentComputerErrorModal() {
    differentComputerErrorModal.value?.show();
  }

  function hideDifferentComputerErrorModal() {
    differentComputerErrorModal.value?.hide();
  }

  return {
    differentComputerErrorModal,
    showDifferentComputerErrorModal,
    hideDifferentComputerErrorModal,
  };
}
