import config from '@/../config';
import axios from 'axios';

import { AbstractHandler as DomainAbstractHandler } from '../../../domain/ChainOfResponsibility/AbstractHandler';

export type HttpRequestMethod = 'GET' | 'POST';

export interface RequestPayload {
  [key: string]: any;
}

export interface RequestResponse {
  status: number;
  data: {
    [key: string]: any;
  };
  [key: string]: any;
}

export type ValidateStatusParam = RequestPayload & {
  raUrl: string;
  code: string;
  password: string;
};

const sisArProxyServer = axios.create({
  baseURL: `${config.app.urlServer}/`,
});

export abstract class EmissionApiAbstractHandler extends DomainAbstractHandler {
  protected static firstChainLink?: EmissionApiAbstractHandler;

  protected static readonly sisArAprovedStatus = 3;

  protected static readonly sisArEmmitedStatus = 4;

  protected static readonly validSisArSolicitationStatus = [
    this.sisArAprovedStatus,
    this.sisArEmmitedStatus,
  ];

  public static async getArLink(requestId: string): Promise<string> {
    const url = await sisArProxyServer
      .get(`/busca-urls/solicitacao/${requestId}`)
      .then(({ data }) => {
        if (data?.status === 'success') {
          return data?.data?.urls?.shift() || '';
        }

        return '';
      })
      .catch(() => '');

    return url;
  }

  protected static async makeRequest(
    endpoint: string,
    payload: RequestPayload,
    method: HttpRequestMethod = 'POST',
  ): Promise<RequestResponse> {
    const response = await sisArProxyServer
      .post('/sisar-proxy', {
        ...payload,
        method,
        path: endpoint,
      })
      .catch((error) => ({
        status: error.response.status,
        data: {
          ...error?.response,
          status: error.response.status,
          message: 'Não foi possível fazer a solicitação',
        },
      }));

    return {
      status: response.status,
      data: response.data.data,
    };
  }

  public static async validateStatus(payload: ValidateStatusParam): Promise<RequestResponse> {
    const { raUrl, ...params } = payload;

    const arSubdomain = raUrl.replace('http://', '').replace('https://', '').split('.').shift() || '';

    return EmissionApiAbstractHandler.makeRequest('webservice-emission/validate-status', {
      ar_subdomain: arSubdomain,
      params,
    });
  }
}

export default EmissionApiAbstractHandler;
