
import { defineComponent } from 'vue';
import Contact from '@/components/Molecules/Contact.vue';

export default defineComponent({
  name: 'Error404View',
  components: {
    Contact,
  },
});
