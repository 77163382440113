import { ResponseLoginVoucher } from '@/infra/BirdId/controllers/login.controller';
import { FormDataType, useDynamicForms } from '@/store/Pinia/useDynamicForms.store';
import { useRouteBirdId } from '@/store/Pinia/useRouteBirdId.store';
import { StateSolicitation, useSolicitation } from '@/store/Pinia/useSolicitation.store';
import { getMunicipioByIBGECode } from './getMunicipioByNameAndUF';

export default function saveDataVoucher(payload: ResponseLoginVoucher) {
  const solicitationStore = useSolicitation();
  const dynamicFormStore = useDynamicForms();
  const routeBirdIdStore = useRouteBirdId();

  const solicitationModel = {
    isBirdId: payload.isBird,
    voucher: payload.voucher,
    isPj: payload.isPj,
    isSSL: payload.produto.isSSL,
    produto: {
      id: payload.produto.id,
      nome: payload.nomeProduto,
      sku: payload.produto.sku,
      cei: '',
      pis: '',
    },
    solicitaionToken: {
      sessionData: payload.sessionData,
      sessionId: payload.sessionId,
    },
  } as StateSolicitation;

  let formDataModel = {
    cei: '',
    pis: '',
    unidadeId: payload.idunidade || '',
    nome: payload.nome || dynamicFormStore.getFormData.nome,
    cpf: payload.cpf || dynamicFormStore.getFormData.cpf,
    cnpj: dynamicFormStore.getFormData.cnpj || '',
  } as FormDataType;

  if (payload.dataDecrypted) {
    const municipio = getMunicipioByIBGECode(payload.dataDecrypted.municipio);
    formDataModel = {
      ...formDataModel,
      cnpj: payload.dataDecrypted.cnpj,
      municipio: municipio?.nome || '',
      uf: payload.dataDecrypted.uf,
      telefone: payload.dataDecrypted.telefone,
      email: payload.dataDecrypted.email,
      razao_social: payload.dataDecrypted.razao_social,
    };
  }

  if (payload.cei !== '000000000000' && payload.cei !== '') {
    solicitationModel.produto.cei = payload.cei;
  }
  if (payload.pis !== '00000000000' && payload.pis !== '') {
    solicitationModel.produto.pis = payload.pis;
  }

  solicitationStore.saveDataSolicitation(solicitationModel);
  dynamicFormStore.setMunicipios(payload.municipios);
  dynamicFormStore.setFormData(formDataModel);
  routeBirdIdStore.setRouterDone('voucher');
}
