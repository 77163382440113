import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-camera mb-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", {
      class: _normalizeClass(["btn", _ctx.classButton]),
      for: "cameraComponent"
    }, [
      _createElementVNode("span", null, _toDisplayString(_ctx.labelButton), 1),
      _createVNode(_component_Icon, {
        class: "ms-2",
        name: "camera"
      })
    ], 2),
    _createElementVNode("input", {
      class: "d-none",
      type: "file",
      id: "cameraComponent",
      name: "image",
      accept: "image/*",
      capture: "user",
      onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('addPhoto', $event.target.files)))
    }, null, 32)
  ]))
}