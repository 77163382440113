
import { defineComponent } from 'vue';
import Icon from '@/components/Atoms/Icon.vue';
import Selectable from '@/components/Atoms/Selectable.vue';
import drivesTokenJson from '@/store/JSON/drivesTokenJson';
import { mapState } from 'pinia';
import { useAR } from '@/store/Pinia/useAR.store';

interface IObjectDrive {
  nome: string;
  link: string;
  type: string;
}

export default defineComponent({
  name: 'ModalTokenComponent',
  components: {
    Icon,
    Selectable,
  },
  data: () => ({
    stepModal: 1,
    tokenChoice: 'Feithian',
    operationalSystem: '',
    // eslint-disable-next-line global-require
    windowsOS: require('@/assets/images/windows-black.svg'),
    // eslint-disable-next-line global-require
    windowsOSSelected: require('@/assets/images/windows-blue.svg'),
    // eslint-disable-next-line global-require
    macOS: require('@/assets/images/mac-black.svg'),
    // eslint-disable-next-line global-require
    macOSSelected: require('@/assets/images/mac-blue.svg'),
    // eslint-disable-next-line global-require
    linuxOS: require('@/assets/images/linux-black-eo.svg'),
    // eslint-disable-next-line global-require
    linuxOSSelected: require('@/assets/images/linux-blue.svg'),
    driverChoice: '',
    selectableCount: 0,
    link: '',
    drivers: [],
  }),
  methods: {
    downloadDriver() {
      if (this.stepModal === 2) {
        window.open(this.link);
      }
      this.stepModal += 1;
    },
  },
  computed: {
    ...mapState(useAR, ['getLinkEmissor']),
    getColorButton() {
      if (this.stepModal === 1) {
        return 'btn-primary';
      }
      if (this.driverChoice === '' && this.stepModal === 2) {
        return 'btn-secondary';
      }
      return 'btn-primary';
    },
    isFeithian() {
      return this.tokenChoice === 'Feithian';
    },
    isSafenet() {
      return this.tokenChoice === 'Safenet';
    },
    isDXSafe() {
      return this.tokenChoice === 'DXSafe';
    },
    windowsChecked() {
      if (this.operationalSystem === 'windowsOSSelected') {
        return this.windowsOSSelected;
      }
      return this.windowsOS;
    },
    macChecked() {
      if (this.operationalSystem === 'macOSSelected') {
        return this.macOSSelected;
      }
      return this.macOS;
    },
    linuxChecked() {
      if (this.operationalSystem === 'linuxOSSelected') {
        return this.linuxOSSelected;
      }
      return this.linuxOS;
    },
    hasDriversAndNoHasOS() {
      return this.drivers.length === 0 && this.operationalSystem !== '';
    },
  },
  watch: {
    stepModal(curr: number) {
      if (curr === 1) {
        this.operationalSystem = '';
        this.drivers = [];
      }
    },
    operationalSystem(current: 'linuxOSSelected' | 'macOSSelected' | 'windowsOSSelected') {
      if (current) {
        const drivers = drivesTokenJson[current];
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.drivers = drivers.filter((x) => x.type === this.tokenChoice) as any;
        this.selectableCount += 1;
        this.driverChoice = '';
      }
    },
    driverChoice(current) {
      const objectDrive = this.drivers.find((x: IObjectDrive) => x.nome === current) as
        | IObjectDrive
        | undefined;
      if (objectDrive) {
        this.link = objectDrive.link;
      }
    },
  },
});
