import { useStorage } from '@vueuse/core';
import { defineStore } from 'pinia';

export type domainHttpValidationDataType = {
  'check_endpoint': string,
  domain: string,
  'file_content': string,
  'file_name': string,
  links: string[],
  'verification_type': string,
}

export type domainDnsValidationDataType = {
  host: string,
  target: string,
  domain: string,
}

export const useDomainValidation = defineStore({
  id: 'domainValidationStore',
  state: () => ({
    domainHttpValidationData: useStorage('domainHttpValidationData', {
      check_endpoint: '',
      domain: '',
      file_content: '',
      file_name: '',
      links: Array<string>(),
      verification_type: '',
    }),
    domainDnsValidationData: useStorage('domainDnsValidationData', {
      host: '',
      target: '',
      domain: '',
    }),
    typeDomainValidation: useStorage('typeDomainValidation', {
      type: 'http',
    }),
  }),
  getters: {
    getType(): string {
      return this.typeDomainValidation.type;
    },
  },
  actions: {
    setType(payload: string) {
      this.typeDomainValidation.type = payload;
    },
    setDomainHttpValidationData(data: domainHttpValidationDataType) {
      this.domainHttpValidationData.check_endpoint = data.check_endpoint;
      this.domainHttpValidationData.domain = data.domain;
      this.domainHttpValidationData.file_content = data.file_content;
      this.domainHttpValidationData.file_name = data.file_name;
      this.domainHttpValidationData.links = data.links;
      this.domainHttpValidationData.verification_type = data.verification_type;
    },
    clearDomainHttpValidationData() {
      this.domainHttpValidationData.check_endpoint = '';
      this.domainHttpValidationData.domain = '';
      this.domainHttpValidationData.file_content = '';
      this.domainHttpValidationData.file_name = '';
      this.domainHttpValidationData.links = Array<string>();
      this.domainHttpValidationData.verification_type = '';
    },
    setDomainDnsValidationData(data: domainDnsValidationDataType) {
      // eslint-disable-next-line prefer-destructuring
      this.domainDnsValidationData.host = data.host.split('.')[0];
      // eslint-disable-next-line prefer-destructuring
      this.domainDnsValidationData.target = data.target;
      this.domainDnsValidationData.domain = data.domain;
    },
    clearDomainDnsValidationData() {
      this.domainDnsValidationData.host = '';
      this.domainDnsValidationData.target = '';
      this.domainDnsValidationData.domain = '';
    },
  },
});
