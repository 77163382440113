
import { defineComponent } from 'vue';
import Icon from '@/components/Atoms/Icon.vue';
import { mapState } from 'pinia';
import { useAR, ArDataType } from '@/store/Pinia/useAR.store';

export default defineComponent({
  name: 'ModalDownloadInstruction',
  components: {
    Icon,
  },
  data: () => ({
    form: {
      arData: {} as ArDataType,
    },
  }),
  computed: {
    ...mapState(useAR, ['arData']),
    issuerUrl() {
      return `https://${this.form.arData.subdomain}.acsoluti.com.br/renovacao/jnlp`;
    },
  },
  methods: {
    download() {
      (document.getElementById('dowload-link') as HTMLAnchorElement).click();
    },
  },
  mounted() {
    this.form.arData = this.arData;
  },
});
