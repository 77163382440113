
import { defineComponent } from 'vue';
import Contact from '@/components/Molecules/Contact.vue';
import Icon from '@/components/Atoms/Icon.vue';
import TextArea from '@/components/Atoms/TextArea.vue';
import Breadcrumb from '@/components/Molecules/Breadcrumbs.vue';
import ModalOnlyUseOnDesktop from '@/components/templates/ModalOnlyUseOnDesktop.vue';
import browserDeviceCheck from '@/utils/browserDeviceCheck';
import { Tooltip } from 'bootstrap';
import { useSSLCertificate } from '@/store/Pinia/useSSLCertificate.store';
import { useMainStore } from '@/store/Pinia/useMain.store';
import { useDynamicForms } from '@/store/Pinia/useDynamicForms.store';
import { mapActions, mapState } from 'pinia';
import { panelController } from '@/infra/SSLCertificate/panel.controller';

export default defineComponent({
  name: 'HomeView',
  components: {
    Contact,
    Icon,
    TextArea,
    Breadcrumb,
    ModalOnlyUseOnDesktop,
  },
  data: () => ({
    copyTooltip: null as Tooltip | null,
    isMobile: browserDeviceCheck(),
    form: {
      user: '',
      csr: '',
    },
  }),
  computed: {
    ...mapState(useSSLCertificate, ['sslCertificate']),
    ...mapState(useDynamicForms, ['getPassword']),
    pathlist() {
      return {
        'Guia de instalação SSL': 'ssl-certificate/installation-guide-ssl',
        'Downolad de Certificado Digital com CSR': '',
      };
    },
  },
  methods: {
    ...mapActions(useMainStore, ['clearData']),
    ...mapActions(useSSLCertificate, ['clearDataSSL']),
    async handleCopy() {
      await navigator.clipboard.writeText(this.form.csr);
    },
    handleDownload() {
      const csrBlob = new Blob([this.form.csr], { type: 'text/plain' });

      const csrUrl = URL.createObjectURL(csrBlob);

      const link = document.createElement('a');
      link.href = csrUrl;
      link.download = `${this.form.user}.pem`;

      link.click();
    },
  },
  async mounted() {
    const response = await panelController.recover({
      solicitacao: this.sslCertificate.user,
      senha: this.getPassword,
    });

    if (response.mensagem.status === 'sucesso') {
      this.clearData();
    }

    this.form.csr = response.mensagem.data;

    this.copyTooltip = new Tooltip(document.querySelector('.copy-button') as HTMLElement, {
      title: 'Certificado copiado!',
      placement: 'top',
      trigger: 'focus',
      delay: { show: 100, hide: 100 },
    });

    this.form.user = this.sslCertificate.user || '';
  },
  async beforeRouteEnter(to, from, next) {
    const sslStore = useSSLCertificate();
    const dynamicFormsStore = useDynamicForms();

    const { user } = sslStore.$state.sslCertificate;
    const { senha } = dynamicFormsStore.$state.formData;

    if (user === '' && senha === '') {
      next('/ssl-certificate/login-ssl');
    }

    next();
  },
});
