/* eslint-disable max-len */
import ModalTryAgainVue from '../modals/ModalTryAgain.vue';

import { ref } from 'vue';

const tryAgainModal = ref<InstanceType<typeof ModalTryAgainVue> | null>(null);

export default function useTryAgainModal() {
  function showTryAgainModal() {
    tryAgainModal.value?.show();
  }

  function hideTryAgainModal() {
    tryAgainModal.value?.hide();
  }

  function setTryAgainModalAction(tryAgainCallback: () => any) {
    tryAgainModal.value?.setAction(tryAgainCallback);
  }

  return {
    tryAgainModal,
    showTryAgainModal,
    hideTryAgainModal,
    setTryAgainModalAction,
  };
}
