
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'LoadingComponent',
  props: {
    isLoading: {
      type: Boolean,
    },
  },
});
