import arController from '@/infra/BirdId/controllers/ar.controller';
import { RemovableRef, useStorage } from '@vueuse/core';
import { defineStore } from 'pinia';

export type ArDataType = {
  subdomain?: string;
  nomeAr: string;
  logo: string;
  siteAR: string;
  foneAR: string;
  emissorLink: string;
};

type State = {
  arData: RemovableRef<ArDataType>;
};

export const useAR = defineStore({
  id: 'arStore',
  state: () =>
    // eslint-disable-next-line implicit-arrow-linebreak
    ({
      arData: useStorage('arData', {
        subdomain: '',
        nomeAr: '',
        logo: '',
        foneAR: '',
        siteAR: '',
        emissorLink: '',
      }),
    } as State),
  actions: {
    async fetchAraData(arsubdomain: string) {
      const arData = await arController.getArData(arsubdomain);
      this.arData = { ...arData.data, subdomain: arsubdomain };
    },
    setArData(payload: ArDataType) {
      this.arData = payload;
    },
  },
  getters: {
    getSubdomain(): string {
      return this.arData.subdomain || '';
    },
    getLinkEmissor(): string {
      if (this.arData.emissorLink) return this.arData.emissorLink;
      return 'https://emissor.ca.inf.br/prod/Emissor.jnlp';
    },
    getSiteArFormatted(): string {
      return this.arData.siteAR
        ? this.arData.siteAR.replace('http://', '').replace('https://', '').replace('/', '')
        : '';
    },
  },
});
