
import { defineComponent } from 'vue';

import loginController, {
  ResponseGetProductsByVoucher,
  VoucherParam,
} from '@/infra/BirdId/controllers/login.controller';
import { mapActions, mapState } from 'pinia';
import { useMainStore } from '@/store/Pinia/useMain.store';
import { useSolicitation } from '@/store/Pinia/useSolicitation.store';
import { useAR } from '@/store/Pinia/useAR.store';
import { useModalLoading } from '@/store/Pinia/useModalLoading.store';
import { useDynamicForms } from '@/store/Pinia/useDynamicForms.store';
import { useRouteBirdId } from '@/store/Pinia/useRouteBirdId.store';
import saveDataVoucher from '@/utils/saveDataVoucher';
import ModalRFB from '@/components/templates/ModalRFB.vue';
import ModalRFBError from '@/components/templates/ModalRFBError.vue';

export default defineComponent({
  name: 'VoucherView',
  data: () => ({
    voucher: '',
    error: '',
    model: {
      token: {
        sessionData: '',
        sessionId: '',
      },
      arsubdomain: '',
      voucher: '',
      cnpj: '',
      produtos: [],
      hasRfb: false,
    } as VoucherParam,
  }),
  watch: {
    voucher(curr) {
      if (curr.length > 0 && this.error) {
        this.error = '';
      }
    },
  },
  components: {
    ModalRFB,
    ModalRFBError,
  },
  errorReq: '',
  computed: {
    ...mapState(useMainStore, ['unidade']),
    ...mapState(useSolicitation, ['getCessToken']),
    ...mapState(useAR, ['arData']),
    isValid() {
      return !!this.voucher && !this.error;
    },
  },
  methods: {
    ...mapActions(useDynamicForms, ['setMunicipios', 'setFormData']),
    ...mapActions(useSolicitation, ['saveDataSolicitation', 'setIsRFB']),
    ...mapActions(useModalLoading, ['setIsLoading']),
    ...mapActions(useRouteBirdId, ['setRouterDone']),
    validateHasProductRFB(responseRFB: ResponseGetProductsByVoucher) {
      return responseRFB.consultarCnpjRfbOuQsa;
    },
    async handleSave() {
      try {
        this.setIsLoading({
          isLoading: true,
          title: 'Aguarde enquanto localizamos seu Voucher/Pedido',
          image: 'zoom',
          message: '',
        });

        this.model = {
          token: this.getCessToken,
          arsubdomain: this.arData.subdomain || '',
          voucher: this.voucher,
        };

        if (this.unidade) {
          this.model.unidade = this.unidade;
        }

        const responseRFB = await loginController.getProductsByVoucher({
          arsubdomain: this.arData.subdomain || '',
          cessToken: this.getCessToken,
          voucher: this.voucher,
        });

        const hasProductRFB = this.validateHasProductRFB(responseRFB);
        this.setIsRFB(hasProductRFB || false);

        if (hasProductRFB) {
          this.model = {
            ...this.model,
            produtos: responseRFB.produtos || [],
            hasRfb: hasProductRFB,
          };
          const modal = this.$refs.modalRfbRef as typeof ModalRFB;
          modal.modalOpen();
          setTimeout(() => {
            this.setIsLoading({ isLoading: false });
          }, 100);
          return;
        }

        await this.createSolicitation(this.model);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        if (error.response && error.response?.data?.message === 'Voucher já utilizado') {
          this.error = 'Voucher utilizado em outro Certificado';
        } else {
          this.error = 'Voucher ou Pedido inválido.';
        }
      } finally {
        setTimeout(() => {
          this.setIsLoading({ isLoading: false });
        }, 1500);
      }
    },
    async createSolicitation(request: VoucherParam) {
      try {
        const modal = this.$refs.modalRfbRef as typeof ModalRFB;
        modal.modalClose();

        const { data } = await loginController.validateVoucher(request);

        const payload = {
          ...data,
          voucher: this.voucher,
        };

        saveDataVoucher(payload);
        this.$router.push('/birdId/form');
      } catch (error: any) {
        if (error.response && error.response?.data?.message === 'Consulta RFB falhou') {
          this.error = 'Consulta RFB falhou';
          this.openModalRFBError();
          return;
        }
        if (error.response && error.response?.data?.message === 'Voucher já utilizado') {
          this.error = 'Voucher utilizado em outro Certificado';
          return;
        }
        this.error = 'Voucher ou Pedido inválido.';
      } finally {
        setTimeout(() => {
          this.setIsLoading({ isLoading: false });
        }, 1500);
      }
    },
    openModalRFB() {
      const modal = this.$refs.modalRfbRef as typeof ModalRFB;
      modal.modalOpen();
    },
    openModalRFBError() {
      const modalerror = this.$refs.modalRfbRefError as typeof ModalRFBError;
      modalerror.modalOpen();
    },
  },
  beforeRouteEnter(to, from, next) {
    const solicitation = useSolicitation();
    if (!solicitation.getCessToken.sessionData && solicitation.getCessToken.sessionId) {
      next({ path: '/' });
    }
    next();
  },
});
