
import { defineComponent } from 'vue';
import Icon from '@/components/Atoms/Icon.vue';
import Contact from '@/components/Molecules/Contact.vue';
import Breadcrumbs from '@/components/Molecules/Breadcrumbs.vue';
import Modal from '@/components/Molecules/Modal.vue';
import mobileAndTabletCheck from '@/utils/browserDeviceCheck';
import { mapActions, mapState } from 'pinia';
import { useSolicitation } from '@/store/Pinia/useSolicitation.store';
import { useToastGlobal } from '@/store/Pinia/useToastGlobal.store';

export default defineComponent({
  name: 'InstallationGuideBirdIdView',
  components: {
    Icon,
    Breadcrumbs,
    Contact,
    Modal,
  },
  data: () => ({
    form: {
      showAlert: false,
    },
    isMobile: false,
    pageImg: false,
    desktopImg: false,
    linkTo: '',
  }),
  computed: {
    ...mapState(useSolicitation, ['getSolicitation']),
  },
  methods: {
    ...mapActions(useToastGlobal, ['toggleToastGlobal']),
    actionButton(link: string) {
      if (!this.isMobile) {
        window.open(link, '_blank');
      }
    },
    startAnimation(imgName: string) {
      if (imgName === 'page') {
        this.pageImg = true;
        return;
      }
      this.desktopImg = true;
    },
    stopAnimation(imgName: string) {
      if (imgName === 'page') {
        this.pageImg = false;
        return;
      }
      this.desktopImg = false;
    },
    showModal() {
      if (!this.isMobile) {
        window.open('https://birdid.com.br/suporte.html', '_blank');
      }
    },
    async sendLink(link: string) {
      try {
        const shareData = {
          url: link,
        };
        await navigator.share(shareData);
      } catch (error) {
        this.toggleToastGlobal({
          isShow: true,
          type: 'info',
          message: 'O link não foi enviado,',
        });
      }
    },
  },
  mounted() {
    this.isMobile = mobileAndTabletCheck();
    if (this.isMobile) {
      const modal = document.getElementById('button-download') as HTMLElement;
      modal.setAttribute('data-bs-toggle', 'modal');
      modal.setAttribute('data-bs-target', '#modalSentDesktop');
    }
  },
});
