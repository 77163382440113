import { server } from '@/infra/server';

const systemController = {
  gerVersion: async () => {
    const { data: response } = await server.get('version');
    return response;
  },
};

export default systemController;
