import { EmissionApiAbstractHandler } from './EmissionApiAbstractHandler';
import { ChainTarget } from '../../../domain/ChainOfResponsibility/Handler';
import { checkIfPluginIsInstalled } from '../../pluginHttps';

import usePluginDownloadModal from '../../../composables/usePluginDownloadModal';
import { useModalControl } from '../../../store/pinia/useModalControl.store';
import useEmissionStepsModal from '@/SignaturePlugin/A1/composables/useEmissionStepsModal';

export class ReturnSisArValidSolicitationStatusA1 extends EmissionApiAbstractHandler {
  public async handle(target: ChainTarget): Promise<any> {
    const certificateType = target?.response?.data?.product?.iti_profile;

    EmissionApiAbstractHandler.firstChainLink = this;

    if (certificateType !== 'A1') {
      return super.handle(target);
    }

    if (!checkIfPluginIsInstalled()) {
      await ReturnSisArValidSolicitationStatusA1.showPluginDownloadModal();
      return null;
    }

    ReturnSisArValidSolicitationStatusA1.setStepModal();

    await target?.callback();

    return super.handle(target);
  }

  public static async showPluginDownloadModal(): Promise<void> {
    const { showPluginDownloadModal, hidePluginDownloadModal } = usePluginDownloadModal();
    const modalControlStore = useModalControl();

    await modalControlStore.show({
      modalId: 'pluginDownloadModal',
      show: showPluginDownloadModal,
      hide: hidePluginDownloadModal,
    });
  }

  public static async setStepModal(): Promise<void> {
    const { setEmissionStep } = useEmissionStepsModal();
    setEmissionStep(1);
  }
}

export default ReturnSisArValidSolicitationStatusA1;
