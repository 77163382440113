
import { defineComponent } from 'vue';
import Icon from '@/components/Atoms/Icon.vue';
import Contact from '@/components/Molecules/Contact.vue';
import Upload from '@/components/Molecules/Upload.vue';
import { typesCompany } from '@/store/JSON/documentsJson';
import mobileAndTabletCheck from '@/utils/browserDeviceCheck';
import { mapActions, mapState } from 'pinia';
import { useToastGlobal } from '@/store/Pinia/useToastGlobal.store';
import sentFilesController from '@/infra/sisAr/sentFiles.controller';
import { useSolicitation } from '@/store/Pinia/useSolicitation.store';
import { useModalLoading } from '@/store/Pinia/useModalLoading.store';
import FilesLoader from '@/components/Molecules/FilesLoader.vue';
import { useDynamicForms } from '@/store/Pinia/useDynamicForms.store';

interface INotesPF {
  title: string;
  description: string;
}

interface IObjectDocument {
  key: string;
  title: string;
  notes: INotesPF[];
}

const ChangesPFType = {
  CEI: 'includeCEI',
  PIS: 'includePIS',
};

const documentsListPF = [
  {
    key: ChangesPFType.CEI,
    title: 'Inclusão de CEI',
    notes: [
      {
        title: 'CAEPF, CNO e CEI',
        // eslint-disable-next-line
        description: `Espelho do respectivo documento. Link: <a target="_blank" href="http://ceiweb.receita.fazenda.gov.br/ceiweb/modificarSenhaIdentificador.view">http://ceiweb.receita.fazenda.gov.br/ceiweb/modificarSenhaIdentificador.view</a>`,
      },
      {
        title: 'CEI com Espólio',
        description: 'Espelho do CEI PF com Espólio.',
      },
    ],
  },
  {
    key: ChangesPFType.PIS,
    title: 'Inclusão de PIS',
    notes: [
      {
        title: 'PIS',
        // eslint-disable-next-line
        description: `Espelho do PIS e CTPS (modelo atual). Link: <a target="_blank" href="https://consultacadastral.inss.gov.br/Esocial/pages/qualificacao/qualificar.xhtml">https://consultacadastral.inss.gov.br/Esocial/pages/qualificacao/qualificar.xhtml</a>`,
      },
    ],
  },
] as IObjectDocument[];

export default defineComponent({
  name: 'DocumentsUpload',
  components: {
    Icon,
    Contact,
    Upload,
    FilesLoader,
  },
  data: () => ({
    form: {
      types: typesCompany,
      documents: [] as Array<IObjectDocument>,
    },
    isMobile: false,
    filesLoaded: [] as Array<File>,
    extensionsAllows: ['png', 'jpg', 'jpeg', 'doc', 'docs', 'docx', 'pdf'],
    changesPF: [] as Array<string>,
  }),
  computed: {
    ...mapState(useSolicitation, ['getSolicitation']),
    ...mapState(useDynamicForms, ['formData']),
    isInvalid() {
      return this.filesLoaded.length === 0 || this.findDocumentError();
    },
    filteredDocsPf() {
      return documentsListPF.filter((docNote) => this.changesPF.includes(docNote.key));
    },
  },
  methods: {
    ...mapActions(useToastGlobal, ['toggleToastGlobal']),
    ...mapActions(useModalLoading, ['setIsLoading']),
    handleAddFile(newFiles: File[]) {
      if (this.filesLoaded.length >= 10) {
        return;
      }
      if (this.filesLoaded.length > 0) {
        this.filesLoaded = [...this.filesLoaded, ...newFiles];
      } else {
        this.filesLoaded = [...newFiles];
      }
    },
    handleDeleteFile(indexFile: number) {
      const filtered = this.filesLoaded.filter((x: File, i: number) => i !== indexFile);
      this.filesLoaded = filtered;
    },
    async sendFiles() {
      try {
        this.setIsLoading({
          isLoading: true,
          title: 'Aguarde enquanto enviamos seus documentos',
          message: '',
          image: 'fileUpload',
        });

        await sentFilesController.sendFiles(this.filesLoaded);

        this.$router.push('/success-upload-files');
      } catch (error) {
        this.toggleToastGlobal({
          isShow: true,
          message: 'Houve um erro ao enviar seus documentos, tente novamente mais tarde',
        });
        this.setIsLoading({ isLoading: false });
      } finally {
        this.setIsLoading({ isLoading: false });
      }
    },
    findDocumentError() {
      return !!this.filesLoaded.find(
        (x: File) => !this.extensionsAllows.includes(x.type.split('/')[1]),
      );
    },
  },
  mounted() {
    this.isMobile = mobileAndTabletCheck();
    if (this.formData.cei !== '') {
      this.changesPF.push(ChangesPFType.CEI);
    }
    if (this.formData.pis !== '') {
      this.changesPF.push(ChangesPFType.PIS);
    }
  },
  beforeRouteEnter(to, from, next) {
    const solicitationStore = useSolicitation();

    if (!solicitationStore.getSolicitation.cdSolicitacao) {
      return next('/');
    }
    return next();
  },
});
