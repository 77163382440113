import { ChainTarget, Handler } from './Handler';

export abstract class AbstractHandler implements Handler {
  protected nextHandler: Handler | undefined;

  public setNext(handler: Handler): Handler {
    this.nextHandler = handler;
    return handler;
  }

  handle(target: ChainTarget) {
    if (this.nextHandler) {
      return this.nextHandler.handle(target);
    }

    return null;
  }
}

export default AbstractHandler;
