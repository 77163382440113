import { server } from '@/infra/server';

const arController = {
  getLogo: async (subdomain: string) => {
    const response = await server.get(`${subdomain}/logo.png`);
    return response;
  },
  getArData: async (subdomain: string) => {
    const response = await server.get(`ar-data/${subdomain}?needsExist=true`);
    return response;
  },
};

export default arController;
